import { isObject } from "lodash";

import { ACCESS_TO_THE_COMPONENT, DisplayFields } from ".";

const hasAccess = (
	accessName: string,
	personalRole?: DisplayFields,
): boolean => {
	try {
		if (!accessName) return false;

		const isValidObject = (value?: DisplayFields): boolean =>
			isObject(value) && value !== null;

		let access = isValidObject(personalRole)
			? personalRole
			: ACCESS_TO_THE_COMPONENT;

		const keys = accessName.split("."); // Split the accessName by dots to handle nested properties

		// Traverse the object to reach the desired property
		for (const key of keys) {
			access = access?.[key];
			if (access === undefined) {
				return false; // Intermediate value is `undefined`, access is denied
			}
		}

		const isAccessTrue = !!access?.show; // Check if the final property has a truthy `show` attribute

		return isAccessTrue;
	} catch {
		return false; // Any error results in returning `false`
	}
};

export default hasAccess;
