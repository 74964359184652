import React, { useCallback, useMemo, useState } from "react";
import { Button, Icon } from "uikit";

import ExecutorReport from "../../../../../../../../services/ExecutorReport";
import {
	UseTableOptions,
	useTableOptions,
} from "../../../../../../../../components/LightTable";
import {
	StyledRow,
	ExecutorsFilterPanel,
} from "../../../../../../../../components/common";

import { useAccountingExecutors } from "../../../../hooks";
import DateRange from "../../../../components/DateRange";
import Search from "../../../../components/Search";

import Balance from "./components/Balance";

const Header: React.FC<Header.Props> = ({
	paymentAccounts,
	taxiServiceId,
	value,
	onChange,
	getData,
	filters,
	setFilters,
	handleFilters,
	executorId,
}) => {
	const {
		setQuery: setQueryRedux,
		setDateRange: setDateRangeRedux,
		filters: filtersRedux,
	} = useAccountingExecutors();

	const { query, setQuery, dateRange, setDateRange, lang } = useTableOptions({
		value,
		setValue: onChange,
	});

	const [beforeQuery, setBeforeQuery] = useState<string>(filtersRedux.query);
	const [booferDate, setBooferDate] = useState({
		dateFrom: filtersRedux.dateRange.from,
		dateTo: filtersRedux.dateRange.to,
	});

	const onSave = useCallback(() => {
		setBooferDate({
			dateFrom: dateRange.dateFrom,
			dateTo: dateRange.dateTo,
		});
		setDateRangeRedux({
			from: dateRange.dateFrom,
			to: dateRange.dateTo,
		});
		setQueryRedux(query);
		setBeforeQuery(query);
		getData();
	}, [
		dateRange.dateFrom,
		dateRange.dateTo,
		getData,
		query,
		setDateRangeRedux,
		setQueryRedux,
	]);

	const dateRangeHasChanges = useMemo(() => {
		if (
			dateRange.dateFrom.getTime() !== booferDate.dateFrom?.getTime() ||
			dateRange.dateTo.getTime() !== booferDate.dateTo?.getTime() ||
			beforeQuery !== query
		) {
			return true;
		}
		return false;
	}, [
		beforeQuery,
		booferDate.dateFrom,
		booferDate.dateTo,
		dateRange.dateFrom,
		dateRange.dateTo,
		query,
	]);

	const mainAccount = useMemo(
		() => paymentAccounts?.find((a) => a.type === "main"),
		[paymentAccounts],
	);

	return (
		<StyledRow
			p="5px"
			gap="5px"
			justify="space-between"
			alignItems="center"
		>
			<StyledRow gap="5px" justify="start" alignItems="center" h="32px">
				<Button.Button
					variant="primary"
					focused={false}
					disabled={dateRangeHasChanges}
					icon={<Icon id="refresh" size={20} />}
					onClick={onSave}
				/>
				<Balance
					taxiServiceId={taxiServiceId}
					paymentAccount={mainAccount}
					executorId={executorId}
					iconId="balance"
				/>
				<Button.Button
					variant="primary"
					focused={false}
					disabled
					icon={<Icon id="balance" size={20} />}
					onClick={() => {}}
				/>
				<Button.Button
					variant="primary"
					focused={false}
					disabled
					icon={<Icon id="bonus-balance" size={20} />}
					onClick={() => {}}
				/>
			</StyledRow>
			<StyledRow
				gap="5px"
				justify="center"
				alignItems="center"
				h="32px"
				m="0 0 0 2rem"
			>
				<DateRange value={dateRange} onChange={setDateRange} />
				<Button.Button
					variant="primary"
					focused={false}
					disabled={!dateRangeHasChanges}
					icon={<Icon id="search" size={20} />}
					onClick={onSave}
				/>
			</StyledRow>
			<StyledRow gap="5px" alignItems="center">
				<Button.Button
					variant="primary"
					focused={false}
					disabled
					icon={<Icon id="save" size={20} />}
					onClick={() => {}}
				/>
				<Button.Button
					variant="primary"
					focused={false}
					disabled
					icon={<Icon id="print" size={20} />}
					onClick={() => {}}
				/>
				<Search value={query} onChange={setQuery} />
				<ExecutorsFilterPanel
					filter={filters}
					language={lang}
					setFilter={setFilters}
					onSave={handleFilters}
				/>
			</StyledRow>
		</StyledRow>
	);
};

declare namespace Header {
	interface Props {
		value: UseTableOptions["editor"];
		onChange: UseTableOptions["onChange"];
		paymentAccounts?: ExecutorReport.Model["paymentAccounts"] | null;
		executorId: ExecutorReport.Model["id"] | null;
		taxiServiceId: number | null;
		getData: () => void;
		filters: ExecutorsFilterPanel.Props["filter"];
		setFilters: ExecutorsFilterPanel.Props["setFilter"];
		handleFilters: (data: ExecutorsFilterPanel.Value) => void;
	}
}

export default Header;
