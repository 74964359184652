import React, { Dispatch, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent, Row } from "uikit";

import CarClass from "../../../../../../../../../../../../../../services/CarClass";
import Language from "../../../../../../../../../../../../../../services/Language";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import FieldsContainer from "../../../../../../../../../../../../../../components/FieldsContainer";

const DistributableCarClass: React.FC<DistributableCarClass.Props> = ({
	value,
	onChange,
	distributableCarClass,
	disabled,
	language,
}) => {
	const { t } = useTranslation();

	const items = useMemo(
		() => distributableCarClass ?? [],
		[distributableCarClass],
	);

	const valueEditor = useObjectEditor(value, onChange);

	return (
		<FieldsContainer
			label={
				t(
					"pages.preferencesPages.screenDirectory.carClass.modal.main.settingsData.str200",
				) ?? ""
			}
		>
			<Row wrap="nowrap" sizes="160px*" gaps="12px*">
				{items.map((item) => (
					<CheckBoxWithContent
						key={item.id}
						value={valueEditor.includes(item.id)}
						gap="10px"
						disabled={disabled}
						onChange={(serviceEnable) => {
							if (serviceEnable) valueEditor.pushUniq(item.id);
							else valueEditor.removeByValue(item.id);
						}}
					>
						{item.name?.[language]}
					</CheckBoxWithContent>
				))}
			</Row>
		</FieldsContainer>
	);
};

declare namespace DistributableCarClass {
	type Value = number[];

	interface Props {
		value: Value;
		onChange: Dispatch<Value>;
		distributableCarClass?: CarClass.Model[];

		disabled: boolean;
		language: Language;
	}
}

export default DistributableCarClass;
