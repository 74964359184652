import React from "react";
import { Row, Icon } from "uikit";
import Button from "../Button";

const ControlButtons: React.FC<ControlButtons.Props> = ({
	canAdd = true,
	canEdit,
	canDelete,
	onAdd,
	onEdit,
	onDelete,
}) => (
	<Row gaps="10px*" align="center">
		<Button
			variant="primary"
			icon={<Icon id="plus" size={16} />}
			disabled={!canAdd}
			onClick={onAdd}
		/>
		<Button
			variant="secondary"
			icon={<Icon id="pencil" size={20} />}
			disabled={!canEdit}
			onClick={onEdit}
		/>
		<Button
			variant="secondary"
			icon={<Icon id="trash" size={20} />}
			disabled={!canDelete}
			onClick={onDelete}
		/>
	</Row>
);

declare namespace ControlButtons {
	interface Props {
		canAdd?: boolean;
		canEdit: boolean;
		canDelete: boolean;

		onAdd: () => void;
		onEdit: () => void;
		onDelete: () => void;
	}
}

export default ControlButtons;
