import React, {
	CSSProperties,
	PropsWithChildren,
	ReactNode,
	useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { isUndefined } from "lodash";
import { Icon, Row } from "uikit";

import DefaultPageHeaderButton from "../../../DefaultPageHeaderButton";
import { Popup, StyledP } from "../../../common";

const ControlButtons: React.FC<ControlButtons.Props> = ({
	onAdd,
	onEdit,
	onDelete,
	canAdd = true,
	canEdit = false,
	canDelete = false,

	titleAdd = "table.buttons.add",
	idAdd = "table_add_button_id",
	titleEdit = "table.buttons.edit",
	idEdit = "table_edit_button_id",
	titleDelete = "table.buttons.delete",
	idDelete = "table_delete_button_id",
	popupAdd = {},
	popupEdit = {},
	popupDelete = {},

	beforeAdditionalButtons,
	buttons,
	afterAdditionalButtons,
	variant,
}) => {
	const { t } = useTranslation();
	const defaultButtons = useMemo(
		() => (
			<>
				<Popup
					useHoverControl
					trackerId={idAdd}
					offset={{ x: 32, y: 33 }}
					{...popupAdd}
					tracker={
						<DefaultPageHeaderButton
							variant={variant || "primary"}
							disabled={!canAdd}
							onClick={onAdd}
							icon={<Icon id="plus" size={16} />}
							id={idAdd}
						/>
					}
				>
					<StyledP
						br="4px"
						bgC="#000000"
						colors="#ffffff"
						p="5px 10px"
					>
						{t(titleAdd)}
					</StyledP>
				</Popup>
				<Popup
					useHoverControl
					trackerId={idEdit}
					offset={{ x: 32, y: 33 }}
					{...popupEdit}
					tracker={
						<DefaultPageHeaderButton
							disabled={!canEdit}
							variant={variant || "secondary"}
							icon={<Icon id="pencil" size={20} />}
							onClick={onEdit}
							id={idEdit}
						/>
					}
				>
					{titleEdit && (
						<StyledP
							br="4px"
							bgC="#000000"
							colors="#ffffff"
							p="5px 10px"
						>
							{t(titleEdit)}
						</StyledP>
					)}
				</Popup>
				<Popup
					useHoverControl
					trackerId={idDelete}
					offset={{ x: 32, y: 33 }}
					{...popupDelete}
					tracker={
						<DefaultPageHeaderButton
							disabled={!canDelete}
							variant={variant || "secondary"}
							icon={<Icon id="trash" size={20} />}
							onClick={onDelete}
							id={idDelete}
						/>
					}
				>
					{titleDelete && (
						<StyledP
							br="4px"
							bgC="#000000"
							colors="#ffffff"
							p="5px 10px"
						>
							{t(titleDelete)}
						</StyledP>
					)}
				</Popup>
			</>
		),
		[
			idAdd,
			popupAdd,
			variant,
			canAdd,
			onAdd,
			t,
			titleAdd,
			idEdit,
			popupEdit,
			canEdit,
			onEdit,
			titleEdit,
			idDelete,
			popupDelete,
			canDelete,
			onDelete,
			titleDelete,
		],
	);

	const currentButtons = useMemo(
		() =>
			// eslint-disable-next-line no-nested-ternary
			isUndefined(buttons)
				? defaultButtons
				: Array.isArray(buttons)
				? buttons.map(
						(
							{
								title,
								id,
								disabled,
								icon,
								onClick,
								variation,
								margin,
								popup,
								children,
							},
							index,
						) => (
							<Popup
								key={index}
								useHoverControl
								trackerId={id}
								offset={{ x: 32, y: 33 }}
								{...popup}
								tracker={
									<DefaultPageHeaderButton
										style={{ margin }}
										key={index}
										disabled={disabled}
										variant={variation || "secondary"}
										icon={
											<Icon
												{...icon}
												size={icon.size || 20}
											/>
										}
										onClick={onClick}
										id={id}
									/>
								}
							>
								{title && (
									<StyledP
										br="4px"
										bgC="#000000"
										colors="#ffffff"
										p="5px 10px"
									>
										{title}
									</StyledP>
								)}
								{children}
							</Popup>
						),
				  )
				: buttons,
		[buttons, defaultButtons],
	);

	return (
		<Row gaps="10px*" align="center">
			{Array.isArray(beforeAdditionalButtons)
				? beforeAdditionalButtons.map(
						(
							{
								title,
								id,
								disabled,
								icon,
								onClick,
								variation,
								margin,
								popup,
								children,
							},
							index,
						) => (
							<Popup
								key={index}
								useHoverControl
								trackerId={id}
								offset={{ x: 32, y: 33 }}
								{...popup}
								tracker={
									<DefaultPageHeaderButton
										style={{ margin }}
										key={index}
										disabled={disabled}
										variant={variation || "secondary"}
										icon={
											<Icon
												{...icon}
												size={icon.size || 20}
											/>
										}
										onClick={onClick}
										id={id}
									/>
								}
							>
								{title && (
									<StyledP
										br="4px"
										bgC="#000000"
										colors="#ffffff"
										p="5px 10px"
									>
										{title}
									</StyledP>
								)}
								{children}
							</Popup>
						),
				  )
				: beforeAdditionalButtons}
			{currentButtons}
			{Array.isArray(afterAdditionalButtons)
				? afterAdditionalButtons.map(
						(
							{
								disabled,
								icon,
								onClick,
								variation,
								margin,
								title,
								id,
								popup,
								children,
							},
							index,
						) => (
							<Popup
								key={index}
								useHoverControl
								trackerId={id}
								offset={{ x: 32, y: 33 }}
								{...popup}
								tracker={
									<DefaultPageHeaderButton
										style={{ margin }}
										key={index}
										disabled={disabled}
										variant={variation || "secondary"}
										icon={
											<Icon
												{...icon}
												size={icon.size || 20}
											/>
										}
										onClick={onClick}
										id={id}
									/>
								}
							>
								{title && (
									<StyledP
										br="4px"
										bgC="#000000"
										colors="#ffffff"
										p="5px 10px"
									>
										{title}
									</StyledP>
								)}
								{children}
							</Popup>
						),
				  )
				: afterAdditionalButtons}
		</Row>
	);
};

declare namespace ControlButtons {
	interface Props {
		onAdd?: VoidFunction;
		onEdit?: VoidFunction;
		onDelete?: VoidFunction;
		canAdd?: boolean;
		canEdit?: boolean;
		canDelete?: boolean;
		titleAdd?: string;
		idAdd?: string;
		titleEdit?: string;
		idEdit?: string;
		titleDelete?: string;
		idDelete?: string;
		popupAdd?: Popup.Props;
		popupEdit?: Popup.Props;
		popupDelete?: Popup.Props;

		beforeAdditionalButtons?: Button[] | ReactNode;
		buttons?: Button[] | ReactNode;
		afterAdditionalButtons?: Button[] | ReactNode;
		variant?: Variant;
	}

	interface Button extends PropsWithChildren {
		popup?: Popup.Props;
		icon: Icon.Props;
		onClick: VoidFunction;
		disabled?: boolean;
		variation?: Variant;
		margin?: CSSProperties["margin"];
		title?: string;
		id?: string;
	}

	type Variant = "negative" | "primary" | "secondary" | "white";
}

export default ControlButtons;
