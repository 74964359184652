import makeTable from "./makeTable";

const dispatcher = makeTable({
	allIds: [
		"fullName",
		"alias",
		"phones",
		"role",
		"status",
		"registeredAt",
		"access",
		"birthAt",
	],
	defaultIds: [
		"fullName",
		"alias",
		"phones",
		"role",
		"status",
		"registeredAt",
		"access",
	],
	initialWidths: {
		fullName: 331,
		alias: 200,
		phones: 240,
		role: 140,
		status: 140,
		registeredAt: 150,
		access: 331,
		birthAt: 150,
	},
	i18tPath: (id) => `tableColumns.dispatcher.${id}`,
});

export default dispatcher;
