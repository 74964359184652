/* eslint-disable no-shadow */

import React, { useCallback, useMemo } from "react";
import { CheckBoxWithContent, useInternal } from "uikit";

import useObjectEditor from "../../hooks/useObjectEditor";
import FieldsContainer from "../FieldsContainer";
import { Style, StyledGrid } from "../common";

type KeyBase = string | number;

const CheckBoxSelect = <OptionKey extends KeyBase>({
	value,
	disabled = false,
	title,
	options,
	onChange,
	height,
	verticalScroll = false,
	...props
}: CheckBoxSelect.Props<OptionKey>) => {
	const defaultValue = useMemo(() => value ?? [], [value]);

	const [internalValue, setInternalValue] = useInternal(
		value ?? defaultValue,
	);

	const internalValueOnChange = useCallback(
		(newValue: CheckBoxSelect.Value<OptionKey>) => {
			if (onChange) onChange(newValue);
			else setInternalValue(newValue);
		},
		[onChange, setInternalValue],
	);

	const valueEditor = useObjectEditor(internalValue, internalValueOnChange);

	return (
		<FieldsContainer label={title}>
			<StyledGrid
				areas=""
				gap="12px"
				columns="repeat(auto-fill, minmax(200px, 1fr))"
				scrollbar={verticalScroll}
				overY="auto"
				h={height ? `${height}px` : "21px"}
				css={`
					div > div {
					}
					div > div > div:nth-child(2) {
						display: flex;
						flex-wrap: nowrap;
						gap: 10px;
					}
				`}
				{...props}
			>
				{options?.map((option) => (
					<CheckBoxWithContent
						key={option.key}
						value={valueEditor.includes(option.key)}
						gap="10px"
						disabled={disabled}
						onChange={(serviceEnable) => {
							if (serviceEnable) valueEditor.pushUniq(option.key);
							else valueEditor.removeByValue(option.key);
						}}
					>
						{option.label}
					</CheckBoxWithContent>
				))}
			</StyledGrid>
		</FieldsContainer>
	);
};

declare namespace CheckBoxSelect {
	type Value<Key extends KeyBase> = Key[];

	interface Option<Key extends KeyBase> {
		key: Key;
		label: string;
	}

	interface Props<OptionKey extends KeyBase> extends Partial<Style.Grid> {
		value?: Value<OptionKey>;

		disabled?: boolean;
		title?: string;
		options?: Option<OptionKey>[];
		height?: number;
		verticalScroll?: boolean;

		onChange?: (value: Value<OptionKey>) => void;
	}
}

export default CheckBoxSelect;
