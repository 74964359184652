import { ReactNode, useMemo } from "react";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import Language from "../../../../../../services/Language";

import orderNumber from "./orderNumber";
import orderDate from "./orderDate";
import orderTime from "./orderTime";
import orderPrice from "./orderPrice";
import executorArrivalDate from "./executorArrivalDate";
import executorArrivalTime from "./executorArrivalTime";
import timeToDelivery from "./timeToDelivery";
import executorCallSign from "./executorCallSign";
import pickupSettlement from "./pickupSettlement";
import pickupAddress from "./pickupAddress";
import pickupSector from "./pickupSector";
import phone from "./phone";
import customerCallStatus from "./customerCallStatus";
import executorCallStatus from "./executorCallStatus";
import executingStageWithClientsTime from "./executingStageWithClientsTime";
import customerNote from "./customerNote";
import carClass from "./carClass";
import destinationSettlement from "./destinationSettlement";
import destinationAddress from "./destinationAddress";
import destinationSector from "./destinationSector";
import paymentType from "./paymentType";
import executorNote from "./executorNote";
import dispatcherCallSign from "./dispatcherCallSign";
import additionalPhone from "./additionalPhone";
import status from "./status";
import source from "./source";
import executingStage from "./executingStage";
import closeReason from "./closeReason";
import rideNumber from "./rideNumber";
import totalRideCount from "./totalRideCount";
import createOrderDate from "./createOrderDate";
import createOrderTime from "./createOrderTime";
import customerFullName from "./customerFullName";
import executorLate from "./executorLate";
import executingStageAcceptedTime from "./executingStageAcceptedTime";
import executingStageArrivedTime from "./executingStageArrivedTime";
import executingStageWaitingCustomerTime from "./executingStageWaitingCustomerTime";
import smsLateStatus from "./smsLateStatus";
import smsWillBeAtStatus from "./smsWillBeAtStatus";
import smsCarNotFoundStatus from "./smsCarNotFoundStatus";
import smsAwaitingPassengersStatus from "./smsAwaitingPassengersStatus";
import smsWithPassengersStatus from "./smsWithPassengersStatus";
import smsOrderCompletedStatus from "./smsOrderCompletedStatus";
import smsExecutorSwitchedStatus from "./smsExecutorSwitchedStatus";
import counterparty from "./counterparty";
import company from "./company";
import taxiService from "./taxiService";
import idleTime from "./idleTime";
import tariff from "./tariff";
import services from "./services";
import parameters from "./parameters";
import transportationType from "./transportationType";
import discount from "./discount";
import counterpartyCheckCard from "./counterpartyCheckCard";
import counterpartyCheck from "./counterpartyCheck";

export enum ColumnId {
	OrderDate = "orderDate",
	OrderTime = "orderTime",
	ExecutorArrivalDate = "executorArrivalDate",
	ExecutorArrivalTime = "executorArrivalTime",
	TimeToDelivery = "timeToDelivery",
	ExecutorCallSign = "executorCallSign",
	PickupSettlement = "pickupSettlement",
	PickupAddress = "pickupAddress",
	PickupSector = "pickupSector",
	Phone = "phone",
	OrderPrice = "orderPrice",
	CustomerCallStatus = "customerCallStatus",
	ExecutorCallStatus = "executorCallStatus",
	OrderNumber = "orderNumber",
	CustomerNote = "customerNote",
	CarClass = "carClass",
	DestinationSettlement = "destinationSettlement",
	DestinationAddress = "destinationAddress",
	DestinationSector = "destinationSector",
	PaymentType = "paymentType",
	ExecutorNote = "executorNote",
	DispatcherCallSign = "dispatcherCallSign",
	AdditionalPhone = "additionalPhone",
	Status = "status",
	Source = "source",
	ExecutingStage = "executingStage",
	CloseReason = "closeReason",
	RideNumber = "rideNumber",
	TotalRideCount = "totalRideCount",
	CreateOrderDate = "createOrderDate",
	CreateOrderTime = "createOrderTime",
	CustomerFullName = "customerFullName",
	Counterparty = "counterparty",
	CounterpartyCheck = "counterpartyCheck",
	CounterpartyCheckCard = "counterpartyCheckCard",
	ExecutorLate = "executorLate",
	ExecutingStageWithClientsTime = "executingStageWithClientsTime",
	ExecutingStageAcceptedTime = "executingStageAcceptedTime",
	ExecutingStageArrivedTime = "executingStageArrivedTime",
	ExecutingStageWaitingCustomerTime = "executingStageWaitingCustomerTime",
	SMSLateStatus = "smsLateStatus",
	SMSWillBeAtStatus = "smsWillBeAtStatus",
	SMSCarNotFoundStatus = "smsCarNotFoundStatus",
	SMSAwaitingPassengersStatus = "smsAwaitingPassengersStatus",
	SMSWithPassengersStatus = "smsWithPassengersStatus",
	SMSOrderCompletedStatus = "smsOrderCompletedStatus",
	SMSExecutorSwitchedStatus = "smsExecutorSwitchedStatus",
	Company = "company",
	TaxiService = "taxiService",
	IdleTime = "idleTime",
	Tariff = "rate",
	Services = "services",
	Parameters = "parameters",
	Discount = "discount",
	TransportationType = "transportationType",
}

export interface Column {
	render: (options: Column.Options) => ReactNode;
	pathTranslation: string;
	label: string;
}

export declare namespace Column {
	interface Options {
		width?: number;

		language: Language;

		onResize?: (width: number, columnId: ColumnId) => void;
	}
}

const pathTranslationsMain = "orderPageWidgets.orderTable.modelTable.columns.";

export const columns: Record<ColumnId, Column> = {
	[ColumnId.OrderDate]: {
		render: orderDate,
		label: "Order Date",
		pathTranslation: `${pathTranslationsMain}orderDate`,
	},
	[ColumnId.OrderTime]: {
		render: orderTime,
		label: "Order Time",
		pathTranslation: `${pathTranslationsMain}orderTime`,
	},
	[ColumnId.ExecutorArrivalDate]: {
		render: executorArrivalDate,
		label: "Arrival Date",
		pathTranslation: `${pathTranslationsMain}arrivalDate`,
	},
	[ColumnId.ExecutorArrivalTime]: {
		render: executorArrivalTime,
		label: "Arrival Time",
		pathTranslation: `${pathTranslationsMain}arrivalTime`,
	},
	[ColumnId.TimeToDelivery]: {
		render: timeToDelivery,
		label: "Time to Delivery",
		pathTranslation: `${pathTranslationsMain}timeToDelivery`,
	},
	[ColumnId.ExecutorCallSign]: {
		render: executorCallSign,
		label: "Executor Call Sign",
		pathTranslation: `${pathTranslationsMain}executorCallSign`,
	},
	[ColumnId.PickupSettlement]: {
		render: pickupSettlement,
		label: "Pickup Settlement",
		pathTranslation: `${pathTranslationsMain}firstCity`,
	},
	[ColumnId.PickupAddress]: {
		render: pickupAddress,
		label: "Pickup Address",
		pathTranslation: `${pathTranslationsMain}firstAddress`,
	},
	[ColumnId.PickupSector]: {
		render: pickupSector,
		label: "Pickup Sector",
		pathTranslation: `${pathTranslationsMain}firstSector`,
	},
	[ColumnId.Phone]: {
		render: phone,
		label: "Phone",
		pathTranslation: `${pathTranslationsMain}phone`,
	},
	[ColumnId.OrderPrice]: {
		render: orderPrice,
		label: "Price",
		pathTranslation: `${pathTranslationsMain}price`,
	},
	[ColumnId.CustomerCallStatus]: {
		render: customerCallStatus,
		label: "Customer Call Status",
		pathTranslation: `${pathTranslationsMain}clientCallStatus`,
	},
	[ColumnId.ExecutorCallStatus]: {
		render: executorCallStatus,
		label: "Executor Call Status",
		pathTranslation: `${pathTranslationsMain}executorCallStatus`,
	},
	[ColumnId.OrderNumber]: {
		render: orderNumber,
		label: "Order Number",
		pathTranslation: `${pathTranslationsMain}orderNumber`,
	},
	[ColumnId.ExecutingStageWithClientsTime]: {
		render: executingStageWithClientsTime,
		label: "With Clients",
		pathTranslation: `${pathTranslationsMain}executingStageTimeWithClients`,
	},
	[ColumnId.CustomerNote]: {
		render: customerNote,
		label: "Notes",
		pathTranslation: `${pathTranslationsMain}clientNote`,
	},
	[ColumnId.CarClass]: {
		render: carClass,
		label: "Class",
		pathTranslation: `${pathTranslationsMain}taxiClass`,
	},
	[ColumnId.DestinationSettlement]: {
		render: destinationSettlement,
		label: "Destination Settlement",
		pathTranslation: `${pathTranslationsMain}lastCity`,
	},
	[ColumnId.DestinationAddress]: {
		render: destinationAddress,
		label: "Destination Address",
		pathTranslation: `${pathTranslationsMain}lastAddress`,
	},
	[ColumnId.DestinationSector]: {
		render: destinationSector,
		label: "Destination Sector",
		pathTranslation: `${pathTranslationsMain}lastSector`,
	},
	[ColumnId.PaymentType]: {
		render: paymentType,
		label: "Payment Type",
		pathTranslation: `${pathTranslationsMain}paymentType`,
	},
	[ColumnId.ExecutorNote]: {
		render: executorNote,
		label: "Driver Note",
		pathTranslation: `${pathTranslationsMain}driverNote`,
	},
	[ColumnId.DispatcherCallSign]: {
		render: dispatcherCallSign,
		label: "Dispatcher",
		pathTranslation: `${pathTranslationsMain}dispatcherAlias`,
	},
	[ColumnId.AdditionalPhone]: {
		render: additionalPhone,
		label: "Additional Phone",
		pathTranslation: `${pathTranslationsMain}additionalPhone`,
	},
	[ColumnId.Status]: {
		render: status,
		label: "Order Status",
		pathTranslation: `${pathTranslationsMain}status`,
	},
	[ColumnId.Source]: {
		render: source,
		label: "Source",
		pathTranslation: `${pathTranslationsMain}source`,
	},
	[ColumnId.ExecutingStage]: {
		render: executingStage,
		label: "Execution Stage",
		pathTranslation: `${pathTranslationsMain}executingStage`,
	},
	[ColumnId.CloseReason]: {
		render: closeReason,
		label: "Close Reason",
		pathTranslation: `${pathTranslationsMain}closeReason`,
	},
	[ColumnId.RideNumber]: {
		render: rideNumber,
		label: "Ride Number",
		pathTranslation: `${pathTranslationsMain}rideNumber`,
	},
	[ColumnId.TotalRideCount]: {
		render: totalRideCount,
		label: "Total Ride Count",
		pathTranslation: `${pathTranslationsMain}totalRideCount`,
	},
	[ColumnId.CreateOrderDate]: {
		render: createOrderDate,
		label: "Order Creation Date",
		pathTranslation: `${pathTranslationsMain}createDateOrder`,
	},
	[ColumnId.CreateOrderTime]: {
		render: createOrderTime,
		label: "Order Creation Time",
		pathTranslation: `${pathTranslationsMain}createTimeOrder`,
	},
	[ColumnId.CustomerFullName]: {
		render: customerFullName,
		label: "Customer",
		pathTranslation: `${pathTranslationsMain}fullName`,
	},
	[ColumnId.Counterparty]: {
		render: counterparty,
		label: "Counterparty",
		pathTranslation: `${pathTranslationsMain}counterparty`,
	},
	[ColumnId.CounterpartyCheck]: {
		render: counterpartyCheck,
		label: "Counterparty Check",
		pathTranslation: `${pathTranslationsMain}counterpartyCheck`,
	},
	[ColumnId.CounterpartyCheckCard]: {
		render: counterpartyCheckCard,
		label: "Card",
		pathTranslation: `${pathTranslationsMain}counterpartyCheckCard`,
	},
	[ColumnId.ExecutorLate]: {
		render: executorLate,
		label: "Late",
		pathTranslation: `${pathTranslationsMain}executorLate`,
	},
	[ColumnId.ExecutingStageAcceptedTime]: {
		render: executingStageAcceptedTime,
		label: "Order Accepted",
		pathTranslation: `${pathTranslationsMain}executingStageTimeAccepted`,
	},
	[ColumnId.ExecutingStageArrivedTime]: {
		render: executingStageArrivedTime,
		label: "At Arrival Place",
		pathTranslation: `${pathTranslationsMain}executingStageTimeArrived`,
	},
	[ColumnId.ExecutingStageWaitingCustomerTime]: {
		render: executingStageWaitingCustomerTime,
		label: "Waiting for Customers",
		pathTranslation: `${pathTranslationsMain}executingStageTimeWaitingClient`,
	},
	[ColumnId.SMSLateStatus]: {
		render: smsLateStatus,
		label: `SMS Status "Late"`,
		pathTranslation: `${pathTranslationsMain}smsStatusLate`,
	},
	[ColumnId.SMSWillBeAtStatus]: {
		render: smsWillBeAtStatus,
		label: `SMS Status "Will be at XX:XX"`,
		pathTranslation: `${pathTranslationsMain}smsStatusWillBeTime`,
	},
	[ColumnId.SMSCarNotFoundStatus]: {
		render: smsCarNotFoundStatus,
		label: `SMS Status "Car Not Found"`,
		pathTranslation: `${pathTranslationsMain}smsStatusCarNotFound`,
	},
	[ColumnId.SMSAwaitingPassengersStatus]: {
		render: smsAwaitingPassengersStatus,
		label: `SMS Status "Waiting for Passengers"`,
		pathTranslation: `${pathTranslationsMain}smsStatusOutputPassengers`,
	},
	[ColumnId.SMSWithPassengersStatus]: {
		render: smsWithPassengersStatus,
		label: `SMS Status "With Passengers"`,
		pathTranslation: `${pathTranslationsMain}smsStatusWithPassengers`,
	},
	[ColumnId.SMSOrderCompletedStatus]: {
		render: smsOrderCompletedStatus,
		label: `SMS Status "Order Completed"`,
		pathTranslation: `${pathTranslationsMain}smsStatusOrderCompleted`,
	},
	[ColumnId.SMSExecutorSwitchedStatus]: {
		render: smsExecutorSwitchedStatus,
		label: `SMS Status "Driver Switched"`,
		pathTranslation: `${pathTranslationsMain}smsStatusDriverSwitched`,
	},
	[ColumnId.Company]: {
		render: company,
		label: "Company",
		pathTranslation: `${pathTranslationsMain}company`,
	},
	[ColumnId.TaxiService]: {
		render: taxiService,
		label: "Branch",
		pathTranslation: `${pathTranslationsMain}taxiService`,
	},
	[ColumnId.IdleTime]: {
		render: idleTime,
		label: "Idle Time",
		pathTranslation: `${pathTranslationsMain}idleTime`,
	},
	[ColumnId.Tariff]: {
		render: tariff,
		label: "Tariff",
		pathTranslation: `${pathTranslationsMain}tariff`,
	},
	[ColumnId.Services]: {
		render: services,
		label: "Services",
		pathTranslation: `${pathTranslationsMain}services`,
	},
	[ColumnId.Parameters]: {
		render: parameters,
		label: "Parameters",
		pathTranslation: `${pathTranslationsMain}params`,
	},
	[ColumnId.Discount]: {
		render: discount,
		label: "Discount",
		pathTranslation: `${pathTranslationsMain}discount`,
	},
	[ColumnId.TransportationType]: {
		render: transportationType,
		label: "Transportation Type",
		pathTranslation: `${pathTranslationsMain}transportationType`,
	},
};

export const defaultColumnIds = Object.values(ColumnId);

export default columns;
