/* eslint-disable no-shadow */

import React, { Dispatch, memo } from "react";
import { Icon, Row } from "uikit";
import InternalFilters from "./components/Filters";
import Root from "./components/Root";
import ControlButtons from "../../../../../../../PreferencesPages/components/ScreenDirectory/components/components/ControlButtons";
import Button from "../../../../../../../PreferencesPages/components/ScreenDirectory/components/components/Button";

const Header = memo<Header.Props>(
	({
		filters,

		canEdit,
		canDelete,

		onChangeFilters,

		onAdd,
		onEdit,
		onDelete,
	}) => (
		<Root gaps="10px*" align="center" justify="space-between" maxedWidth>
			<ControlButtons
				canEdit={canEdit}
				canDelete={canDelete}
				onAdd={onAdd}
				onEdit={onEdit}
				onDelete={onDelete}
			/>
			<Row gaps="10px*">
				<Button
					variant="secondary"
					icon={<Icon id="save" size={20} />}
					disabled
				/>
				<Button
					variant="secondary"
					icon={<Icon id="print" size={20} />}
					disabled
				/>
				<InternalFilters value={filters} onChange={onChangeFilters} />
			</Row>
		</Root>
	),
);

declare namespace Header {
	interface Props {
		filters: InternalFilters.Value;

		canEdit: boolean;
		canDelete: boolean;

		onChangeFilters: Dispatch<InternalFilters.Value>;

		onAdd: () => void;
		onEdit: () => void;
		onDelete: () => void;
	}

	namespace Filters {
		type Value = InternalFilters.Value;
		type Props = InternalFilters.Props;
	}
}

export default Header;
