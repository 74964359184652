/* eslint-disable no-param-reassign */
import {
	CaseReducer,
	createSlice,
	Draft,
	PayloadAction,
} from "@reduxjs/toolkit";
import { cloneDeep, isArray, isObject } from "lodash";
import { BoxBase, LayoutBase } from "rc-dock";
import { Filter } from "rc-dock/lib/Algorithm";
import { find } from "../../utils/rc-dock";

function removeByValue(object: object, value: any) {
	if (isArray(object)) {
		const index = object.findIndex((element) => {
			if (element === value) {
				return true;
			}

			removeByValue(element, value);

			return false;
		});

		if (index >= 0) object.splice(index, 1);
	} else if (isObject(object)) {
		const entry = Object.entries(object).find(([, entryValue]) => {
			if (entryValue === value) {
				return true;
			}

			removeByValue(entryValue, value);

			return false;
		});

		if (entry) delete value[entry[0]];
	}
}

interface State {
	layout?: LayoutBase;
}

type WidgetId =
	| "table"
	| "previewer"
	| "map"
	| "parking"
	| "executors"
	| "chat";

const initialState: State = {
	layout: {
		dockbox: {
			mode: "horizontal",
			children: [
				{
					mode: "vertical",
					size: 1000,
					children: [
						{
							size: 424,
							tabs: [{ id: "table" }],
						},
						{
							size: 323,
							tabs: [{ id: "previewer" }],
						},
					],
				},
				{
					size: 500,
					tabs: [
						{ id: "map" },
						{ id: "parking" },
						{ id: "executors" },
						{ id: "chat" },
					],
				},
			],
		},
	},
};

function baseOpen(state: Draft<State>, id: string) {
	const newLayout = cloneDeep(state.layout as LayoutBase);

	const tab = find(newLayout, id, Filter.AnyTab);

	if (!tab) {
		const floatbox: BoxBase = cloneDeep(newLayout.floatbox) ?? {
			mode: "float",
			children: [],
		};

		floatbox.children.push({
			h: 500,
			w: 500,
			x: Math.round(Math.random() * 400) + 50,
			y: Math.round(Math.random() * 100) + 50,
			z: floatbox.children.length,
			tabs: [
				{
					id,
				},
			],
		});

		newLayout.floatbox = floatbox;
	}

	state.layout = newLayout;
}

function baseClose(state: Draft<State>, id: string) {
	const newLayout = cloneDeep(state.layout as LayoutBase);

	const tab = find(newLayout, id, Filter.AnyTab);

	if (tab) {
		removeByValue(newLayout, tab);
	}

	state.layout = newLayout;
}

type Reducer<P> = CaseReducer<State, PayloadAction<P>>;

const setLayout: Reducer<LayoutBase> = (state, { payload }) => {
	state.layout = payload;
};

const open: Reducer<WidgetId> = (state, { payload: id }) => {
	baseOpen(state, id);
};

const close: Reducer<WidgetId> = (state, { payload: id }) => {
	baseClose(state, id);
};

const toggle: Reducer<WidgetId> = (state, { payload: id }) => {
	if (!state.layout) return;

	const tab = find(state.layout, id, Filter.AnyTab);

	if (tab) {
		baseClose(state, id);
	} else {
		baseOpen(state, id);
	}
};

const widgets = createSlice({
	name: "widgets",
	initialState,
	reducers: {
		setLayout,
		open,
		close,
		toggle,
	},
});

export default widgets;
