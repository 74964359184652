import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Base from "../Base";
import Card from "../Card";
import {
	NonEditableProperties,
	NonEditablePropertyNames,
} from "../../types/NonEditableProperties";
import Content from "../../pages/Settings/pages/Orders/tabs/Orders/components/Modal/components/Content";
import Language from "../Language";

class OrderSettings extends Base {
	private static _Card: Card | null = null;

	public static get Card() {
		if (this._Card) return this._Card;

		this._Card = new Card((prpc) => prpc.theirsModel.settings.order.card);

		return this._Card;
	}

	public static fromResponse(data: any): OrderSettings.Model {
		return {
			id: data.id,

			...data,
			taxiServiceIds: data.taxiServices?.map(({ id }) => id) || [],
			settings: {
				...data?.settings,
				processing: {
					...data.settings.processing,
					executorControl: {
						...data.settings.processing.executorControl,
						statusChanging: {
							...data.settings.processing.executorControl
								.statusChanging,
							executed: {
								...data.settings.processing.executorControl
									.statusChanging.executed,
								afterArrivalTime: {
									...data.settings.processing.executorControl
										.statusChanging.executed
										.afterArrivalTime,
									threshold:
										data.settings.processing.executorControl
											.statusChanging.executed
											.afterArrivalTime.threshold /
										60 /
										1000,
								},
							},
						},
						waypointAddition: {
							...data.settings.processing.executorControl
								.waypointAddition,
							allowLimitedDistance: {
								...data.settings.processing.executorControl
									.waypointAddition.allowLimitedDistance,
								distanceLimitMeters:
									data.settings.processing.executorControl
										.waypointAddition.allowLimitedDistance
										.distanceLimitMeters / 1000,
							},
						},
						closure: {
							...data.settings.processing.executorControl.closure,
							declineByClient: {
								...data.settings.processing.executorControl
									.closure.declineByClient,
								afterArrivalTime: {
									...data.settings.processing.executorControl
										.closure.declineByClient
										.afterArrivalTime,
									threshold:
										data.settings.processing.executorControl
											.closure.declineByClient
											.afterArrivalTime.threshold /
										60 /
										1000,
								},
							},
						},
					},
					downtime: {
						...data.settings.processing.downtime,
						waiting: {
							...data.settings.processing.downtime.waiting,
							conditions: {
								...data.settings.processing.downtime.waiting
									.conditions,
								afterArrivalTime: {
									...data.settings.processing.downtime.waiting
										.conditions.afterArrivalTime,
									threshold:
										data.settings.processing.downtime
											.waiting.conditions.afterArrivalTime
											.threshold /
										60 /
										1000,
								},
							},
						},
					},
				},
			},

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
		};
	}

	public static toRequest(model: OrderSettings.New | OrderSettings.Modified) {
		return {
			name: model.name,
			active: model.active,
			settings: {
				...model.settings,
				processing: {
					...model.settings?.processing,
					executorControl: {
						...model.settings?.processing.executorControl,
						statusChanging: {
							...model.settings?.processing.executorControl
								.statusChanging,
							executed: {
								...model.settings?.processing.executorControl
									.statusChanging.executed,
								afterArrivalTime: {
									...model.settings?.processing
										.executorControl.statusChanging.executed
										.afterArrivalTime,
									threshold:
										model.settings &&
										model.settings.processing
											.executorControl.statusChanging
											.executed.afterArrivalTime
											.threshold *
											60 *
											1000,
								},
							},
						},
						waypointAddition: {
							...model.settings?.processing.executorControl
								.waypointAddition,
							allowLimitedDistance: {
								...model.settings?.processing.executorControl
									.waypointAddition.allowLimitedDistance,
								distanceLimitMeters:
									model.settings &&
									model.settings.processing.executorControl
										.waypointAddition.allowLimitedDistance
										.distanceLimitMeters * 1000,
							},
						},
						closure: {
							...model.settings?.processing.executorControl
								.closure,
							declineByClient: {
								...model.settings?.processing.executorControl
									.closure.declineByClient,
								afterArrivalTime: {
									...model.settings?.processing
										.executorControl.closure.declineByClient
										.afterArrivalTime,
									threshold:
										model.settings &&
										model.settings.processing
											.executorControl.closure
											.declineByClient.afterArrivalTime
											.threshold *
											60 *
											1000,
								},
							},
						},
					},
					downtime: {
						...model.settings?.processing.downtime,
						waiting: {
							...model.settings?.processing.downtime.waiting,
							conditions: {
								...model.settings?.processing.downtime.waiting
									.conditions,
								afterArrivalTime: {
									...model.settings?.processing.downtime
										.waiting.conditions.afterArrivalTime,
									threshold:
										model.settings &&
										model.settings.processing.downtime
											.waiting.conditions.afterArrivalTime
											.threshold *
											60 *
											1000,
								},
							},
						},
					},
					customerApp: {
						...model.settings?.processing.customerApp,
						costChanging: {
							...model.settings?.processing.customerApp
								.costChanging,
							increase: {
								...model.settings?.processing.customerApp
									.costChanging.increase,
								active: true,
							},
							decrease: {
								...model.settings?.processing.customerApp
									.costChanging.decrease,
								active: true,
							},
						},
					},
				},
			},
			taxiServiceIds: model.taxiServiceIds,
		};
	}

	public static async getAll() {
		const res = await this.request((prpc) =>
			prpc.theirsModel.settings.order.getAll({
				includeTaxiServices: true,
			}),
		);

		console.log("OrderSettings getAll", res);

		return (res?.items as any[])?.map(this.fromResponse);
	}

	public static async store(object: OrderSettings.New) {
		try {
			const res = await this.request(
				(prpc) =>
					prpc.theirsModel.settings.order.create(
						OrderSettings.toRequest(object),
					),
				{ silent: false, error: true },
			);

			console.log("OrderSettings create", { res, object });
		} catch (err: any) {
			if (err.status === 403) {
				return false;
			}
		}
		return true;
	}

	public static async update(object: OrderSettings.Modified) {
		try {
			const res = await this.request(
				(prpc) =>
					prpc.theirsModel.settings.order.update(
						object.id,
						this.toRequest(object),
					),
				{ silent: false, error: true },
			);
			console.log("OrderSettings update", { res, object });
		} catch (err: any) {
			if (err.status === 403) {
				return false;
			}
		}
		return true;
	}

	public static async destroy(id: number[] | number) {
		this.request((prpc) => prpc.theirsModel.settings.order.delete(id));
	}
}

declare namespace OrderSettings {
	interface Model extends NonEditableProperties {
		taxiServices: any;
		name: Record<Language, string>;
		active: boolean;
		settings: Content.Value["settings"];
		taxiServiceIds: number[];
	}

	type New = Omit<Model, NonEditablePropertyNames>;
	type Modified = Partial<New> & { id: number };

	interface SubscribeOptions
		extends Omit<
			ServiceSubscribeOptionsBase<OrderSettings.Model>,
			"query"
		> {
		active?: boolean;
		language?: Language;
		taxiServiceIds?: number[];
	}
}

export enum RegularOrderArchiveNotifyType {
	NONE = "none",
	SMS = "sms",
	CALL_MESSAGE = "call_message",
}

export default OrderSettings;
