import React, { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Icon, theme } from "uikit";
import { Filter } from "rc-dock/lib/Algorithm";

import {
	useTypedDispatch,
	useTypedSelector,
} from "../../../../../../redux/store";
import widgets from "../../../../../../redux/reducers/widgets";
import { find } from "../../../../../../utils/rc-dock";

import ToggleButton from "./components/ToggleButton";
import Root from "./components/Root";
import Button from "./components/Button";
import SoftphoneButton from "./components/SoftphoneButton";

const RightMenu: React.FC<RightMenu.Props> = () => {
	const dispatch = useTypedDispatch();
	const orderPageLayout = useTypedSelector((state) => state.widgets.layout);
	const location = useLocation();
	const { t } = useTranslation();

	const isOrderPage = useMemo(
		() =>
			location.pathname === "/orders" || location.pathname === "/orders/",
		[location.pathname],
	);

	const isTabOpen = useCallback(
		(id: string) =>
			orderPageLayout
				? !!find(orderPageLayout, id, Filter.AnyTab)
				: false,
		[orderPageLayout],
	);

	const parkingsButtonOnClick = useCallback(() => {
		dispatch(widgets.actions.toggle("parking"));
	}, [dispatch]);

	const isParkingsOpen = useMemo(() => isTabOpen("parking"), [isTabOpen]);

	const executorsButtonOnClick = useCallback(() => {
		dispatch(widgets.actions.toggle("executors"));
	}, [dispatch]);

	const isExecutorsOpen = useMemo(() => isTabOpen("executors"), [isTabOpen]);

	const mapButtonOnClick = useCallback(() => {
		dispatch(widgets.actions.toggle("map"));
	}, [dispatch]);

	const isMapOpen = useMemo(() => isTabOpen("map"), [isTabOpen]);

	const orderDetailsButtonOnClick = useCallback(() => {
		dispatch(widgets.actions.toggle("previewer"));
	}, [dispatch]);

	const isOrderDetailsOpen = useMemo(
		() => isTabOpen("previewer"),
		[isTabOpen],
	);

	const chatButtonOnClick = useCallback(() => {
		dispatch(widgets.actions.toggle("chat"));
	}, [dispatch]);

	const isChatOpen = useMemo(() => isTabOpen("chat"), [isTabOpen]);

	return (
		<Root align="center" gaps="16px*">
			<Button
				title={
					t(`orderPage.rightMenu.notifications`) || "Notifications"
				}
				tabIndex={-1}
			>
				<Icon
					id="right-menu-notifications"
					size={24}
					colors={[theme.colors.secondary]}
				/>
			</Button>
			{isOrderPage ? (
				<>
					<ToggleButton
						active={isParkingsOpen}
						title={
							t(`orderPage.rightMenu.toggle.parkings`) ||
							"Parking lots"
						}
						tabIndex={-1}
						onClick={parkingsButtonOnClick}
					>
						<Icon id="right-menu-parkings" size={24} />
					</ToggleButton>
					<ToggleButton
						active={isExecutorsOpen}
						title={
							t(`orderPage.rightMenu.toggle.executors`) ||
							"Executors"
						}
						tabIndex={-1}
						onClick={executorsButtonOnClick}
					>
						<Icon id="right-menu-executors" size={24} />
					</ToggleButton>
					<ToggleButton
						active={isMapOpen}
						title={t(`orderPage.rightMenu.toggle.map`) || "Map"}
						tabIndex={-1}
						onClick={mapButtonOnClick}
					>
						<Icon id="right-menu-map" size={24} />
					</ToggleButton>
					<ToggleButton
						active={isOrderDetailsOpen}
						title={
							t(`orderPage.rightMenu.toggle.orderDetails`) ||
							"Order information"
						}
						tabIndex={-1}
						onClick={orderDetailsButtonOnClick}
					>
						<Icon id="right-menu-order-details" size={24} />
					</ToggleButton>
					<ToggleButton
						active={isChatOpen}
						title={t(`orderPage.rightMenu.toggle.chat`) || "Chat"}
						tabIndex={-1}
						onClick={chatButtonOnClick}
					>
						<Icon id="right-menu-chat" size={24} />
					</ToggleButton>
				</>
			) : null}
			<SoftphoneButton />
		</Root>
	);
};

declare namespace RightMenu {
	interface Props {}
}

export default RightMenu;
