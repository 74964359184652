import React, { ReactNode, memo } from "react";
import { Row } from "uikit";

import Root from "./components/Root";
import ControlButtons from "./components/ControlButtons";

const DefaultPageHeader = memo<DefaultPageHeader.Props>(
	({
		onAdd,
		onEdit,
		onDelete,
		canAdd,
		canEdit,
		canDelete,
		titleAdd,
		idAdd,
		titleEdit,
		idEdit,
		titleDelete,
		idDelete,

		beforeAdditionalButtons,
		buttons,
		afterAdditionalButtons,
		leftFilters = null,
		filters = null,
		...props
	}) => (
		<Root gaps="10px*" justify="space-between" maxedWidth {...props}>
			<Row gaps="10px*" align="center">
				<ControlButtons
					onAdd={onAdd}
					onEdit={onEdit}
					onDelete={onDelete}
					canAdd={canAdd}
					canEdit={canEdit}
					canDelete={canDelete}
					titleAdd={titleAdd}
					idAdd={idAdd}
					titleEdit={titleEdit}
					idEdit={idEdit}
					titleDelete={titleDelete}
					idDelete={idDelete}
					beforeAdditionalButtons={beforeAdditionalButtons}
					buttons={buttons}
					afterAdditionalButtons={afterAdditionalButtons}
				/>
				{leftFilters}
			</Row>
			<Row align="center" gaps="20px*">
				{filters}
			</Row>
		</Root>
	),
);

declare namespace DefaultPageHeader {
	interface Props extends ControlButtons.Props, Root.Props {
		leftFilters?: ReactNode;
		filters?: ReactNode;
	}
}

export default DefaultPageHeader;
