import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "uikit";
import { useTypedSelector } from "../../../../../../../../../../redux/store";
import SIP from "../../../../../../../../../../services/SIP";
import Button from "../Button";

const CallClientButton: React.FC<CallClientButton.Props> = () => {
	const { t } = useTranslation();
	const sipToDispatcherId = useTypedSelector(
		(state) => state.sipToDispatcher.id,
	);

	const activeOrder = useTypedSelector(
		(state) => state.ordersPageReducer.activeOrder,
	);

	const disabled = useMemo(() => {
		if (!activeOrder?.id) return true;
		if (!sipToDispatcherId) return true;
		if (!activeOrder.passengers.length) return true;

		const hasCustomer = activeOrder.passengers.some(
			(passenger) => passenger.customer?.id,
		);

		return !hasCustomer;
	}, [sipToDispatcherId, activeOrder]);

	const onClick = useCallback(() => {
		if (sipToDispatcherId) {
			SIP.callToCustomer({
				sipToDispatcherId,
				orderId: activeOrder.id,
			});
		}
	}, [activeOrder, sipToDispatcherId]);

	return (
		<Button
			variant="secondary"
			disabled={disabled}
			settingsKeyId="callOrderClient"
			title={
				t(
					"pages.mainPage.pages.orders.orderHeader.secondRow.callClientButton.str0",
				) ?? ""
			}
			tabIndex={-1}
			onClick={onClick}
		>
			<Icon id="orders-call-client" size={20} />
		</Button>
	);
};

declare namespace CallClientButton {
	interface Props {}
}

export default CallClientButton;
