/**
 * ⚠️ **Important Notice** ⚠️
 *
 * **Synchronization Required**: When making any modifications to the structure of the
 * `ACCESS_TO_THE_COMPONENT` object or the `AccessKey` enum, it is crucial to synchronize
 * these changes with the backend. This is essential to ensure that the frontend and backend
 * data structures remain consistent and function correctly.
 *
 * **Action Required**:
 * - Communicate any updates to the backend development team.
 * - Ensure that the corresponding API or data models are updated to reflect the new structure.
 *
 * Keeping both frontend and backend in sync will help avoid potential issues and maintain
 * smooth functionality across the application.
 */

export enum AccessKey {
	SHOW = "show",
	MAIN = "main",
	LOGIN = "login",
	ORDERS = "orders",
	ACCOUNTING = "accounting",
	ACCOUNTS = "accounts",
	ARCHIVES = "archives",
	REPORTS = "reports",
	REPORTS_CHARTS = "reportsCharts",
	REPORTS_ACCOUNTING = "reportsAccounting",
	CUSTOMERS = "customers",
	BLACKLIST = "blacklist",
	PREFERENCES = "preferences",
	PREFERENCES_PAGE_NAME = "preferencesPageName",
	SETTINGS = "settings",
	SETTINGS_ORDERS = "settingsOrders",
	SETTINGS_TARIFFS = "settingsTariffs",
	SETTINGS_FINANCES = "settingsFinances",
	SETTINGS_MESSAGES = "settingsMessages",
	SETTINGS_MOBILE_APPS = "settingsMobileApps",
	SETTINGS_PARAMETERS = "settingsParameters",
	SETTINGS_MAP = "settingsMap",
	SETTINGS_ORDER_EXCHANGE = "settingsOrderExchange",
	SETTINGS_PAYMENT_SYSTEMS = "settingsPaymentSystems",
	SETTINGS_INTERFACE = "settingsInterface",
	SETTINGS_VOIP = "settingsVoip",
	EXECUTORS = "executors",
	TRANSPORT = "transport",
	USERS = "users",
	ROLES = "roles",
	CAR_PARKS = "carParks",
	SCHEDULES = "schedules",
	EXECUTOR_TEAMS = "executorTeams",

	CLIENTS = "clients",
	COUNTERPARTIES = "counterparties",
	AGENTS = "agents",
	CLIENT_GROUPS = "clientGroups",
	COUNTERPARTY_GROUPS = "counterpartyGroups",
	AGENT_GROUPS = "agentGroups",

	BLACKLIST_CUSTOMERS = "blacklistCustomers",
	BLACKLIST_EXECUTORS = "blacklistExecutors",
	BLACKLIST_TRANSPORT = "blacklistTransport",

	BANK_TRANSACTIONS = "bankTransactions",
	TRANSACTIONS = "transactions",
	RATINGS = "ratings",
	GPS_LOG = "gpsLog",
	MESSAGES = "messages",
	PUSH = "push",
	DRIVER_SHIFTS = "driverShifts",
	CHANGE_HISTORY = "changeHistory",
	CALL_HISTORY = "callsHistory",

	EXECUTORS_GENERAL = "executorsGeneral",
	EXECUTOR_BONUSES = "executorBonuses",
	CUSTOMER_BONUSES = "customerBonuses",
	BANK_ACCOUNTS = "bankAccounts",
	LOYALTY_PROGRAM = "loyaltyProgram",

	GENERAL = "general",

	CASHLESS_ORDERS = "cashlessOrders",
	CASHLESS_ORDERS_GENERAL = "cashlessOrdersGeneral",
	CASHLESS_ORDERS_PERIOD_REPORT = "cashlessOrdersPeriodReport",
	CASHLESS_ACCOUNTS_SUMMARY_REPORT = "cashlessAccountsSummaryReport",
	FUEL_DEDUCTION_ORDERS_REPORT = "fuelDeductionOrdersReport",
	AGENT_ORDERS_REPORT = "agentOrdersReport",
	ACCOUNTS_REPORT = "accountsReport",

	ORDERS_DISTRIBUTION = "ordersDistribution",

	MAIN_TARIFFS = "mainTariffs",
	AUTOMATIC_TARIFFS = "automaticTariffs",
	GENERAL_TARIFFS = "generalTariffs",
	ADDITIONAL = "additional",

	EXECUTOR_TARIFF_PLANS = "executorTariffPlans",
	EXECUTOR_TARIFF_PLANS_2 = "executorTariffPlans2",
	CUSTOMER_TARIFF_PLANS = "customerTariffPlans",
	USER_TARIFF_PLANS = "userTariffPlans",
	EXECUTOR_PENALTIES = "executorPenalties",
	CUSTOMER_PENALTIES = "customerPenalties",
	USER_PENALTIES = "userPenalties",

	PROVIDER_SMS = "providerSms",
	TEMPLATES_SMS = "templatesSms",
	PUSH_NOTICES = "pushNotices",

	MOBILE_APP_EXECUTOR = "mobileAppExecutor",
	MOBILE_APP_CLIENT = "mobileAppClient",

	SYSTEM = "system",
	APPLICATION = "application",

	CUSTOMER = "customer",
	EXECUTOR = "executor",

	COLORS = "colors",
	FONT = "font",
	KEYBINDS = "keybinds",

	CAR_MODELS_AND_BRANDS = "settingsCarModelsAndBrands",
	CAR_CLASSES = "carClasses",
	CAR_TYPES = "carTypes",
	OBJECTS = "objects",
	SECTORS = "sectors",
	PARKINGS = "parkings",
	PRICE_ZONES = "priceZones",
	SERVICES = "services",
}

export type AccessTranslate = {
	[key in AccessKey]: string;
};

// Создаем новый объект с ключами от AccessKey
const ACCESS_TRANSLATE: AccessTranslate = {
	[AccessKey.SHOW]: "show",
	[AccessKey.MAIN]: "routes.main",
	[AccessKey.LOGIN]: "routes.loginPage",
	[AccessKey.ORDERS]: "mainPage.orders.title",
	[AccessKey.ACCOUNTING]: "mainPage.accounting.title",
	[AccessKey.ACCOUNTS]: "mainPage.accounts.title",
	[AccessKey.ARCHIVES]: "mainPage.archives.title",
	[AccessKey.REPORTS]: "mainPage.reports.title",
	[AccessKey.REPORTS_CHARTS]: "mainPage.reports.charts.title",
	[AccessKey.REPORTS_ACCOUNTING]: "mainPage.reports.accounting.title",
	[AccessKey.CUSTOMERS]: "mainPage.customers.title",
	[AccessKey.BLACKLIST]: "mainPage.blacklist.title",
	[AccessKey.PREFERENCES]: "mainPage.preferences.title",
	[AccessKey.PREFERENCES_PAGE_NAME]: "mainPage.preferences.title",
	[AccessKey.SETTINGS]: "settings.title",

	[AccessKey.SETTINGS_ORDERS]: "settings.orders.title",
	[AccessKey.SETTINGS_TARIFFS]: "settings.tariffs.title",
	[AccessKey.SETTINGS_FINANCES]: "settings.finances.title",
	[AccessKey.SETTINGS_MESSAGES]: "settings.messages.title",
	[AccessKey.SETTINGS_MOBILE_APPS]: "settings.mobileApps.title",
	[AccessKey.SETTINGS_PARAMETERS]: "settings.parameters.title",
	[AccessKey.SETTINGS_MAP]: "settings.map.title",
	[AccessKey.SETTINGS_ORDER_EXCHANGE]: "settings.orderExchange.title",
	[AccessKey.SETTINGS_PAYMENT_SYSTEMS]: "settings.paymentSystems.title",
	[AccessKey.SETTINGS_INTERFACE]: "settings.interface.title",
	[AccessKey.SETTINGS_VOIP]: "settings.voip.title",

	[AccessKey.EXECUTORS]: "mainPage.accounts.executors.title",
	[AccessKey.TRANSPORT]: "mainPage.accounts.transport.title",
	[AccessKey.USERS]: "mainPage.accounts.users.title",
	[AccessKey.ROLES]: "mainPage.accounts.roles.title",
	[AccessKey.CAR_PARKS]: "mainPage.accounts.carParks.title",
	[AccessKey.SCHEDULES]: "mainPage.accounts.schedules.title",
	[AccessKey.EXECUTOR_TEAMS]: "mainPage.accounts.executorTeams.title",

	[AccessKey.CLIENTS]: "mainPage.customers.clients.title",
	[AccessKey.COUNTERPARTIES]: "mainPage.customers.counterparties.title",
	[AccessKey.AGENTS]: "mainPage.customers.agents.title",
	[AccessKey.CLIENT_GROUPS]: "mainPage.customers.clientGroups.title",
	[AccessKey.COUNTERPARTY_GROUPS]:
		"mainPage.customers.counterpartyGroups.title",
	[AccessKey.AGENT_GROUPS]: "mainPage.customers.agentGroups.title",

	[AccessKey.BLACKLIST_CUSTOMERS]: "mainPage.blacklist.customers.title",
	[AccessKey.BLACKLIST_EXECUTORS]: "mainPage.blacklist.executors.title",
	[AccessKey.BLACKLIST_TRANSPORT]: "mainPage.blacklist.transport.title",

	[AccessKey.BANK_TRANSACTIONS]: "mainPage.archives.bankTransactions.title",
	[AccessKey.TRANSACTIONS]: "mainPage.archives.transactions.title",
	[AccessKey.RATINGS]: "mainPage.archives.ratings.title",
	[AccessKey.GPS_LOG]: "mainPage.archives.gpsLog.title",
	[AccessKey.MESSAGES]: "mainPage.archives.messages.title",
	[AccessKey.PUSH]: "mainPage.archives.push.title",
	[AccessKey.DRIVER_SHIFTS]: "mainPage.archives.driverShifts.title",
	[AccessKey.CHANGE_HISTORY]: "mainPage.archives.changeHistory.title",
	[AccessKey.CALL_HISTORY]: "mainPage.archives.callsHistory.title",

	[AccessKey.EXECUTORS_GENERAL]: "mainPage.accounting.executors.title",
	[AccessKey.EXECUTOR_BONUSES]: "mainPage.accounting.executorBonuses.title",
	[AccessKey.CUSTOMER_BONUSES]: "mainPage.accounting.customerBonuses.title",
	[AccessKey.BANK_ACCOUNTS]: "mainPage.accounting.bankAccounts.title",
	[AccessKey.LOYALTY_PROGRAM]: "mainPage.accounting.loyaltyProgram.title",

	[AccessKey.GENERAL]: "mainPage.reports.charts.byOrders.title",

	[AccessKey.CASHLESS_ORDERS]:
		"mainPage.reports.accounting.cashlessOrders.title",
	[AccessKey.CASHLESS_ORDERS_GENERAL]:
		"mainPage.reports.accounting.cashlessOrdersGeneral.title",
	[AccessKey.CASHLESS_ORDERS_PERIOD_REPORT]:
		"mainPage.reports.accounting.cashlessOrdersPeriodReport.title",
	[AccessKey.CASHLESS_ACCOUNTS_SUMMARY_REPORT]:
		"mainPage.reports.accounting.cashlessAccountsSummaryReport.title",
	[AccessKey.FUEL_DEDUCTION_ORDERS_REPORT]:
		"mainPage.reports.accounting.fuelDeductionOrdersReport.title",
	[AccessKey.AGENT_ORDERS_REPORT]:
		"mainPage.reports.accounting.agentOrdersReport.title",
	[AccessKey.ACCOUNTS_REPORT]:
		"mainPage.reports.accounting.accountsReport.title",

	[AccessKey.ORDERS_DISTRIBUTION]: "settings.ordersDistribution.title",

	[AccessKey.MAIN_TARIFFS]: "settings.tariffs.main.title",
	[AccessKey.AUTOMATIC_TARIFFS]: "settings.tariffs.auto.title",
	[AccessKey.GENERAL_TARIFFS]: "settings.tariffs.general.title",
	[AccessKey.ADDITIONAL]: "settings.tariffs.additional.title",

	[AccessKey.EXECUTOR_TARIFF_PLANS]:
		"settings.finances.executorTariffPlans.title",
	[AccessKey.EXECUTOR_TARIFF_PLANS_2]:
		"settings.finances.executorTariffPlans.title",
	[AccessKey.CUSTOMER_TARIFF_PLANS]:
		"settings.finances.customerTariffPlans.title",
	[AccessKey.USER_TARIFF_PLANS]: "settings.finances.userTariffPlans.title",
	[AccessKey.EXECUTOR_PENALTIES]: "settings.finances.executorPenalties.title",
	[AccessKey.CUSTOMER_PENALTIES]: "settings.finances.customerPenalties.title",
	[AccessKey.USER_PENALTIES]: "settings.finances.userPenalties.title",

	[AccessKey.PROVIDER_SMS]: "settings.messages.providerSms.title",
	[AccessKey.TEMPLATES_SMS]: "settings.messages.templatesSms.title",
	[AccessKey.PUSH_NOTICES]: "settings.messages.pushNotices.title",

	[AccessKey.MOBILE_APP_EXECUTOR]: "settings.mobileApps.executor.title",
	[AccessKey.MOBILE_APP_CLIENT]: "settings.mobileApps.client.title",

	[AccessKey.SYSTEM]: "settings.parameters.tabs.system.title",
	[AccessKey.APPLICATION]: "settings.parameters.tabs.programs.title",

	[AccessKey.CUSTOMER]: "settings.paymentSystems.customer.title",
	[AccessKey.EXECUTOR]: "settings.paymentSystems.executor.title",

	[AccessKey.COLORS]: "settings.interface.colors.title",
	[AccessKey.FONT]: "settings.interface.fonts.title",
	[AccessKey.KEYBINDS]: "settings.interface.keybinds.title",

	[AccessKey.CAR_MODELS_AND_BRANDS]: "pages.preferencesPages.navInfo.str200",
	[AccessKey.CAR_CLASSES]: "pages.preferencesPages.navInfo.str201",
	[AccessKey.CAR_TYPES]: "pages.preferencesPages.navInfo.str202",
	[AccessKey.OBJECTS]: "pages.preferencesPages.navInfo.str204",
	[AccessKey.SECTORS]: "pages.preferencesPages.navInfo.str205",
	[AccessKey.PARKINGS]: "pages.preferencesPages.navInfo.str206",
	[AccessKey.PRICE_ZONES]: "pages.preferencesPages.navInfo.str207",
	[AccessKey.SERVICES]: "pages.preferencesPages.navInfo.str208",
};

export interface AccessComponent {
	[key: string]: boolean | AccessComponent | undefined;
}

export type DisplayFields = {
	[key in AccessKey]?: AccessComponent;
};

const ACCESS_TO_THE_COMPONENT: DisplayFields = {
	[AccessKey.MAIN]: { [AccessKey.SHOW]: true },
	[AccessKey.LOGIN]: { [AccessKey.SHOW]: true },
	[AccessKey.ORDERS]: { [AccessKey.SHOW]: true },
	[AccessKey.ACCOUNTING]: {
		[AccessKey.SHOW]: true,
		//* Tabs
		[AccessKey.EXECUTORS_GENERAL]: { [AccessKey.SHOW]: true },
		[AccessKey.EXECUTOR_BONUSES]: { [AccessKey.SHOW]: true },
		[AccessKey.CUSTOMER_BONUSES]: { [AccessKey.SHOW]: true },
		[AccessKey.BANK_ACCOUNTS]: { [AccessKey.SHOW]: true },
		[AccessKey.AGENTS]: { [AccessKey.SHOW]: true },
		[AccessKey.LOYALTY_PROGRAM]: { [AccessKey.SHOW]: true },
	},
	[AccessKey.ACCOUNTS]: {
		[AccessKey.SHOW]: true,
		//* Tabs
		[AccessKey.EXECUTORS]: { [AccessKey.SHOW]: true },
		[AccessKey.TRANSPORT]: { [AccessKey.SHOW]: true },
		[AccessKey.USERS]: { [AccessKey.SHOW]: true },
		[AccessKey.ROLES]: { [AccessKey.SHOW]: true },
		[AccessKey.CAR_PARKS]: { [AccessKey.SHOW]: true },
		[AccessKey.SCHEDULES]: { [AccessKey.SHOW]: true },
		[AccessKey.EXECUTOR_TEAMS]: { [AccessKey.SHOW]: true },
	},
	[AccessKey.ARCHIVES]: {
		[AccessKey.SHOW]: true,
		//* Tabs
		[AccessKey.ORDERS]: { [AccessKey.SHOW]: true },
		[AccessKey.BANK_TRANSACTIONS]: { [AccessKey.SHOW]: true },
		[AccessKey.TRANSACTIONS]: { [AccessKey.SHOW]: true },
		[AccessKey.RATINGS]: { [AccessKey.SHOW]: true },
		[AccessKey.GPS_LOG]: { [AccessKey.SHOW]: true },
		[AccessKey.MESSAGES]: { [AccessKey.SHOW]: true },
		[AccessKey.PUSH]: { [AccessKey.SHOW]: true },
		[AccessKey.DRIVER_SHIFTS]: { [AccessKey.SHOW]: true },
		[AccessKey.CHANGE_HISTORY]: { [AccessKey.SHOW]: true },
		[AccessKey.CALL_HISTORY]: { [AccessKey.SHOW]: true },
	},
	[AccessKey.REPORTS]: {
		[AccessKey.SHOW]: true,
		//* Menu
		[AccessKey.REPORTS_CHARTS]: {
			[AccessKey.SHOW]: true,
			//* Tabs
			[AccessKey.GENERAL]: { [AccessKey.SHOW]: true },
		},
		[AccessKey.REPORTS_ACCOUNTING]: {
			[AccessKey.SHOW]: true,
			//* Tabs
			[AccessKey.CASHLESS_ORDERS]: { [AccessKey.SHOW]: true },
			[AccessKey.CASHLESS_ORDERS_GENERAL]: { [AccessKey.SHOW]: true },
			[AccessKey.CASHLESS_ORDERS_PERIOD_REPORT]: {
				[AccessKey.SHOW]: true,
			},
			[AccessKey.CASHLESS_ACCOUNTS_SUMMARY_REPORT]: {
				[AccessKey.SHOW]: true,
			},
			[AccessKey.FUEL_DEDUCTION_ORDERS_REPORT]: {
				[AccessKey.SHOW]: true,
			},
			[AccessKey.AGENT_ORDERS_REPORT]: { [AccessKey.SHOW]: true },
			[AccessKey.ACCOUNTS_REPORT]: { [AccessKey.SHOW]: true },
		},
	},
	[AccessKey.CUSTOMERS]: {
		[AccessKey.SHOW]: true,
		//* Tabs
		[AccessKey.CLIENTS]: { [AccessKey.SHOW]: true },
		[AccessKey.COUNTERPARTIES]: { [AccessKey.SHOW]: true },
		[AccessKey.AGENTS]: { [AccessKey.SHOW]: true },
		[AccessKey.CLIENT_GROUPS]: { [AccessKey.SHOW]: true },
		[AccessKey.COUNTERPARTY_GROUPS]: { [AccessKey.SHOW]: true },
		[AccessKey.AGENT_GROUPS]: { [AccessKey.SHOW]: true },
	},
	[AccessKey.BLACKLIST]: {
		[AccessKey.SHOW]: true,
		//* Tabs
		[AccessKey.BLACKLIST_CUSTOMERS]: { [AccessKey.SHOW]: true },
		[AccessKey.BLACKLIST_EXECUTORS]: { [AccessKey.SHOW]: true },
		[AccessKey.BLACKLIST_TRANSPORT]: { [AccessKey.SHOW]: true },
	},
	[AccessKey.PREFERENCES]: { [AccessKey.SHOW]: true },
	[AccessKey.PREFERENCES_PAGE_NAME]: {
		[AccessKey.SHOW]: true,
		//* Tabs
		[AccessKey.CAR_MODELS_AND_BRANDS]: { [AccessKey.SHOW]: true },
		[AccessKey.CAR_CLASSES]: { [AccessKey.SHOW]: true },
		[AccessKey.CAR_TYPES]: { [AccessKey.SHOW]: true },
		[AccessKey.COLORS]: { [AccessKey.SHOW]: true },
		[AccessKey.OBJECTS]: { [AccessKey.SHOW]: true },
		[AccessKey.SECTORS]: { [AccessKey.SHOW]: true },
		[AccessKey.PARKINGS]: { [AccessKey.SHOW]: true },
		[AccessKey.PRICE_ZONES]: { [AccessKey.SHOW]: true },
		[AccessKey.SERVICES]: { [AccessKey.SHOW]: true },
	},
	[AccessKey.SETTINGS]: {
		[AccessKey.SHOW]: true,
		//* Menu
		[AccessKey.SETTINGS_ORDERS]: {
			[AccessKey.SHOW]: true,
			//* Tabs
			[AccessKey.ORDERS]: { [AccessKey.SHOW]: true },
			[AccessKey.ORDERS_DISTRIBUTION]: { [AccessKey.SHOW]: true },
		},
		[AccessKey.SETTINGS_TARIFFS]: {
			[AccessKey.SHOW]: true,
			//* Tabs
			[AccessKey.MAIN_TARIFFS]: { [AccessKey.SHOW]: true },
			[AccessKey.AUTOMATIC_TARIFFS]: { [AccessKey.SHOW]: true },
			[AccessKey.GENERAL_TARIFFS]: { [AccessKey.SHOW]: true },
			[AccessKey.ADDITIONAL]: { [AccessKey.SHOW]: true },
		},
		[AccessKey.SETTINGS_FINANCES]: {
			[AccessKey.SHOW]: true,
			//* Tabs
			[AccessKey.EXECUTOR_TARIFF_PLANS]: { [AccessKey.SHOW]: true },
			[AccessKey.EXECUTOR_TARIFF_PLANS_2]: { [AccessKey.SHOW]: true },
			[AccessKey.CUSTOMER_TARIFF_PLANS]: { [AccessKey.SHOW]: true },
			[AccessKey.USER_TARIFF_PLANS]: { [AccessKey.SHOW]: true },
			[AccessKey.EXECUTOR_PENALTIES]: { [AccessKey.SHOW]: true },
			[AccessKey.CUSTOMER_PENALTIES]: { [AccessKey.SHOW]: true },
			[AccessKey.USER_PENALTIES]: { [AccessKey.SHOW]: true },
		},
		[AccessKey.SETTINGS_MESSAGES]: {
			[AccessKey.SHOW]: true,
			//* Tabs
			[AccessKey.PROVIDER_SMS]: { [AccessKey.SHOW]: true },
			[AccessKey.TEMPLATES_SMS]: { [AccessKey.SHOW]: true },
			[AccessKey.PUSH_NOTICES]: { [AccessKey.SHOW]: true },
		},

		[AccessKey.SETTINGS_MOBILE_APPS]: {
			[AccessKey.SHOW]: true,
			//* Tabs
			[AccessKey.MOBILE_APP_EXECUTOR]: { [AccessKey.SHOW]: true },
			[AccessKey.MOBILE_APP_CLIENT]: { [AccessKey.SHOW]: true },
		},
		[AccessKey.SETTINGS_PARAMETERS]: {
			[AccessKey.SHOW]: true,
			//* Tabs
			[AccessKey.SYSTEM]: { [AccessKey.SHOW]: true },
			[AccessKey.APPLICATION]: { [AccessKey.SHOW]: true },
		},
		[AccessKey.SETTINGS_MAP]: { [AccessKey.SHOW]: true },
		[AccessKey.SETTINGS_ORDER_EXCHANGE]: { [AccessKey.SHOW]: true },
		[AccessKey.SETTINGS_PAYMENT_SYSTEMS]: {
			[AccessKey.SHOW]: true,
			//* Tabs
			[AccessKey.CUSTOMER]: { [AccessKey.SHOW]: true },
			[AccessKey.EXECUTOR]: { [AccessKey.SHOW]: true },
		},
		[AccessKey.SETTINGS_INTERFACE]: {
			[AccessKey.SHOW]: true,
			//* Tabs
			[AccessKey.COLORS]: { [AccessKey.SHOW]: true },
			[AccessKey.FONT]: { [AccessKey.SHOW]: true },
			[AccessKey.KEYBINDS]: { [AccessKey.SHOW]: true },
		},
		[AccessKey.SETTINGS_VOIP]: { [AccessKey.SHOW]: true },
	},
};

export { ACCESS_TRANSLATE, ACCESS_TO_THE_COMPONENT };
