import React, {
	Dispatch,
	RefAttributes,
	SetStateAction,
	memo,
	useMemo,
} from "react";
import { react, useRefWithSetter } from "uikit";

import { ModelId } from "../../../../../../../../../../../../../../../../../types/ModelId";
import useObjectEditor from "../../../../../../../../../../../../../../../../../hooks/useObjectEditor";

import { CardsTabs as Tabs } from "./tabs";
import History from "./tabs/History";
import Main from "./tabs/Main";
import Employees from "./tabs/Employees";
import Orders from "./tabs/Orders";
import Files from "./tabs/Files";
import Transactions from "./tabs/Transactions";

import Root from "./components/Root";
import Header from "../Header";
import InternalController from "./Controller";
import { PaymentAccount } from "../../../../../../../../../../../../../../../../../types/PaymentAccount";
import Modal from "../..";
import Accounts from "../../../../..";

const ContentBase = react.withController<Content.PropsBase, Content.Controller>(
	({
		controller,
		id,
		value,
		onChange,
		tab,
		setTab,
		paymentAccounts,
		companyId,
		taxiServiceId,
		counterpartyId,
		cards,
		checks,
	}) => {
		const [mainTabRef, setMainTabRef] = useRefWithSetter<Main.Ref>(null);
		controller.setContext({ mainTabRef, setTab });

		const valueEditor = useObjectEditor(value, onChange);

		const mainTabValue = valueEditor.usePicker([
			"code",
			"additionalFields",
			"accessAllEmployeeToPersonalApp",
			"isAccessToCorporateAccount",
			"activationDate",
			"completionDate",
			"corporateLogin",
			"corporatePassword",
			"isLoginOnlyByEmployee",
		]);

		const employees = valueEditor.useGetter("employees");
		const setEmployees = valueEditor.useSetter("employees");
		const setEmployeeDeleteIds = valueEditor.useSetter("employeeDeleteIds");

		const filesTabValue = valueEditor.usePicker([
			"otherFiles",
			"personalFiles",
		]);

		const updateAllData = valueEditor.useAssigner();

		const options = useMemo(
			() => ({ checkCardIds: id ? [id] : undefined }),
			[id],
		);

		const content = useMemo(
			() => (
				<>
					<Main
						cardId={id}
						visible={tab === Tabs.MAIN}
						ref={setMainTabRef}
						value={mainTabValue}
						onChange={updateAllData}
					/>
					<Employees
						visible={tab === Tabs.EMPLOYEES}
						value={employees}
						onChange={setEmployees}
						companyId={companyId}
						taxiServiceId={taxiServiceId}
						counterpartyId={counterpartyId}
						cards={cards}
						checks={checks}
						setEmployeeDeleteIds={setEmployeeDeleteIds}
					/>
					<Orders
						cardId={id}
						visible={tab === Tabs.ORDERS}
						value={options}
					/>
					<Transactions
						visible={tab === Tabs.TRANSACTIONS}
						paymentAccounts={paymentAccounts}
						checkCardId={id}
					/>
					<Files
						visible={tab === Tabs.FILES}
						value={filesTabValue}
						onChange={updateAllData}
					/>
					<History visible={tab === Tabs.HISTORY} value={{}} />
				</>
			),
			[
				id,
				tab,
				setMainTabRef,
				mainTabValue,
				updateAllData,
				employees,
				setEmployees,
				companyId,
				taxiServiceId,
				counterpartyId,
				cards,
				checks,
				options,
				paymentAccounts,
				filesTabValue,
			],
		);

		return <Root>{content}</Root>;
	},
	InternalController,
);
const Content = memo(ContentBase);

declare namespace Content {
	type Ref = InternalController | null;
	type Controller = InternalController;

	interface PropsBase {
		id?: ModelId;
		value: Value;
		onChange: Dispatch<Value>;
		tab: Header.Tab;
		setTab: Dispatch<SetStateAction<Header.Tab>>;
		paymentAccounts: PaymentAccount[];
		companyId?: number;
		taxiServiceId?: number;
		counterpartyId?: number;
		cards: Modal.Value[];
		checks?: Accounts.Check[];
	}

	type Props = PropsBase & RefAttributes<Ref>;

	interface Value extends Main.Value, Files.Value {
		employees: Employees.Value;
		employeeDeleteIds?: number[];
	}
}

export default Content;
