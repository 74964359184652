/* eslint-disable no-shadow */
import { cloneDeep } from "lodash";
import React, {
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";

import { Currency, AdditionalTariff } from "../../../../../../services";
import { useModelSubscribe } from "../../../../../../hooks";
import { ModelId } from "../../../../../../types/ModelId";
import mapByKey from "../../../../../../utils/mapByKey";
import DefaultPageHeader from "../../../../../../components/DefaultPageHeader";
import { SettingsTabProps } from "../../../../../../components/Settings/tabProps";
import { useTableOptions } from "../../../../../../components/LightTable";

import defaultValue from "./constants/defaultValue";
import Content from "./components/Content";
import Modal from "./components/Modal";
import Root from "./components/Root";
import tPath from "./constants/tPath";

const Additional: React.FC<SettingsTabProps> = () => {
	const { t } = useTranslation();

	const { setDataLength, dataLength, lang, limit, editor, onChange } =
		useTableOptions();

	const optionsAdditionalTariff = useMemo(() => {
		// const order = {};
		const payload: AdditionalTariff.SubscribeOptions = {
			limit,
			// order,
		};

		// if (sort.dataKey) order[sort.dataKey] = sort.sortType;
		return payload;
	}, [limit]);

	const { models: settings } = useModelSubscribe(
		optionsAdditionalTariff,
		AdditionalTariff,
	);

	const [currencies, setCurrencies] = useState<Currency.Model[]>([]);

	useLayoutEffect(() => {
		if (dataLength !== settings.length) {
			setDataLength(settings.length);
		}
	}, [settings.length, dataLength, setDataLength]);

	useEffect(() => {
		Currency.getAll().then((currencies) => {
			setCurrencies(currencies);
		});
	}, [settings]);

	const [showModal, setShowModal] = useState(false);

	const [selected, setSelected] = useState<ModelId[]>([]);

	const [editingItem, setEditingItem] = useState<Modal.Value>(
		settings[0] ?? defaultValue,
	);

	const modelItemById = useMemo(() => mapByKey(settings, "id"), [settings]);

	const edit = useCallback(
		(id: ModelId) => {
			const item = modelItemById[id];
			setEditingItem(cloneDeep(item));

			setShowModal(true);
			setSelected([]);
		},
		[modelItemById],
	);

	const editHeaderHandler = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const editContentHandler = useCallback(
		(settings: AdditionalTariff.Model) => edit(settings.id),
		[edit],
	);

	const cancelHandler = useCallback(() => {
		setShowModal(false);
	}, []);

	const saveHandler = useCallback(
		async (settings: AdditionalTariff.Model) => {
			await AdditionalTariff.update(settings);

			setShowModal(false);
		},
		[],
	);

	const headerTitle = useMemo(
		() =>
			editingItem?.id
				? `${t(`${tPath}.modal.title.edit`)} "${
						editingItem.name[lang]
				  }"`
				: t(`${tPath}.modal.title.add`),
		[editingItem?.id, editingItem.name, lang, t],
	);

	return (
		<Root sizes="auto! 1fr" gaps="16px" maxedWidth maxedHeight>
			<DefaultPageHeader
				buttons={[
					{
						icon: { id: "pencil", size: 20 },
						onClick: editHeaderHandler,
						disabled: selected.length !== 1,
					},
				]}
			/>
			<Content
				value={settings}
				selected={selected}
				setSelected={setSelected}
				onEdit={editContentHandler}
				editorTable={editor}
				onChangeTable={onChange}
			/>
			{showModal && (
				<Modal
					onCancel={cancelHandler}
					onSave={saveHandler}
					currencies={currencies}
					value={editingItem}
					headerTitle={headerTitle}
				/>
			)}
		</Root>
	);
};

export default Additional;
