import { getPRPC } from "../../../../hooks/usePRPC";
import BackendSettings, {
	ToggleGroups,
} from "../../../../types/BackendSettings";
import createRPCQuery from "../../../../utils/createRPCQuery.util";
import { store } from "../../../store";

export default function saveGlobalSettings() {
	return async () => {
		const prpcow = getPRPC();

		if (!prpcow) return null;

		// const fontsState = store.getState().settings.fonts;
		// const keybindsState = store.getState().settings.keybinds;
		// const preordersState = store.getState().settings.preorders;
		const mapSettingsState = store.getState().settings.map;
		const executorAppGeneralState =
			store.getState().settings.executorAppGeneral;
		const executorAppInterfaceGeneralState =
			store.getState().settings.executorAppInterfaceGeneral;
		const executorAppInterfaceOrdersState =
			store.getState().settings.executorAppInterfaceOrders;
		const executorAppInterfaceMapState =
			store.getState().settings.executorAppInterfaceMap;

		const { defaultSearchType, addressCacheExpirationMs, ...rest } =
			mapSettingsState;

		const settings: BackendSettings = {
			map: {
				general: {
					defaultSearchType: defaultSearchType || [],
					addressCacheExpirationMs:
						addressCacheExpirationMs / 24 / 3600e3,
					...rest,
				},
				polygon: {},
				localObject: {},
			},
			orders: {
				timeToLive: 30 * 60 * 1000,
			},
			executorApp: {
				general: executorAppGeneralState,

				orders: {
					...executorAppInterfaceGeneralState,

					filter: {
						display:
							executorAppInterfaceGeneralState.filter.displaySelf,
						...(executorAppInterfaceGeneralState.filter
							.display as Omit<ToggleGroups.Filter, "display">),
					},

					sort: {
						display:
							executorAppInterfaceGeneralState.sort.displaySelf,
						...(executorAppInterfaceGeneralState.sort
							.display as Omit<ToggleGroups.Sort, "display">),
					},

					parking: {
						display:
							executorAppInterfaceGeneralState.parking
								.displaySelf,
						...(executorAppInterfaceGeneralState.parking
							.display as Omit<ToggleGroups.Parking, "display">),
					},

					call: {
						display:
							executorAppInterfaceGeneralState.call.displaySelf,
						...(executorAppInterfaceGeneralState.call
							.display as Omit<ToggleGroups.Call, "display">),
					},

					currentBalance: {
						display:
							executorAppInterfaceGeneralState.currentBalance
								.displaySelf,
						...(executorAppInterfaceGeneralState.currentBalance
							.display as Omit<
							ToggleGroups.CurrentBalance,
							"display"
						>),
					},

					income: {
						display:
							executorAppInterfaceGeneralState.income.displaySelf,
						...(executorAppInterfaceGeneralState.income
							.display as Omit<ToggleGroups.Income, "display">),
					},

					taximeter: {
						display:
							executorAppInterfaceGeneralState.taximeter
								.displaySelf,
						...(executorAppInterfaceGeneralState.taximeter
							.display as Omit<
							ToggleGroups.Taximeter,
							"display"
						>),
					},

					ownOrder: {
						display:
							executorAppInterfaceGeneralState.ownOrder
								.displaySelf,
						...(executorAppInterfaceGeneralState.ownOrder
							.display as Omit<ToggleGroups.OwnOrder, "display">),
					},

					rating: {
						display:
							executorAppInterfaceGeneralState.rating.displaySelf,
						...(executorAppInterfaceGeneralState.rating
							.display as Omit<ToggleGroups.Rating, "display">),
					},

					chat: {
						display:
							executorAppInterfaceGeneralState.chat.displaySelf,
						...(executorAppInterfaceGeneralState.chat
							.display as Omit<ToggleGroups.Chat, "display">),
					},

					live: {
						displayDistance:
							executorAppInterfaceOrdersState.displayDistanceInLive,
						displayCost:
							executorAppInterfaceOrdersState.displayCostInLive,
						displayRouteLength:
							executorAppInterfaceOrdersState.displayRouteLengthInLive,
						displayAverageCostPerKm:
							executorAppInterfaceOrdersState.displayAverageCostPerKmInLive,
						displayRideCount:
							executorAppInterfaceOrdersState.displayRideCountInLive,
						displayVehicleClass:
							executorAppInterfaceOrdersState.displayVehicleClassInLive,
						displayClientStatus:
							executorAppInterfaceOrdersState.displayClientStatusInLive,
						displayOrderPoints:
							executorAppInterfaceOrdersState.displayOrderPointsInLive,
						displaySectors:
							executorAppInterfaceOrdersState.displaySectorsInLive,
						displayNotes:
							executorAppInterfaceOrdersState.displayNotesInLive,
						displayServices:
							executorAppInterfaceOrdersState.displayServicesInLive,
					},

					required: {
						displayDistance:
							executorAppInterfaceOrdersState.displayDistanceInRequiredOrder,
						displayCost:
							executorAppInterfaceOrdersState.displayCostInRequiredOrder,
						displayRouteLength:
							executorAppInterfaceOrdersState.displayRouteLengthInRequiredOrder,
						displayAverageCostPerKm:
							executorAppInterfaceOrdersState.displayAverageCostPerKmInRequiredOrder,
						displayRideCount:
							executorAppInterfaceOrdersState.displayRideCountInRequiredOrder,
						displayVehicleClass:
							executorAppInterfaceOrdersState.displayVehicleClassInRequiredOrder,
						displayClientStatus:
							executorAppInterfaceOrdersState.displayClientStatusInRequiredOrder,
						displayOrderPoints:
							executorAppInterfaceOrdersState.displayOrderPointsInRequiredOrder,
						displaySectors:
							executorAppInterfaceOrdersState.displaySectorsInRequiredOrder,
						displayNotes:
							executorAppInterfaceOrdersState.displayNotesInRequiredOrder,
						displayServices:
							executorAppInterfaceOrdersState.displayServicesInRequiredOrder,
					},

					inProgress: {
						displayDistance:
							executorAppInterfaceOrdersState.displayDistanceInOrderInProgress,
						displayCost:
							executorAppInterfaceOrdersState.displayCostInOrderInProgress,
						displayRouteLength:
							executorAppInterfaceOrdersState.displayRouteLengthInOrderInProgress,
						displayAverageCostPerKm:
							executorAppInterfaceOrdersState.displayAverageCostPerKmInOrderInProgress,
						displayRideCount:
							executorAppInterfaceOrdersState.displayRideCountInOrderInProgress,
						displayVehicleClass:
							executorAppInterfaceOrdersState.displayVehicleClassInOrderInProgress,
						displayClientStatus:
							executorAppInterfaceOrdersState.displayClientStatusInOrderInProgress,
						displayOrderPoints:
							executorAppInterfaceOrdersState.displayOrderPointsInOrderInProgress,
						displaySectors:
							executorAppInterfaceOrdersState.displaySectorsInOrderInProgress,
						displayNotes:
							executorAppInterfaceOrdersState.displayNotesInOrderInProgress,
						displayServices:
							executorAppInterfaceOrdersState.displayServicesInOrderInProgress,
					},

					exchangeServer: {
						displayDistance:
							executorAppInterfaceOrdersState.displayDistanceInOrderFromExchangeServer,
						displayCost:
							executorAppInterfaceOrdersState.displayCostInOrderFromExchangeServer,
						displayRouteLength:
							executorAppInterfaceOrdersState.displayRouteLengthInOrderFromExchangeServer,
						displayAverageCostPerKm:
							executorAppInterfaceOrdersState.displayAverageCostPerKmInOrderFromExchangeServer,
						displayRideCount:
							executorAppInterfaceOrdersState.displayRideCountInOrderFromExchangeServer,
						displayVehicleClass:
							executorAppInterfaceOrdersState.displayVehicleClassInOrderFromExchangeServer,
						displayClientStatus:
							executorAppInterfaceOrdersState.displayClientStatusInOrderFromExchangeServer,
						displayOrderPoints:
							executorAppInterfaceOrdersState.displayOrderPointsInOrderFromExchangeServer,
						displaySectors:
							executorAppInterfaceOrdersState.displaySectorsInOrderFromExchangeServer,
						displayNotes:
							executorAppInterfaceOrdersState.displayNotesInOrderFromExchangeServer,
						displayServices:
							executorAppInterfaceOrdersState.displayServicesInOrderFromExchangeServer,
					},
				},

				map: {
					displayMap: executorAppInterfaceMapState.displayMap,
					displayExecutors:
						executorAppInterfaceMapState.displayExecutors,
					displayOrders: executorAppInterfaceMapState.displayOrders,
				},
			},
		};

		await createRPCQuery(() => prpcow?.theirsModel.settings.save(settings));
		return null;
	};
}
