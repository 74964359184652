import React, { memo, useCallback, useMemo } from "react";
import { Icon } from "uikit";
import { Controller, ControllerRenderProps } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { uniq, uniqBy } from "lodash";

import Service from "../../../../../../../../services/Service";
import useModelSubscribe from "../../../../../../../../hooks/useModelSubscribe2";
import { useTypedSelector } from "../../../../../../../../redux/store";
import { Schema } from "../../../../../../../../redux/constants/OrdersPage/order";
import { useKeyBindSettings } from "../../../../../../../../hooks/useKeyBindSettings";
import {
	ListSelectMemo,
	ListSelect,
} from "../../../../../../../../components/Orders";
import {
	StyledWrapIcon,
	StyledColumn,
	Text,
} from "../../../../../../../../components/common";
import { OrderFormProps } from "../../types/FormProps";

const Services: React.FC<Services.Props> = ({ tab, form, disabled }) => {
	const { t } = useTranslation();
	const language = useTypedSelector((state) => state.session.language);
	const { rates } = useTypedSelector(
		(state) => state.preferencesReducer.rates,
	);

	const watchedTaxiServiceId = useMemo(
		() => tab?.form?.taxiServiceId,
		[tab?.form?.taxiServiceId],
	);

	const serviceSubscribe = useModelSubscribe(
		{ taxiServiceIds: watchedTaxiServiceId ? [watchedTaxiServiceId] : [] },
		Service,
	);

	const servicesModel = useMemo(
		() => serviceSubscribe?.models ?? [],
		[serviceSubscribe?.models],
	);

	const rateId = useMemo(() => tab?.form?.rateId, [tab?.form?.rateId]);

	const styles = useMemo(
		() => ({
			wrap: {
				justify: "center",
				alignItems: "space-between",
				w: "max-content",
				bgC: "transparent",
				cursor: disabled ? "not-allowed" : "pointer",
			},
			icon: {
				w: "18px",
				h: "18px",
				colors: "#5e6b73",
			},
			arrow: {
				w: "16px",
				h: "16px",
				colors: "#5e6b73",
				cursor: disabled ? "not-allowed" : "pointer",
			},
			text: {
				w: "auto",
				colors: disabled ? "#9BA3A8" : "#21333F",
			},
		}),
		[disabled],
	);

	const serviceModalOptions = useMemo<ListSelect.Options>(() => {
		const ratesServices =
			rates?.find((item) => item.id === rateId) || rates?.at(0);

		const tabServices = servicesModel.filter((item) => {
			if (item?.id) {
				const exist = ratesServices?.services?.find(
					(srv) => srv?.id === item.id,
				);
				if (exist && !exist?.additionalFields?.active) {
					return false;
				}
			}
			return true;
		});
		return uniqBy(
			tabServices.map((item) => ({
				value: item?.id,
				name: item?.name?.[language] || "",
			})),
			"id",
		);
	}, [servicesModel, language, rates, rateId]);

	const onIsServices = useCallback(
		(services: boolean) => {
			if (!disabled) {
				tab.setState((prev) => ({
					...prev,
					modals: { ...prev.modals, services },
				}));
			}
		},
		[disabled, tab],
	);

	const onClickServices = useCallback(() => {
		const isOpen = tab.state.modals.services;
		if (!disabled && !isOpen) onIsServices(true);
		if (isOpen) onIsServices(false);
	}, [disabled, onIsServices, tab.state?.modals?.services]);

	useKeyBindSettings("orderModalServicesModal", (event) => {
		if (!disabled) {
			onClickServices();
		}
		event.preventDefault();
		event.stopPropagation();
	});

	const translateService = useMemo(() => t(["services"]), [t]);

	const onRenderServices = useCallback(
		({ field }: Services.ServicesType) => {
			const value = field.value?.length
				? uniq(field.value.map((item) => item?.name || "")).join(", ")
				: translateService;

			return (
				<>
					<StyledColumn {...styles.wrap} onClick={onClickServices}>
						<Text
							valueStyle={styles.text}
							textStyle={styles.text}
							text={
								<StyledWrapIcon {...styles.icon}>
									<Icon id="order-modal-services" size={18} />
								</StyledWrapIcon>
							}
							value={
								<>
									{value}
									<StyledWrapIcon {...styles.arrow}>
										<Icon
											id="arrow-select-down"
											size={16}
										/>
									</StyledWrapIcon>
								</>
							}
						/>
					</StyledColumn>
					{tab.state.modals.services && (
						<ListSelectMemo
							hiddenButton={false}
							pullUpItemInsideArray
							headerDivider={false}
							title={translateService}
							value={field.value}
							options={serviceModalOptions}
							onSubmit={(value) => {
								field.onChange({
									target: { value },
								} as unknown as React.ChangeEvent<Element>);

								onIsServices(false);
								form.setValue("orderSave", true);
							}}
							onClose={onClickServices}
						/>
					)}
				</>
			);
		},
		[
			form,
			onClickServices,
			onIsServices,
			serviceModalOptions,
			styles.arrow,
			styles.icon,
			styles.text,
			styles.wrap,
			tab.state.modals.services,
			translateService,
		],
	);
	return (
		<Controller
			name="services"
			control={form.control}
			render={onRenderServices}
		/>
	);
};

declare namespace Services {
	interface Props extends OrderFormProps {}

	interface ServicesType {
		field: ControllerRenderProps<Schema, "services">;
	}
}
export const ServicesMemo = memo(Services);
export default Services;
