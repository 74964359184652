/* eslint-disable no-shadow */

import React from "react";
import { useTranslation } from "react-i18next";
import { useTypedDispatch, useTypedSelector } from "../../../../../redux/store";
import { SettingsTabProps } from "../../../../../components/Settings/tabProps";
import useSnapshot from "../../../../../hooks/useSnapshot";
import SettingList from "../../../../../components/Settings/SettingList";
import colors, {
	colorGroups,
	colorMeta,
	ColorMeta,
	ColorState,
} from "../../../../../redux/reducers/settings/colors";
import GlobalSettings from "../../../../../services/GlobalSettings";

const ColorSettings: React.FC<SettingsTabProps> = ({ methods, onHasChanges }) => {
	const { t } = useTranslation();
	const dispatch = useTypedDispatch();

	const user = useTypedSelector((state) => state.account.user);

	const [snapshot, setSnapshot] = useSnapshot({
		state: useTypedSelector((state) => state.settings.colors),
		methods,
		onHasChanges,
		async onSave(snapshot) {
			if (!user) return;

			dispatch(colors.actions.setAll(snapshot));

			const globalSettings = await GlobalSettings.get();

			globalSettings.ui.colors.orders.selected = snapshot.activeOrder;

			globalSettings.ui.colors.orders.paymentType.card = snapshot.card;
			globalSettings.ui.colors.orders.paymentType.cash = snapshot.cash;
			globalSettings.ui.colors.orders.paymentType.invoice =
				snapshot.invoice;
			globalSettings.ui.colors.orders.paymentType.companyBill =
				snapshot.company_bill;

			globalSettings.ui.colors.orders.createdSource.app = snapshot.app;
			globalSettings.ui.colors.orders.createdSource.phone =
				snapshot.phone;
			globalSettings.ui.colors.orders.createdSource.website =
				snapshot.website;
			globalSettings.ui.colors.orders.createdSource.executor =
				snapshot.executor;
			globalSettings.ui.colors.orders.createdSource.dispatcher =
				snapshot.dispatcher;

			globalSettings.ui.colors.orders.palette.color1 = snapshot.color1;
			globalSettings.ui.colors.orders.palette.color2 = snapshot.color2;
			globalSettings.ui.colors.orders.palette.color3 = snapshot.color3;
			globalSettings.ui.colors.orders.palette.color4 = snapshot.color4;
			globalSettings.ui.colors.orders.palette.color5 = snapshot.color5;
			globalSettings.ui.colors.orders.palette.color6 = snapshot.color6;
			globalSettings.ui.colors.orders.palette.color7 = snapshot.color7;
			globalSettings.ui.colors.orders.palette.color8 = snapshot.color8;

			await GlobalSettings.update(globalSettings);

			// await Dispatcher.update({
			// 	id: user.id,
			// 	settings: {
			// 		...user.settings,
			// 		colors: snapshot,
			// 	},
			// });

			// dispatch(Account.me(user.id.toString()));
		},
		onResetDefaults() {
			dispatch(colors.actions.resetDefaults());
			return colors.getInitialState();
		},
	});

	return (
		<SettingList<never, never, ColorState, ColorMeta>
			state={snapshot}
			meta={colorMeta}
			groups={colorGroups}
			name={(key) => t(`settings.interface.colors.options.${key}`)}
			onChange={(newValue, key) => {
				setSnapshot((prev) =>
					prev[key] === newValue
						? prev
						: { ...prev, [key]: newValue },
				);
			}}
		/>
	);
};

export default ColorSettings;
