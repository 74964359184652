import React, { Dispatch, useState } from "react";
import { useTranslation } from "react-i18next";
import { Float, Icon, InputGroup, Row, Select, Tabs, theme } from "uikit";

import MapLanguage from "../../../../../../../../../../types/MapLanguage";
import SearchIconBorders from "../../../../../../../../../../components/SearchIconBorders";
import SearchTextBox from "../../../../../../../../../../components/SearchTextBox";
import CompaniesAndTaxiServicesFilter from "../../../../../../../../../../components/CompaniesAndTaxiServicesFilter";
import Map from "../../../../../../../../../../redux/services/Map";
import Language from "../../../../../../../../../../services/Language";
import Button from "../../../../../components/Button";

import { types } from "./constants";
import InternalPopover from "./components/Popover";

const Filters: React.FC<Filters.Props> = ({
	companies,
	taxiServices,
	language,
	dataType,
	search,
	additionalFilters,

	onChangeCompanies,
	onChangeTaxiServices,
	onChangeLanguage,
	onChangeDataType,
	onChangeSearch,
	onChangeAdditionalFilters,

	onExport,
	onImport,
}) => {
	const [openAdditionalFilters, setOpenAdditionalFilters] = useState(false);

	const { t } = useTranslation();
	return (
		<Row
			sizes="200px!*2 66px! auto 270px! auto*"
			gaps="12px 10px*"
			align="center"
		>
			<CompaniesAndTaxiServicesFilter
				companies={companies}
				taxiServices={taxiServices}
				language={language}
				onChangeCompanies={onChangeCompanies}
				onChangeTaxiServices={onChangeTaxiServices}
			/>
			<Select<MapLanguage>
				options={Language.constants.select.options.shortened}
				value={language}
				onSelect={(option) => onChangeLanguage(option.value)}
			/>
			<Tabs
				variant="monoblock"
				options={types}
				value={dataType}
				onChangeSelectedOption={(option) =>
					onChangeDataType(option.value)
				}
			/>
			<InputGroup.InputGroup sizes="auto 1fr">
				<SearchIconBorders>
					<Icon
						id="search2"
						size={16}
						colors={[theme.colors.disabled_text]}
					/>
				</SearchIconBorders>
				<SearchTextBox
					placeholder={`${
						t(
							"pages.preferencesPages.screenDirectory.objects.header.filters.str200",
						) ?? ""
					}...`}
					value={search}
					onChange={onChangeSearch}
				/>
			</InputGroup.InputGroup>
			<Float.Tracker id="filters">
				<Button
					variant="secondary"
					icon={<Icon id="filters" size={20} />}
					onClick={() =>
						openAdditionalFilters
							? undefined
							: setOpenAdditionalFilters(true)
					}
				/>
			</Float.Tracker>
			<Button
				variant="secondary"
				icon={<Icon id="export-page" size={20} />}
				disabled={dataType === "object-groups"}
				onClick={onExport}
			/>
			<Button
				variant="secondary"
				icon={<Icon id="import-page" size={20} />}
				disabled={dataType === "object-groups"}
				onClick={onImport}
			/>
			<InternalPopover
				value={additionalFilters}
				open={openAdditionalFilters}
				onClose={() => setOpenAdditionalFilters(false)}
				onChange={onChangeAdditionalFilters}
			/>
		</Row>
	);
};

declare namespace Filters {
	interface Props extends CompaniesAndTaxiServicesFilter.Props {
		language: Map.Language;
		dataType: "objects" | "object-groups";
		search: string;
		additionalFilters: InternalPopover.Value;

		onChangeLanguage: Dispatch<Map.Language>;
		onChangeDataType: Dispatch<"objects" | "object-groups">;
		onChangeSearch: Dispatch<string>;
		onChangeAdditionalFilters: Dispatch<InternalPopover.Value>;

		onExport: () => void;
		onImport: () => void;
	}

	namespace Popover {
		type Value = InternalPopover.Value;
	}
}

export default Filters;
