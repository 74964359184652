/* eslint-disable no-param-reassign */
import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import DistanceCalcMethod from "../../../../types/DistanceCalcMethod";
import {
	NumberEntry,
	DataKind,
	EntryList,
	SelectEntry,
} from "../../../../types/settingEntries";

export type ExecutorAppGeneralSelectTypes = DistanceCalcMethod;
export type ExecutorAppGeneralMultiSelectTypes = never;

export interface BaseExecutorAppGeneralEntries<Data extends DataKind> {
	coordinateSyncTime: NumberEntry<Data>;
	defaultOrdersDisplayRadius: NumberEntry<Data>;
	pickupDistanceCalculationIntervalRadius: NumberEntry<Data>;
	pickupDistanceCalculationMinSpeed: NumberEntry<Data>;
	pickupDistanceCalculationType: SelectEntry<Data, DistanceCalcMethod>;
}

export type ExecutorAppGeneralEntries<Data extends DataKind> =
	BaseExecutorAppGeneralEntries<Data> &
		EntryList<
			Data,
			ExecutorAppGeneralSelectTypes,
			ExecutorAppGeneralMultiSelectTypes
		>;
export type ExecutorAppGeneralMeta = ExecutorAppGeneralEntries<"meta">;
export type ExecutorAppGeneralState = ExecutorAppGeneralEntries<"value">;
export type ExecutorAppGeneralValue =
	ExecutorAppGeneralEntries<any>[keyof ExecutorAppGeneralEntries<any>];

export const executorAppGeneralMeta: ExecutorAppGeneralMeta = {
	coordinateSyncTime: {
		type: "number",
		unit: "millisecond",
	},
	defaultOrdersDisplayRadius: {
		type: "number",
		unit: "meter",
	},
	pickupDistanceCalculationIntervalRadius: {
		type: "number",
		unit: "meter",
	},
	pickupDistanceCalculationMinSpeed: {
		type: "number",
		unit: "kph",
	},
	pickupDistanceCalculationType: {
		type: "select",
		options: [
			{
				key: "none",
				label: "distance_calc_methods.none",
				value: false,
			},
			{
				key: "direct",
				label: "distance_calc_methods.direct",
				value: "direct",
			},
			{
				key: "osm",
				label: "distance_calc_methods.osm",
				value: "osm",
			},
		],
	},
};

const initialState: ExecutorAppGeneralState = {
	coordinateSyncTime: 3e3,
	defaultOrdersDisplayRadius: 5000,
	pickupDistanceCalculationIntervalRadius: 500,
	pickupDistanceCalculationMinSpeed: 2,
	pickupDistanceCalculationType: "osm",
};

type Reducer<P> = CaseReducer<ExecutorAppGeneralState, PayloadAction<P>>;

const setAll: Reducer<ExecutorAppGeneralState> = (_, { payload }) => payload;
const resetDefaults: Reducer<void> = () => initialState;

const modify: Reducer<Partial<ExecutorAppGeneralState>> = (
	state,
	{ payload },
) => {
	Object.keys(payload).forEach((k) => {
		state[k] = payload[k];
	});
};

const executorAppGeneral = createSlice({
	name: "executorAppGeneral",
	initialState,
	reducers: {
		setAll,
		resetDefaults,
		modify,
	},
});

export default executorAppGeneral;
