import React, {
	memo,
	useCallback,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { cloneDeep } from "lodash";

import CustomersBlacklist from "../../../../../../services/CustomersBlacklist";
import useModelSubscribe2 from "../../../../../../hooks/useModelSubscribe2";
import mapByKey from "../../../../../../utils/mapByKey";
import { ModelId } from "../../../../../../types/ModelId";
import { useTableOptions } from "../../../../../../components/LightTable";
import DefaultPageHeader from "../../../../../../components/DefaultPageHeader";
import DeleteModal from "../../../../../../components/DeleteModal";

import defaultValue from "./constants/defaultValue";
import Root from "./components/Root";
import Modal from "./components/Modal";
import Content from "./components/Content";
import tPath from "./constants/tPath";
import Filters from "./components/Filters";

const Customers = memo(() => {
	const { t } = useTranslation();

	const {
		setDataLength,
		dataLength,
		limit,
		sort,
		editor,
		onChange,
		query,
		setQuery,
	} = useTableOptions();

	const optionsCustomersBlacklist = useMemo(() => {
		const order = {};
		const payload: CustomersBlacklist.SubscribeOptions = {
			limit,
			order,
			query,
		};

		const sortList = ["phone", "surname", "name", "fatherName"];

		if (sort.dataKey && sortList.includes(sort.dataKey)) {
			order[sort.dataKey] = sort.sortType;
		}

		return payload;
	}, [limit, query, sort.dataKey, sort.sortType]);

	const { models: data } = useModelSubscribe2(
		optionsCustomersBlacklist,
		CustomersBlacklist,
	);

	const [showModal, setShowModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const [selected, setSelected] = useState<ModelId[]>([]);

	const [editingItem, setEditingItem] = useState<Modal.Value>(defaultValue);

	const modelItemById = useMemo(() => mapByKey(data, "id"), [data]);

	useLayoutEffect(() => {
		if (dataLength !== data.length) {
			setDataLength(data.length);
		}
	}, [data.length, dataLength, setDataLength]);

	const edit = useCallback(
		(id: ModelId) => {
			const item = modelItemById[id];
			setEditingItem(cloneDeep(item));

			setShowModal(true);
			setSelected([]);
		},
		[modelItemById],
	);

	const editHeaderHandler = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const editContentHandler = useCallback(
		(item: CustomersBlacklist.Modified) => edit(item.id),
		[edit],
	);

	const addHandler = useCallback(() => {
		setShowModal(true);
	}, []);

	const preDeleteHandler = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const deleteHandler = useCallback(async () => {
		await CustomersBlacklist.delete(selected);
		setSelected([]);
		setShowDeleteModal(false);
	}, [selected]);

	const cancelHandler = useCallback(() => {
		setShowModal(false);
		setEditingItem(defaultValue);
	}, []);

	const cancelDeleteHandler = useCallback(() => {
		setShowDeleteModal(false);
		setSelected([]);
	}, []);

	const saveHandler = useCallback(
		async (item: CustomersBlacklist.New | CustomersBlacklist.Modified) => {
			let isAllOk = true;
			if ("id" in item) {
				isAllOk = await CustomersBlacklist.update(item);
			} else {
				isAllOk = await CustomersBlacklist.store(item);
			}

			if (isAllOk) {
				setShowModal(false);
				setEditingItem(defaultValue);
			}
		},
		[],
	);

	const deleteModalLabel = useMemo(
		() =>
			selected.length === 1
				? t(`${tPath}.deleteModal.title`) || ""
				: t(`${tPath}.deleteModal.title2`) || "",
		[selected.length, t],
	);

	return (
		<Root sizes="auto! 1fr" gaps="16px" maxedWidth maxedHeight>
			<DefaultPageHeader
				canEdit={selected.length === 1}
				canDelete={!!selected.length}
				onAdd={addHandler}
				onDelete={preDeleteHandler}
				onEdit={editHeaderHandler}
				filters={<Filters value={query} onChange={setQuery} />}
				titleAdd={t(`${tPath}.table.buttons.add`) || ""}
				titleEdit={t(`${tPath}.table.buttons.edit`) || ""}
				titleDelete={t(`${tPath}.table.buttons.delete`) || ""}
			/>
			<Content
				value={data}
				selected={selected}
				setSelected={setSelected}
				onEdit={editContentHandler}
				editorTable={editor}
				onChangeTable={onChange}
			/>
			{showModal && (
				<Modal
					headerTitle={
						editingItem.id
							? t(`${tPath}.modal.title.edit`)
							: t(`${tPath}.modal.title.add`)
					}
					onCancel={cancelHandler}
					onSave={saveHandler}
					value={editingItem}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					label={deleteModalLabel}
					onCancel={cancelDeleteHandler}
					onConfirm={deleteHandler}
				/>
			)}
		</Root>
	);
});

export default Customers;
