import * as ModelEvent from "@node-elion/syncron";

import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Subscription from "../../types/Subscription";
import SubscriptionPool from "../../redux/services/SubscriptionPool";
import createLogger from "../../utils/logger.util";
import { Language, Role, Base, Dispatcher as Dispatcher1 } from "..";

const logger = createLogger({ name: "Dispatcher" });

class Dispatcher extends Base {
	static fromResponse(data: any): Dispatcher.Model {
		if (!data) {
			throw new Error("Dispatcher: Data is undefined or null");
		}
		const response = data;

		return response;
	}

	public static async subscribe(
		options: Dispatcher.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<Dispatcher.Model>,
	): Promise<Subscription<Dispatcher.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				if (!state.models) {
					logger.error("State models are undefined");
					return;
				}

				const models = state.models.map(this.fromResponse);
				onUpdate({
					...state,
					models,
				});
			},
		});

		// console.group("dispatcher modelSubscriptionOptions");

		// console.log("result:");
		// console.table(result);

		// console.groupEnd();

		try {
			const subscription = await SubscriptionPool.add(
				(prpc) => {
					const params = this.optionsToRequest(options);
					// console.group("dispatcher modelSubscriptionOptions");

					// console.log("result:");
					// console.table(params);

					// console.groupEnd();
					return prpc.theirsModel.dispatcher.subscribe({
						params,
						// params: {
						// 	order: { createdAt: "DESC" },
						// },
						ping: () => true,
						onEvent: (events) => {
							modelEventConstructor.onEvent(events);
						},
						onError: (error) => {
							logger.error(
								"Subscribe to dispatcher error: %o",
								error,
							);
						},
					});
				},
				{ name: "Dispatcher.subscribe" },
			);

			return {
				unsubscribe: () => subscription.unsubscribe(),
				update: (options: Dispatcher.SubscribeOptions) =>
					subscription.update(this.optionsToRequest(options)),
			} as Subscription<Dispatcher.SubscribeOptions>;
		} catch (error) {
			logger.error("Error dispatcher during subscription: %o", error);
			throw error;
		}
	}

	private static optionsToRequest(options: Dispatcher.SubscribeOptions) {
		return options;
		// return {
		// 	offset: options.offset,
		// 	limit: options.limit,
		// 	// query: options.query,
		// 	// dispatcher: options.dispatcher,
		// };
	}
}

declare namespace Dispatcher {
	interface SubscribeOptions
		extends ServiceSubscribeOptionsBase<Dispatcher.Model> {
		executorId?: number;
		dispatcherId?: number;
		limit?: number;
		offset?: number;
		lang: string;
	}

	interface Model extends Dispatcher1.Model {}

	export interface RoleElement {
		id: number;
		role: Role.Model;
		createdAt: string | null;
		updatedAt: string | null;
		deletedAt: string | null;
	}

	type Status = "active" | "blocked" | "dismissed";

	interface Settings extends Record<string, any> {
		language?: Language;
		fontName?: string;
		fontSize?: number;
	}

	interface AdditionalFields {
		passport?: Passport;
		taxId?: string;
		phones?: string[];
	}

	interface Passport {
		series?: string;
		number?: string;
		issuedBy?: string;
		issuedAt?: Date | string | null;
		residenceAddress?: string;
		livingAddress?: string;
	}
}

export default Dispatcher;
