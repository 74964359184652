import React from "react";
import { Column, ColumnId } from ".";
import OrderTimeCellContent from "../components/OrderTimeCellContent";
import base from "./base";

function orderTime({ width, onResize }: Column.Options) {
	return base(
		ColumnId.OrderTime,
		(item) => <OrderTimeCellContent item={item} />,
		{
			width,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default orderTime;
