import React, { memo } from "react";
import Sectors from "../../../../../Modal/components/Content/components/Sectors";
import Sector from "../../../../../../../../../../../../../../../../../../../../services/Sector";
import SectorItems from "./components/SectorItems";
import { ModelId } from "../../../../../../../../../../../../../../../../../../../../types/ModelId";
import OrdersCounterOutput from "./components/OrdersCounterOutput";
import ExecutorsCounterOutput from "./components/ExecutorsCounterOutput";
import { AutoRateRulePolygonType } from "../../../../../../../../../../../../../../../../../../../../services/AutoTariff/enums";

const SectorsOutput: React.FC<SectorsOutput.Props> = memo(
	({ polygonType, value, sectors, sectorIds }) =>
		polygonType === AutoRateRulePolygonType.SECTOR ? (
			<>
				<SectorItems value={sectorIds} sectors={sectors} />
				<OrdersCounterOutput value={value.ordersCounter} />
				<ExecutorsCounterOutput value={value.executorsCounter} />
			</>
		) : null,
);

declare namespace SectorsOutput {
	interface Props {
		polygonType: AutoRateRulePolygonType;
		value: Sectors.Value;
		sectorIds: ModelId[];
		sectors?: Sector.Model[];
	}
}

export default SectorsOutput;
