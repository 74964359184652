import React, { useEffect, useCallback, memo, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Select, Key } from "uikit";

import Dispatcher from "../../../../../../../../services/Dispatcher";
import { languageAction } from "../../../../../../../../redux/reducers/language";
import { sessionAction } from "../../../../../../../../redux/reducers/session";
import { accountAction } from "../../../../../../../../redux/reducers/account";
import { useTypedSelector } from "../../../../../../../../redux/store";
import {
	Language,
	languageOptions,
} from "../../../../../../../../assets/languages/langs";
import { StyledSpan } from "../../../../../../../common";

const StyledSpanB = styled(StyledSpan)`
	color: rgba(0, 0, 0, 0.87);
	margin: 0;
	justify-content: center;
	text-align: center;
	font-weight: 700;
	font-size: 1.1rem;
	line-height: 1.3;
`;

const PopupContent: React.FC<LanguageSwitch.Props> = ({
	label = true,
	onClose,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [open, setOpen] = useState<boolean>(false);

	const language = useTypedSelector((state) => state.session.language);
	const user = useTypedSelector((state) => state.account?.user);

	const selectOptions = useMemo<LanguageSwitch.LanguageSelects>(
		() => languageOptions,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[languageOptions],
	);

	const onChangeSelect = useCallback(
		(value: LanguageSwitch.LanguageSelect) => {
			dispatch(sessionAction.setLanguage(value.value));
			dispatch(languageAction.setLang(value.value));
			if (value?.value && user?.id) {
				Dispatcher.updateSettings(user.id, {
					...user.settings,
					language: value.value,
				});
				dispatch(accountAction.setLanguage(value.value));
			}

			setOpen(false);
			onClose?.();
		},
		[dispatch, onClose, user],
	);

	useEffect(() => {
		if (!language) onChangeSelect(selectOptions[0]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [language, onChangeSelect, onClose, selectOptions]);

	return (
		<>
			{label && (
				<StyledSpanB>{t("main_header.language") || ""}</StyledSpanB>
			)}
			<Select
				open={open}
				onEnter={() => {
					setOpen(true);
				}}
				value={language}
				placeholder={t("main_header.language") || ""}
				options={languageOptions}
				onSelect={onChangeSelect}
				style={{ width: "130px" }}
			/>
		</>
	);
};

declare namespace LanguageSwitch {
	interface Props {
		label?: boolean;
		onClose?: () => void;
	}

	interface LanguageSelect {
		key: Key;
		label: string;
		value: Language;
	}

	type LanguageSelects = LanguageSelect[];
}
export const LanguageSwitchMemo = memo(PopupContent);
export default PopupContent;
