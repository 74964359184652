import React, { Dispatch, RefAttributes, memo, useCallback } from "react";
import {
	CheckBox,
	Column,
	Row,
	Select,
	Stepper,
	TextBox,
	react,
	useRefWithSetter,
} from "uikit";
import { FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Language from "../../../../../../../../../services/Language";
import useObjectEditor from "../../../../../../../../../hooks/useObjectEditor";
import Root from "./components/Root";
import Name from "./components/Name";
import CompaniesAndTaxiServices from "./components/CompaniesAndTaxiServices";
import TemplateBody from "./components/TemplateBody";
import { languageOptions } from "../../../../../../../../../assets/languages/langs";
import translationPath from "../../../../constants/translationPath";
import LabeledField from "../../../../../../../../../components/LabeledField";
import { destinationAppOptions } from "./constants/destinationAppOptions";
import { NoticeDestinationApp } from "../../../../../../../../../services/PushNotice";
import {
	MessageTemplateActions,
	MessageTemplateTypes,
} from "../../../../../../../../../types/MessageTemplateEnums";
import InternalController from "./Controller";
import ButtonSettings from "./components/ButtonSettings";
import FileUpload from "../../../../../../../../../components/FileUpload";
import File from "../../../../../../../../../services/File";

const ContentBase = react.withController<Content.PropsBase, Content.Controller>(
	({ value, onChange, controller }) => {
		const [nameRef, setNameRef] = useRefWithSetter<Name.Ref | null>(null);
		const [companyBranchRef, setCompanyBranchRef] =
			useRefWithSetter<CompaniesAndTaxiServices.Ref | null>(null);
		controller.setContext({
			nameRef,
			companyBranchRef,
		});

		const { t } = useTranslation();

		destinationAppOptions.forEach((option) => {
			// eslint-disable-next-line no-param-reassign
			option.label = t(
				`${translationPath}.modal.app.options.${option.value}`,
			);
		});

		const valueEditor = useObjectEditor(value, onChange);

		const isDefault = valueEditor.get("isDefault");

		const name = valueEditor.useGetter("name");
		const setName = valueEditor.useSetter("name");

		const templateText = valueEditor.useGetter("templateText");
		const setTemplateText = valueEditor.useSetter("templateText");

		const type = valueEditor.get("type");

		const title = valueEditor.useGetter("title");
		const setTitle = valueEditor.useSetter("title");

		const lang = valueEditor.useGetter("lang");
		const setLang = valueEditor.useSetter("lang");

		const destinationApp = valueEditor.useGetter("destinationApp");
		const setDestinationApp = valueEditor.useSetter("destinationApp");

		const isValidityPeriod = valueEditor.useGetter("isValidityPeriod");
		const setIsValidityPeriod = valueEditor.useSetter("isValidityPeriod");

		const validityPeriod = valueEditor.useGetter("validityPeriod");
		const setValidityPeriod = valueEditor.useSetter("validityPeriod");

		const additionalFields = valueEditor.useGetter("additionalFields");
		const setAdditionalFields = valueEditor.useSetter("additionalFields");

		const bigPicture = valueEditor.useGetter("bigPicture");
		const setBigPicture = valueEditor.useSetter("bigPicture");

		const largeIcon = valueEditor.useGetter("largeIcon");
		const setLargeIcon = valueEditor.useSetter("largeIcon");

		const taxiServicesData = valueEditor.usePicker(
			["taxiServiceIds", "taxiServices"],
			"json",
		);

		const updateAllData = valueEditor.useAssigner();

		const handleLangChange = useCallback(
			(newLang) => {
				setLang(newLang);
			},
			[setLang],
		);
		const handleDestinationAppChange = useCallback(
			(newDestApp) => {
				setDestinationApp(newDestApp);
			},
			[setDestinationApp],
		);
		const handleValidityPeriodChange = useCallback(
			(newValidityPeriod) => {
				setValidityPeriod(newValidityPeriod);
			},
			[setValidityPeriod],
		);

		const updeBigPictureHandler = useCallback(
			(file: File.Model | null) => {
				if (!file) {
					setBigPicture(null);
					return;
				}
				// eslint-disable-next-line no-param-reassign
				file.isPublicImage = true;
				file.upload();
				setBigPicture(file);
			},
			[setBigPicture],
		);

		const updeLargeIconHandler = useCallback(
			(file: File.Model | null) => {
				if (!file) {
					setLargeIcon(null);
					return;
				}
				// eslint-disable-next-line no-param-reassign
				file.isPublicImage = true;
				file.upload();
				setLargeIcon(file);
			},
			[setLargeIcon],
		);

		return (
			<Root hasPaddings>
				<Column>
					<Column gaps="25px*" maxedWidth maxedHeight>
						<Row sizes="40% 30% 30%" gaps="16px*">
							<Name
								ref={setNameRef}
								name={name}
								setName={setName}
								isDefault={isDefault}
							/>
							<CompaniesAndTaxiServices
								ref={setCompanyBranchRef}
								value={taxiServicesData}
								onChange={updateAllData}
							/>
						</Row>
						<Row align="center" gaps="16px*" sizes="40% 30% 30%">
							<LabeledField
								label={
									t(
										`${translationPath}.modal.noticeTitle.title`,
									) || ""
								}
							>
								<TextBox.TextBox
									placeholder={
										t(
											`${translationPath}.modal.noticeTitle.placeholder`,
										) || ""
									}
									value={title}
									onChange={setTitle}
								/>
							</LabeledField>
							<LabeledField
								label={`${t(`${translationPath}.modal.lang`)}:`}
							>
								<Select
									options={languageOptions}
									value={lang}
									onChange={handleLangChange}
								/>
							</LabeledField>
							<LabeledField
								label={`${t(
									`${translationPath}.modal.app.title`,
								)}:`}
							>
								<Select
									disabled={isDefault}
									placeholder={
										t(
											`${translationPath}.modal.app.placeholder`,
										) || ""
									}
									options={destinationAppOptions}
									value={destinationApp}
									onChange={handleDestinationAppChange}
								/>
							</LabeledField>
						</Row>
						<TemplateBody
							value={templateText}
							onChange={setTemplateText}
							type={type}
							destinationApp={destinationApp}
						/>
						<Row gaps="20px*" justify="space-between">
							<Column gaps="10px*">
								<Row align="center" gaps="10px*">
									<CheckBox
										label={
											t(
												`${translationPath}.modal.isValidityPeriod.main`,
											) || ""
										}
										value={isValidityPeriod}
										onChange={setIsValidityPeriod}
									/>
									<Stepper
										width="80px"
										value={validityPeriod}
										onChange={handleValidityPeriodChange}
									/>
									<span>
										{t(
											`${translationPath}.modal.isValidityPeriod.time`,
										)}
									</span>
								</Row>
								{additionalFields && (
									<ButtonSettings
										additionalFields={additionalFields}
										setAdditionalFields={
											setAdditionalFields
										}
									/>
								)}
							</Column>
							<Row
								gaps="30px*"
								sizes="1fr 1fr"
								style={{ width: "40%" }}
								align="self-start"
							>
								<FileUpload
									value={bigPicture}
									label={
										t(
											`${translationPath}.modal.bigPictureId.title`,
										) || ""
									}
									onChange={updeBigPictureHandler}
								/>
								<FileUpload
									value={largeIcon}
									label={
										t(
											`${translationPath}.modal.largeIconId.title`,
										) || ""
									}
									onChange={updeLargeIconHandler}
								/>
							</Row>
						</Row>
					</Column>
				</Column>
			</Root>
		);
	},
	InternalController,
);
const Content = memo(ContentBase);

declare namespace Content {
	type Ref = InternalController | null;
	type Controller = InternalController;
	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;
		errors?: FieldErrors<any>;
	}
	type Props = PropsBase & RefAttributes<Ref>;
	interface Value extends CompaniesAndTaxiServices.Value {
		name: string;
		title: string;
		templateText: TemplateBody.Value;
		isValidityPeriod: boolean;
		validityPeriod: number;
		lang: Language;
		destinationApp: NoticeDestinationApp;
		additionalFields?: ButtonSettings.AdditionalFields;
		bigPicture: File.Model | null;
		largeIcon: File.Model | null;
		readonly action: MessageTemplateActions;
		readonly type: MessageTemplateTypes;
		readonly isDefault: boolean;
	}

	interface ExecutorAppActions {
		active: boolean;
		type: ExecutorAppActionsTypes;
		text: string;
		value?: number;
	}
}
// eslint-disable-next-line no-shadow
export enum ExecutorAppActionsTypes {
	YES_MIN = "yes_min",
	YES = "yes",
	NO = "no",
}

export default Content;
