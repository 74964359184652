import React, { useCallback, useMemo, useState } from "react";
import { Icon } from "uikit";
import { cloneDeep, isUndefined } from "lodash";
import { useTranslation } from "react-i18next";
import Order from "../../../../../../../../../../services/Order";
import { useTypedSelector } from "../../../../../../../../../../redux/store";
import Popup from "../../../../../../../../../../components/common/Popup";
import { useKeyBindSettings } from "../../../../../../../../../../hooks/useKeyBindSettings";
import Button from "../Button";
import PopupContent from "./components/PopupContent";

const popupOffset: Popup.Props["offset"] = { x: 0, y: 37 };

const ColorButton: React.FC<ColorButton.Props> = () => {
	const { t } = useTranslation();

	const [isPopupOpen, setIsPopupOpen] = useState(false);

	const settingColors = useTypedSelector((state) => state.settings.colors);

	const colorOptions = useMemo<PopupContent.Option[]>(
		() =>
			Array(8)
				.fill(null)
				.map((_, index) => {
					const key = `color${index + 1}`;

					return {
						key,
						color: settingColors[key] as string,
						name: t(`settings.interface.colors.options.${key}`),
					};
				}),
		[settingColors, t],
	);

	const activeOrder = useTypedSelector(
		(state) => state.ordersPageReducer.activeOrder,
	);

	const disabled = useMemo(() => !activeOrder?.id, [activeOrder]);

	const onChangeColor = useCallback(
		(value: PopupContent.Value, closePopup = true) => {
			if (isUndefined(activeOrder.id)) return;

			const additionalFields = cloneDeep(activeOrder.additionalFields);

			additionalFields.displaySettings =
				additionalFields.displaySettings ?? {};

			additionalFields.displaySettings.color =
				additionalFields.displaySettings.color === value
					? "none"
					: value;

			Order.update({ id: activeOrder.id, additionalFields });

			if (closePopup) setIsPopupOpen(false);
		},
		[activeOrder.additionalFields, activeOrder.id],
	);

	const changeColorKeybindCallback = useCallback(
		(key: string) => (event: KeyboardEvent) => {
			onChangeColor(key, false);

			event.preventDefault();
			event.stopPropagation();
		},
		[onChangeColor],
	);

	useKeyBindSettings("color1", changeColorKeybindCallback("color1"));
	useKeyBindSettings("color2", changeColorKeybindCallback("color2"));
	useKeyBindSettings("color3", changeColorKeybindCallback("color3"));
	useKeyBindSettings("color4", changeColorKeybindCallback("color4"));
	useKeyBindSettings("color5", changeColorKeybindCallback("color5"));
	useKeyBindSettings("color6", changeColorKeybindCallback("color6"));
	useKeyBindSettings("color7", changeColorKeybindCallback("color7"));
	useKeyBindSettings("color8", changeColorKeybindCallback("color8"));

	return (
		<Popup
			open={isPopupOpen}
			useClickControl={!disabled}
			tracker={
				<Button
					disabled={disabled}
					active={isPopupOpen}
					variant="secondary"
					title={
						t(
							"pages.mainPage.pages.orders.orderHeader.secondRow.colorButton.str0",
						) ?? ""
					}
					tabIndex={-1}
				>
					<Icon id="orders-color-fill" size={20} />
				</Button>
			}
			containerId="page-content"
			trackerId="order-page-header-sms-button"
			offset={popupOffset}
			onChangeOpen={setIsPopupOpen}
		>
			<PopupContent
				value={activeOrder.additionalFields?.displaySettings?.color}
				options={colorOptions}
				onChange={onChangeColor}
			/>
		</Popup>
	);
};

declare namespace ColorButton {
	interface Props {}
}

export default ColorButton;
