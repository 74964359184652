import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Tabs } from "uikit";

import { useTypedSelector } from "../../../../redux/store";
import useActiveTab from "../../../../hooks/useActiveTab";
import { hasAccess, generateAccessName, AccessKey } from "../../../../access";
import { TabOptions } from "../../../../types";
import BasicPageLayout from "../../../../components/BasicPageLayout";

import { /*  System, */ Programs } from "./tabs";

const Parameters: React.FC = () => {
	const [ACCESS_SECTION, ACCESS_SECTION_2] = [
		AccessKey.SETTINGS,
		AccessKey.SETTINGS_PARAMETERS,
	];
	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const tabs: TabOptions.Array = useMemo(
		() =>
			[
				// {
				// 	disabled: true,
				// 	key: "system",
				// 	label: t("settings.parameters.tabs.system.title"),
				// 	value: {
				// 		render() {
				// 			return <System />;
				// 		},
				// 	},
				// accessName: generateAccessName(
				// 	ACCESS_SECTION,
				// 	ACCESS_SECTION_2,
				// 	AccessKey.SYSTEM,
				// ),
				// },
				{
					key: "application",
					label: t("settings.parameters.tabs.programs.title"),
					value: {
						render() {
							return <Programs />;
						},
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						ACCESS_SECTION_2,
						AccessKey.APPLICATION,
					),
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, ACCESS_SECTION, ACCESS_SECTION_2, personalRole],
	);

	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	return (
		<BasicPageLayout
			navigation={
				<Tabs
					value={activeKey}
					onChange={setActiveKey}
					options={tabs}
					variant="vertical"
				/>
			}
			content={activeTab.value.render()}
		/>
	);
};

export default Parameters;
