import { MutableRefObject, useEffect } from "react";

import { useTypedDispatch, useTypedSelector } from "../redux/store";
import GlobalSettings from "../services/GlobalSettings";
import colors from "../redux/reducers/settings/colors";
import loading from "../redux/reducers/loading";

import usePRPC from "./usePRPC";

function useGlobalSettings() {
	const dispatch = useTypedDispatch();
	const { connected, authorized } = useTypedSelector((state) => state.prpc);
	const prpc = usePRPC();

	useEffect(() => {
		const subscriptionRef: MutableRefObject<GlobalSettings.Subscription | null> =
			{
				current: null,
			};

		(async () => {
			if (connected && authorized && prpc?.theirsModel?.settings) {
				console.log(
					"[GlobalSettings] subscribe",
					prpc?.theirsModel?.settings,
				);

				subscriptionRef.current = await GlobalSettings.subscribe(
					(globalSettings) => {
						if (!globalSettings) {
							console.log(
								"[GlobalSettings] not found!",
								globalSettings,
							);
							return;
						}
						try {
							dispatch(
								colors.actions.setAll({
									activeOrder:
										globalSettings.ui.colors.orders
											.selected,

									card: globalSettings.ui.colors.orders
										.paymentType.card,
									cash: globalSettings.ui.colors.orders
										.paymentType.cash,
									invoice:
										globalSettings.ui.colors.orders
											.paymentType.invoice,
									companyBill:
										globalSettings.ui.colors.orders
											.paymentType.companyBill,

									app: globalSettings.ui.colors.orders
										.createdSource.app,
									phone: globalSettings.ui.colors.orders
										.createdSource.phone,
									website:
										globalSettings.ui.colors.orders
											.createdSource.website,
									executor:
										globalSettings.ui.colors.orders
											.createdSource.executor,
									dispatcher:
										globalSettings.ui.colors.orders
											.createdSource.dispatcher,

									color1: globalSettings.ui.colors.orders
										.palette.color1,
									color2: globalSettings.ui.colors.orders
										.palette.color2,
									color3: globalSettings.ui.colors.orders
										.palette.color3,
									color4: globalSettings.ui.colors.orders
										.palette.color4,
									color5: globalSettings.ui.colors.orders
										.palette.color5,
									color6: globalSettings.ui.colors.orders
										.palette.color6,
									color7: globalSettings.ui.colors.orders
										.palette.color7,
									color8: globalSettings.ui.colors.orders
										.palette.color8,
								}),
							);

							dispatch(
								loading.actions.markLoaded("globalSettings"),
							);
						} catch (error) {
							console.error(error);
						}
					},
				);
			}
		})();

		return () => {
			if (connected) subscriptionRef.current?.unsubscribe();
		};
	}, [authorized, connected, dispatch, prpc?.theirsModel?.settings]);
}

export default useGlobalSettings;
