import { DisplayFields, AccessKey } from ".";

const mergeAccessRolesUser = (dataObjects: DisplayFields[]): DisplayFields => {
	const result: DisplayFields = {};

	dataObjects.forEach((data) => {
		Object.keys(data).forEach((key) => {
			if (!result[key]) {
				result[key] = JSON.parse(JSON.stringify(data[key]));
			} else {
				result[key][AccessKey.SHOW] =
					result[key][AccessKey.SHOW] || data[key][AccessKey.SHOW];
			}

			if (typeof data[key] === "object" && !Array.isArray(data[key])) {
				Object.keys(data[key]).forEach((subKey) => {
					if (subKey !== AccessKey.SHOW) {
						if (!result[key][subKey]) {
							result[key][subKey] = JSON.parse(
								JSON.stringify(data[key][subKey]),
							);
						} else {
							result[key][subKey][AccessKey.SHOW] =
								result[key][subKey][AccessKey.SHOW] ||
								data[key][subKey][AccessKey.SHOW];
						}
					}
				});
			}
		});
	});

	return result;
};

export default mergeAccessRolesUser;
