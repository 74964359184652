import React, { Dispatch, useLayoutEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent, Row } from "uikit";

import Service from "../../../../../../../../../../../../../../services/Service";
import Language from "../../../../../../../../../../../../../../services/Language";
import CarClass from "../../../../../../../../../../../../../../services/CarClass";
import useModelSubscribe from "../../../../../../../../../../../../../../hooks/useModelSubscribe2";
import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";
import FieldsContainer from "../../../../../../../../../../../../../../components/FieldsContainer";

const ServiceIds: React.FC<ServiceIds.Props> = ({
	value,
	disabled,
	carClass,
	language,
	onChange,
	taxiServiceId,
}) => {
	const { t } = useTranslation();

	const [serviceAvailableIds, setServiceAvailableIds] = useState<number[]>(
		[],
	);

	const serviceData = useModelSubscribe(
		{
			availableForCar: true,
			taxiServiceIds: taxiServiceId ? [taxiServiceId] : [],
		},
		Service,
	);

	const services = useMemo(
		() =>
			serviceData?.models?.filter((item) =>
				serviceAvailableIds.includes(item.id),
			) ?? [],
		[serviceAvailableIds, serviceData?.models],
	);

	useLayoutEffect(() => {
		setServiceAvailableIds(carClass?.serviceAvailableIds || []);
	}, [carClass?.serviceAvailableIds]);

	const valueEditor = useObjectEditor(value, onChange);

	return (
		<FieldsContainer
			label={
				t(
					"pages.mainPage.pages.accounts.tabs.car.editModal.content.optionTab.serviceIds.str0",
				) ?? ""
			}
		>
			<Row wrap="nowrap" sizes="160px*" gaps="12px*">
				{services.map((service) => (
					<CheckBoxWithContent
						key={service.id}
						value={valueEditor.includes(service.id)}
						gap="10px"
						disabled={disabled}
						onChange={(serviceEnable) => {
							if (serviceEnable) valueEditor.pushUniq(service.id);
							else valueEditor.removeByValue(service.id);
						}}
					>
						{service.name[language]}
					</CheckBoxWithContent>
				))}
			</Row>
		</FieldsContainer>
	);
};

declare namespace ServiceIds {
	type Value = number[];

	interface Props {
		value: Value;
		onChange: Dispatch<Value>;

		taxiServiceId?: number;
		carClass?: CarClass.Model;

		disabled: boolean;
		language: Language;
	}
}

export default ServiceIds;
