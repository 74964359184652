/* eslint-disable no-param-reassign */
import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { DataKind, EntryList } from "../../../types/settingEntries";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface BaseClientAppEntries<Data extends DataKind> {}

export type ClientAppEntries<Data extends DataKind> =
	BaseClientAppEntries<Data> & EntryList<Data, never, never>;
export type ClientAppMeta = ClientAppEntries<"meta">;
export type ClientAppState = ClientAppEntries<"value">;
export type ClientAppValue = ClientAppEntries<any>[keyof ClientAppEntries<any>];

export const clientAppMeta: ClientAppMeta = {};

const initialState: ClientAppState = {};

type Reducer<P> = CaseReducer<ClientAppState, PayloadAction<P>>;

const setAll: Reducer<ClientAppState> = (_, { payload }) => payload;
const resetDefaults: Reducer<void> = () => initialState;

const clientApp = createSlice({
	name: "clientApp",
	initialState,
	reducers: {
		setAll,
		resetDefaults,
	},
});

export default clientApp;
