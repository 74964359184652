import React, { memo, useCallback, useMemo } from "react";

import { useTypedSelector } from "../../../../../../../../redux/store";
import { useKeyBindSettings } from "../../../../../../../../hooks/useKeyBindSettings";
import { OrderFormProps } from "../../types/FormProps";
import { GridSelectMemo } from "../GridSelect";

const GridItemTariff: React.FC<GridItemTariff.Props> = ({
	area,
	form,
	disabled,
	tab,
}) => {
	const language = useTypedSelector((state) => state.session.language);
	const { rates } = useTypedSelector(
		(state) => state.preferencesReducer.rates,
	);

	const companyID = useMemo(() => tab.form.companyID, [tab.form.companyID]);
	const taxiServiceId = useMemo(
		() => tab.form.taxiServiceId,
		[tab.form.taxiServiceId],
	);
	const watchedCarClassIds = useMemo(
		() => tab?.form?.taxiClass,
		[tab?.form?.taxiClass],
	);
	const rateId = useMemo(() => tab?.form?.rateId, [tab?.form?.rateId]);

	const ratesOptions = useMemo(
		() =>
			rates
				.filter((rate) => {
					const taxi = rate.taxiServiceIds.includes(taxiServiceId);
					const company = rate.taxiServices.find((service) => {
						if (service.company?.id === companyID) return true;
						return false;
					});

					if (taxi && company) return true;
					return false;
				})
				.filter((rate) =>
					watchedCarClassIds
						?.map((item) => rate.carClassIds.includes(item.value))
						.every((item) => item),
				)
				.map((rate) => ({
					id: rate.id,
					name: rate.name[language],
				})),
		[companyID, language, rates, taxiServiceId, watchedCarClassIds],
	);

	const handleSave = useCallback(() => {
		form.setValue("orderSave", true);
		form.setValue("orderPointSave", false);
	}, [form]);

	const onRateOptionSelect = useCallback(
		(option) => {
			const rate =
				rates.find((item) => item.id === option?.value) || rates?.at(0);

			if (!rate) return;
			if (watchedCarClassIds?.length) {
				const carClassOptions = watchedCarClassIds.filter((carClass) =>
					rate.carClassIds.includes(carClass.value),
				);

				if (carClassOptions.length) {
					form.setValue("rateId", option.value);
					form.setValue("taxiClass", carClassOptions);
					handleSave();
				} else if (rate.carClassIds?.length) {
					form.setValue("rateId", option.value);
					handleSave();
				}
			}
		},
		[form, handleSave, rates, watchedCarClassIds],
	);

	const styles = useMemo(
		() => ({
			wrap: { h: "28px", alignItems: "center" },
		}),
		[],
	);

	useKeyBindSettings("orderModalSelectPayment", (event) => {
		event.preventDefault();
		event.stopPropagation();
		if (!rateId) return;
		const selectValue: number[] = ratesOptions.map((item) => item.id);
		const getIndex = selectValue.indexOf(rateId);
		const rate = ratesOptions.at(getIndex + 1) || ratesOptions.at(0);
		onRateOptionSelect({ value: rate.id });
	});

	return (
		<GridSelectMemo
			area={area || "tariff"}
			disabled={disabled}
			name="rateId"
			control={form.control}
			options={ratesOptions}
			onSelect={onRateOptionSelect}
			styles={styles}
		/>
	);
};

declare namespace GridItemTariff {
	interface Props extends OrderFormProps {
		area?: string;
	}
}
export const GridItemTariffMemo = memo(GridItemTariff);
export default GridItemTariff;
