import React, {
	PropsWithChildren,
	memo,
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import Draggable from "react-draggable";
import { uniq, isBoolean } from "lodash";
import { CheckBox, Icon, InputGroup, theme } from "uikit";

import sortByNumberOrString from "../../../../utils/sortByNumberOrString";
import useKeyBind from "../../../../hooks/useKeyBind";
import Modal from "../../../Modal";
import SearchIconBorders from "../../../SearchIconBorders";
import SearchTextBox from "../../../SearchTextBox";
import KeyBind from "../../../KeyBind";
import { HeaderMemo, FooterMemo, Footer } from "../../../common/Modal";
import {
	StyledColumn,
	StyledRow,
	StyledSpan,
	StyledP,
	Divider,
	CheckBoxWithText,
} from "../../../common";

const Group = styled(InputGroup.InputGroup)`
	width: 100%;
`;

const Chips = styled(StyledP)`
	justify-content: center;
	align-items: center;
	gap: 10px;
	height: 24px;
	padding: 4px 8px;
	border-radius: 4px;
	background: #f5f5f5;
	font-family: Lato;
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
`;

const ClearChips = styled(Chips)`
	color: #f83528;
	background: transparent;
	cursor: pointer;
`;

const List = styled(StyledColumn)`
	width: 100%;
	max-height: 435px;
	overflow-y: scroll;

	& > *:not(:last-child) {
		border-bottom: 1px solid #c8cfd6;
	}
`;

const Item = styled(StyledP)<{
	active: boolean;
}>`
	padding: 16px 5px;
	gap: 16px;
	color: #21333f;
	background-color: ${(props) => (props.active ? "#ebebeb" : "#ffffff")};

	&:hover {
		background-color: #ebebeb;
	}
`;

const SortArrow = styled(StyledRow)<{ sortType?: "desc" | "asc" }>`
	display: block;
	position: relative;
	box-sizing: border-box;
	width: 6px;
	height: 10px;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);

	&:after,
	&:before {
		content: "";
		display: block;
		box-sizing: border-box;
		position: absolute;
		width: 5px;
		height: 5px;
		transform: translate(50%, 50%);
		-webkit-transform: translate(50%, 50%);
	}

	&:after {
		border-bottom: 2px solid #03a9f4;
		border-left: 2px solid #03a9f4;
		bottom: -1px;
		left: -2px;
	}
	&:before {
		border-top: 2px solid #03a9f4;
		border-right: 2px solid #03a9f4;
		top: 2px;
		right: -1px;
	}
	${({ sortType }) => {
		if (sortType === "desc") {
			return css`
				&:before {
					content: "";
					display: block;
					position: absolute;
					box-sizing: border-box;
					width: 1px;
					height: 7px;
					background-color: #03a9f4;
					transform: rotate(-135deg) translate(50%, 0px);
					-webkit-transform: rotate(-135deg) translate(50%, 0px);

					left: 4px;
					top: 7px;
				}
			`;
		}
		if (sortType === "asc") {
			return css`
				&:after {
					content: "";
					display: block;
					position: absolute;
					box-sizing: border-box;
					width: 1px;
					height: 7px;
					background-color: #03a9f4;
					transform: rotate(-135deg) translate(50%, 1px);
					-webkit-transform: rotate(-135deg) translate(50%, 1px);

					left: 4px;
					top: 7px;
				}
			`;
		}

		return "";
	}};
`;

const ListSelect: React.FC<ListSelect.Props> = ({
	value,
	options,
	title,
	required = false,
	children,
	footer,
	header,
	headerDivider,
	footerDivider,
	onSubmit,
	onClose,
	pullUpItemInsideArray = false,
	hiddenButton = true,
	warnText,
	clearText,
	showSelectAll,
	showAllItem,
	modalSetting,
	transactionText,
	selectOnlyOne = false,
	sort,
}) => {
	const { t } = useTranslation();
	const _warnText = warnText ?? t("orders.modals.listSelect.str200") ?? "";
	const _clearText = clearText ?? t("orders.modals.listSelect.str201") ?? "";
	const ref = useRef<HTMLInputElement | null>(null);
	const [query, setQuery] = useState("");
	const [selected, setSelected] = useState<ListSelect.Options>(value || []);

	const [filteredOptions, setOptions] = useState<ListSelect.Options>([]);
	const [sortType, setSortType] = useState<ListSelect.SortType | undefined>(
		sort?.sortType,
	);
	const [activeOption, setActiveOption] = useState(-1);
	const [warning, setWarning] = useState<boolean>(false);
	const [showActiveItem, setShowActiveItem] = useState<boolean>(false);

	useLayoutEffect(() => {
		if (sort) setSortType(sort?.sortType);
	}, [sort]);

	const handelSortType = useCallback(
		(value: ListSelect.SortType | undefined) => {
			setSortType(value);
			setQuery("");
			if (sort && sort.onSort && value) sort.onSort(value);
		},
		[sort],
	);

	const data = useMemo(() => {
		if (sort?.active && sortType) {
			const { active } = sort;
			if (active && sortType) {
				const payload = [...options]?.sort((prev, next) => {
					const prevValue =
						sortType === "asc" ? prev?.label : next?.label;
					const nextValue =
						sortType === "asc" ? next?.label : prev?.label;
					return sortByNumberOrString(
						prevValue || "",
						nextValue || "",
					);
				});
				return payload;
			}
		}

		return options;
	}, [options, sort, sortType]);

	const actualOptions = useMemo(
		() => (query.length ? filteredOptions : data),
		[filteredOptions, data, query.length],
	);

	const handleSelectAdd = useCallback(
		(item) => {
			if (!item) return;

			if (selectOnlyOne) {
				selected.find((option) => option.value === item.value) ||
					setSelected([item]);
				return;
			}

			selected.find((option) => option.value === item.value) ||
				setSelected([...selected, item]);
		},
		[selected, selectOnlyOne],
	);

	const handleSelectRemove = useCallback(
		(item) => {
			if (!item) return;
			!selected.find((option) => option.value === item.value) ||
				setSelected(selected.filter((v) => v.value !== item.value));
		},
		[selected],
	);

	const handleSelect = useCallback(
		(item) => {
			if (!item) return;

			if (selectOnlyOne) {
				selected.find((option) => option.value === item.value) ||
					setSelected([item]);
				return;
			}

			selected.find((option) => option.value === item.value)
				? setSelected(selected.filter((v) => v.value !== item.value))
				: setSelected([...selected, item]);
		},
		[selected, selectOnlyOne],
	);

	const handleSubmit = useCallback(() => {
		if (selected.length) {
			onSubmit(selected);
		} else if (required) {
			setWarning(true);
		} else {
			onSubmit([]);
		}
	}, [onSubmit, selected, required]);

	const getOptions = useCallback(
		(item: string) => {
			const existItem = data?.filter((option) =>
				option?.name
					?.toLocaleLowerCase()
					?.includes(item?.toLocaleLowerCase()),
			);

			if (pullUpItemInsideArray) {
				const noExistItem = data.filter(
					(option) =>
						!option.name
							?.toLocaleLowerCase()
							?.includes(item?.toLocaleLowerCase()),
				);
				return [...existItem, ...noExistItem];
			}

			return existItem;
		},
		[data, pullUpItemInsideArray],
	);

	const onChangeSearchTextBox = useCallback(
		(value: string) => {
			const isCheck =
				!value.trim().includes("+") && !value.trim().includes("-");

			if (isCheck) {
				handelSortType(undefined);
				setQuery(value);
				setOptions(getOptions(value));
				setActiveOption(0);
			}
		},
		[getOptions, handelSortType],
	);

	const debounceFocus = useCallback(() => {
		if (!ref?.current) return;
		const input = ref.current;
		input.focus();
	}, []);

	// --- Events ---
	useEffect(() => {
		debounceFocus();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ref?.current]);

	useEffect(() => {
		const handleKeyboardEvent = (event: KeyboardEvent) => {
			const keyEvent = event?.code;
			const isArrows = [
				"arrowup",
				"arrowdown",
				"arrowright",
				"arrowleft",
				"space",
			].includes(keyEvent.toLowerCase());

			if (keyEvent === "Space") {
				event.stopPropagation();
				event.preventDefault();

				const item = actualOptions[activeOption];
				if (
					isBoolean(item.active) &&
					!item.active &&
					!selected.find((option) => option.value === item.value)
				) {
					return;
				}

				handleSelect(item);
			}
			if (keyEvent === "ArrowUp") {
				setActiveOption((prev) =>
					prev - 1 < 0 ? actualOptions.length - 1 : prev - 1,
				);
			}
			if (keyEvent === "ArrowDown") {
				setActiveOption((prev) =>
					prev + 1 > actualOptions.length - 1 ? 0 : prev + 1,
				);
			}

			if (keyEvent === "ArrowLeft") {
				event.stopPropagation();
				event.preventDefault();
				const item = actualOptions[activeOption];
				if (
					isBoolean(item.active) &&
					!item.active &&
					!selected.find((option) => option.value === item.value)
				) {
					return;
				}
				handleSelectAdd(item);
			}
			if (keyEvent === "ArrowRight") {
				event.stopPropagation();
				event.preventDefault();
				const item = actualOptions[activeOption];
				if (
					isBoolean(item.active) &&
					!item.active &&
					!selected.find((option) => option.value === item.value)
				) {
					return;
				}
				handleSelectRemove(item);
			}

			if (isArrows) event.stopPropagation();
		};
		document.addEventListener("keydown", handleKeyboardEvent, true);
		return function cleanup() {
			document.removeEventListener("keydown", handleKeyboardEvent, true);
		};
	}, [
		actualOptions.length,
		activeOption,
		handleSelect,
		actualOptions,
		handleSelectAdd,
		handleSelectRemove,
		selected,
	]);

	useKeyBind(["Escape"], (e) => {
		onClose();
		e.preventDefault();
		e.stopPropagation();
	});

	useKeyBind(["ShiftLeft", "Equal"], () => {
		if (activeOption !== -1) {
			if (selectOnlyOne) {
				setSelected(uniq([actualOptions[activeOption]]));
			} else {
				setSelected(uniq([...selected, actualOptions[activeOption]]));
			}

			setQuery("");
		}
	});
	useKeyBind(["NumpadAdd"], () => {
		if (activeOption !== -1) {
			if (selectOnlyOne) {
				setSelected(uniq([actualOptions[activeOption]]));
			} else {
				setSelected(uniq([...selected, actualOptions[activeOption]]));
			}
			setQuery("");
		}
	});

	useKeyBind(["Minus"], () => {
		if (activeOption !== -1) {
			setSelected(
				selected.filter(
					(v) => v.value !== actualOptions[activeOption].value,
				),
			);
			setQuery("");
		}
	});

	const queryElement = useMemo(() => {
		try {
			const placeholder =
				t([
					transactionText?.query || "",
					"placeholder.base_query",
					"Enter text...",
				]) || "";

			return (
				<SearchTextBox
					inputRef={ref}
					style={{ width: "100%" }}
					value={query}
					onChange={onChangeSearchTextBox}
					placeholder={placeholder}
				/>
			);
		} catch {
			return <></>;
		}
	}, [onChangeSearchTextBox, query, t, transactionText]);

	const selectedAll = useCallback(
		(isAll: boolean) => {
			if (!isAll) setSelected([]);
			else {
				const items = data.filter((item) =>
					isBoolean(item?.active) ? item?.active === true : true,
				);
				setSelected(items);
			}
		},
		[data],
	);

	const all = useMemo(() => {
		const items = data.filter((item) =>
			isBoolean(item?.active) ? item?.active === true : true,
		);

		return selected.length === items?.length;
	}, [selected, data]);

	const inactiveText = useMemo(() => {
		const text1 = t("filter.options.all");
		const text2 = t("filter.options.inactive");
		return `${text1} ${text2}`;
	}, [t]);

	return (
		<Modal {...modalSetting}>
			<KeyBind
				keys={["Escape"]}
				onUse={() => {
					onClose();
				}}
			/>
			<KeyBind
				keys={["Enter"]}
				onUse={() => {
					handleSubmit();
				}}
			/>
			<Draggable handle=".handle">
				<StyledColumn br="10px" shadow="0px 0px 10px 2px gray">
					<HeaderMemo
						title={title}
						onClose={onClose}
						divider={headerDivider}
					>
						{header}
					</HeaderMemo>

					<StyledColumn
						gap="10px"
						alignItems="start"
						w="732px"
						h="537px"
						bgC="#ffffff"
					>
						<StyledRow w="100%" p="8px 24px">
							<Group sizes="32px auto">
								<SearchIconBorders>
									<Icon
										id="search"
										size={16}
										colors={["#5e6b73"]}
									/>
								</SearchIconBorders>
								{queryElement}
							</Group>
						</StyledRow>

						<StyledRow
							gap="3px"
							w="100%"
							flex={{ wrap: "wrap" }}
							h={{ min: "26px" }}
							justify="start"
							p="0 24px 20px 24px"
							overY="scroll"
							scrollbar
						>
							{selected.map((item) => (
								<Chips key={item.value}>
									{item.name}
									<StyledSpan
										cursor="pointer"
										onClick={() =>
											setSelected(
												selected.filter(
													(v) =>
														v.value !== item.value,
												),
											)
										}
									>
										<Icon
											id="close"
											size={8}
											colors={[
												theme.colors.disabled_text,
											]}
										/>
									</StyledSpan>
								</Chips>
							))}
							{Boolean(selected.length) && (
								<ClearChips onClick={() => setSelected([])}>
									<Icon
										id="close"
										size={8}
										colors={[theme.colors.negative]}
									/>
									{_clearText}
								</ClearChips>
							)}
						</StyledRow>
						<Divider
							side="top"
							gap="0 10px"
							justify="start"
							w="100%"
						>
							{showSelectAll && (
								<StyledRow
									alignItems="start"
									p="5px 10px 0 10px"
								>
									<CheckBox
										value={all}
										onChange={selectedAll}
									/>
								</StyledRow>
							)}
							{sort?.show && (
								<StyledRow
									alignItems="center"
									justify="start"
									w="max-content"
									p="5px 10px 0 10px"
									gap="0 3px"
									onClick={() => {
										handelSortType(
											sortType === "asc" ? "desc" : "asc",
										);
									}}
									cursor="pointer"
								>
									<StyledP>
										{t([
											sort?.label || "",
											"title",
											"Name",
										])}
									</StyledP>

									<SortArrow sortType={sortType} />
								</StyledRow>
							)}
							{showAllItem && (
								<StyledRow
									alignItems="start"
									p="5px 10px 0 10px"
								>
									<CheckBoxWithText
										value={showActiveItem}
										onChange={setShowActiveItem}
										title={inactiveText}
									/>
								</StyledRow>
							)}
						</Divider>
						<List
							justify="start"
							p="0 24px 15px 24px"
							h="max(50px, 90%)"
							scrollbar
						>
							{actualOptions.map((item, i) => {
								if (
									!showActiveItem &&
									isBoolean(item.active) &&
									!item.active &&
									!selected.find(
										(option) => option.value === item.value,
									)
								) {
									return <></>;
								}

								return (
									<Item
										text={{
											td:
												isBoolean(item.active) &&
												!item.active
													? "line-through"
													: "",
										}}
										cursor="pointer"
										active={activeOption === i}
										key={item.value}
										onClick={() => {
											if (
												isBoolean(item.active) &&
												!item.active &&
												!selected.find(
													(option) =>
														option.value ===
														item.value,
												)
											) {
												return;
											}
											handleSelect(item);
											setWarning(false);
										}}
									>
										<CheckBoxWithText
											disabled={
												isBoolean(item.active) &&
												!item.active &&
												!selected.find(
													(option) =>
														option.value ===
														item.value,
												)
											}
											gap="10px"
											value={Boolean(
												selected.find(
													(option) =>
														option.value ===
														item.value,
												),
											)}
											onChange={(status) => {
												if (!status) {
													setSelected(
														selected.filter(
															(v) =>
																v.value !==
																item.value,
														),
													);
												} else if (selectOnlyOne) {
													setSelected([item]);
												} else {
													setSelected([
														...selected,
														item,
													]);
												}

												setWarning(false);
											}}
											title={item?.name}
										/>
									</Item>
								);
							})}
						</List>

						{children}
					</StyledColumn>

					<FooterMemo
						onSave={() => handleSubmit()}
						onClose={onClose}
						divider={footerDivider}
						hiddenButton={hiddenButton}
					>
						{warning && (
							<StyledSpan colors="#F83528">
								{_warnText}
							</StyledSpan>
						)}
						{footer}
					</FooterMemo>
				</StyledColumn>
			</Draggable>
		</Modal>
	);
};

declare namespace ListSelect {
	interface Option {
		/** Name for selection */
		name: string;
		/** Key for selection */
		value: number;
		active?: boolean;
		data?: Record<string, any>;
		key?: string | number;
		label?: string;
	}

	type Options = Option[];
	type SortType = "desc" | "asc";

	interface Props extends PropsWithChildren {
		value: Options | undefined;
		options: Options;
		title?: string;
		required?: boolean;
		onSubmit: (value: Options) => void;
		onClose: Footer.Props["onClose"];
		footer?: React.ReactNode;
		footerDivider?: boolean;
		headerDivider?: boolean;
		header?: React.ReactNode;
		pullUpItemInsideArray?: boolean;
		hiddenButton?: boolean;
		warnText?: string | React.ReactNode;
		clearText?: string | React.ReactNode;

		showSelectAll?: boolean;
		showAllItem?: boolean;
		selectOnlyOne?: boolean;

		modalSetting?: Modal.Props;
		transactionText?: {
			query?: string;
			sortLabel?: string;
			warnText?: string | React.ReactNode;
			clearText?: string | React.ReactNode;
			title?: string;
		};
		sort?: {
			show?: boolean;
			active?: boolean;
			sortType?: SortType;
			label?: string;
			onSort?: (type: SortType) => void;
		};
	}
}

export const ListSelectMemo = memo(ListSelect);
export default ListSelect;
