import React from "react";

import Employee from "../../../../../../../../../../../../../../../../../../../services/Employee";
import Counterparty from "../../../../../../../../../../../../../../../../../../../services/Counterparty";
import GeneralEmployees from "../../../../../../../../../../../../tabs/Employees";

const Employees: React.FC<Employees.Props> = ({
	visible,
	value,
	onChange,
	companyId,
	taxiServiceId,
	counterpartyId,
	cards,
	checks,
	setEmployeeDeleteIds,
}) => (
	<GeneralEmployees
		columnsName="counterpartyEmployeeCheckCard"
		tableFrom="customers.counterparty.check.checkCard.employees"
		visible={visible}
		employees={value}
		setEmployees={onChange}
		canAdd
		companyId={companyId}
		taxiServiceId={taxiServiceId}
		counterpartyId={counterpartyId}
		cards={cards}
		checks={checks}
		setEmployeeDeleteIds={setEmployeeDeleteIds}
	/>
);

declare namespace Employees {
	interface Props {
		visible: boolean;
		value: Value;
		onChange: (x: Value) => void;
		companyId?: number;
		taxiServiceId?: number;
		counterpartyId?: number;
		cards?: Counterparty.Model["checks"][0]["checkCards"][0][];
		checks?: Counterparty.Model["checks"][0][];
		setEmployeeDeleteIds?: (
			ids: Counterparty.Model["checks"][0]["checkCards"][0]["employeeDeleteIds"],
		) => void;
	}

	type Value = (Employee.New | Employee.Model)[];
}

export default Employees;
