import React, {
	useCallback,
	useMemo,
	useState,
	useRef,
	useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import { useInternal } from "uikit";
import { merge } from "lodash";
import styled from "styled-components";

import {
	Sector,
	TaxiService,
	AccountSettings,
} from "../../../../../../services";
import { useModelSubscribeOld, useLanguage } from "../../../../../../hooks";
import { StyledColumn } from "../../../../../../components/common";
import { useNotification } from "../../../../../../components/NotificationProvider";

import { Modal, TaxiServiceForm } from "./components";

const Root = styled(StyledColumn)`
	padding-top: 20px;
	gap: 14px;
`;

const Divider = styled.div`
	width: 100%;
	height: 8px;
	background: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0.1),
		rgba(255, 255, 255, 0)
	);
`;

const ElementList = styled.ul`
	list-style-type: disc;
	padding: 0 0 0 20px;
	margin: 0;
`;

const ElementItem = styled.li`
	cursor: pointer;
	margin-bottom: 8px;
	color: #21333f;
	text-decoration: none;
	margin-left: 20px;
`;

const Programs = () => {
	const { t } = useTranslation();
	const language = useLanguage();
	const { success, warn, error } = useNotification();

	const TYPE_LINE = useMemo(
		() => ({
			FIL_SETTING:
				t("settings.parameters.tabs.programs.elementList.filSetting") ??
				"Setting up branches",
			CARS_AVTOMATIC:
				t(
					"settings.parameters.tabs.programs.elementList.carsAvtomatic",
				) ?? "List of cars for automatic call",
			COLORS_AVTOMATIC:
				t(
					"settings.parameters.tabs.programs.elementList.colorsAvtomatic",
				) ?? "List of colors for automatic call",
		}),
		[t],
	);

	const translateTexts = useMemo(
		() => ({
			success: t("notification.title.success") ?? "Success",
			warn: t("notification.title.warn") ?? "Warn",
			error: t("notification.title.error") ?? "Error",
			dataSuccessSave:
				t("notification.description.dataSuccessSave") ??
				"Data saved successfully",
			dataNotSave:
				t("notification.description.dataNotSave") ?? "Data not saved",
			dataDontLoad:
				t("notification.description.dataDontLoad") ??
				"Failed to upload data",
		}),
		[t],
	);

	const sectorData = useModelSubscribeOld({}, Sector);
	const sectors = useMemo(() => sectorData?.cache ?? [], [sectorData?.cache]);

	const taxiServiceFormRef = useRef<TaxiServiceForm.Controller | null>(null);
	const [showModal, setShowModal] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [overload, setOverload] = useState<number>(0);
	const [filSetting, setFilSetting] = useState<Record<string, any> | null>(
		null,
	);
	const [carSetting, setCarSetting] = useState<Record<string, any> | null>(
		null,
	);
	const [colorSetting, setColorSetting] = useState<Record<
		string,
		any
	> | null>(null);
	const [jsonData, setJsonData] = useState<Record<string, any> | null>(null);
	const [internalValue] = useInternal<Programs.Value>({ bySector: false });
	const [editingModelItem, setEditingModelItem] =
		useState<Programs.Value | null>(null);

	useEffect(() => {
		const { taxiServiceId } = editingModelItem || {};

		if (!taxiServiceId) return;

		const fetchSetting = async () => {
			const result = await TaxiService.getById(taxiServiceId);
			if (!result)
				error(translateTexts.error, translateTexts.dataDontLoad);
			setFilSetting(result?.settings ?? null);
		};

		fetchSetting();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		editingModelItem,
		overload,
		translateTexts.dataDontLoad,
		translateTexts.error,
	]);

	useEffect(() => {
		const fetchCar = async () => {
			const result = await AccountSettings.getCarJson();
			if (!result)
				error(translateTexts.error, translateTexts.dataDontLoad);
			setCarSetting(result ?? null);
		};

		const fetchColor = async () => {
			const result = await AccountSettings.getColorJson();
			if (!result)
				error(translateTexts.error, translateTexts.dataDontLoad);
			setColorSetting(result ?? null);
		};

		fetchCar();
		fetchColor();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [overload, translateTexts.dataDontLoad, translateTexts.error]);

	const cancelHandler = useCallback(() => {
		setShowModal(null);
	}, []);

	const saveHandler = useCallback(
		async (type: string, settings: Record<string, any>) => {
			if (!settings) return; // Validate input

			let result: boolean | undefined;
			let attempts = 0;
			const maxAttempts = 3;

			setIsLoading(true);

			while (attempts < maxAttempts) {
				try {
					switch (type) {
						case TYPE_LINE.FIL_SETTING: {
							const { taxiServiceId } = editingModelItem || {};

							if (!taxiServiceId)
								throw new Error("Taxi service ID is missing");

							const resultGetByIdTaxiService =
								await TaxiService.getById(taxiServiceId);
							if (!resultGetByIdTaxiService)
								throw new Error("Taxi service not found");

							const dataOnUpdate = merge(
								{},
								resultGetByIdTaxiService,
								{ settings },
							);

							result = await TaxiService.update2(dataOnUpdate);

							break;
						}

						case TYPE_LINE.CARS_AVTOMATIC: {
							result = await AccountSettings.updateCarJson(
								settings,
							);

							break;
						}

						case TYPE_LINE.COLORS_AVTOMATIC: {
							result = await AccountSettings.updateColorJson(
								settings,
							);

							break;
						}

						default:
							throw new Error(`Unrecognized type: ${type}`);
					}

					if (result) break;
				} catch (error) {
					console.error(
						`[Programs]: Error processing ${type}`,
						error,
					);
					warn(translateTexts.warn, translateTexts.dataNotSave);

					attempts += 1;

					if (attempts >= maxAttempts) {
						console.error(
							`[Programs]: Failed to update ${type} after ${attempts} attempts`,
						);
						setIsLoading(false);
						return;
					}
				}
			}

			result
				? success(
						translateTexts.success,
						translateTexts.dataSuccessSave,
				  )
				: warn(translateTexts.warn, translateTexts.dataNotSave);

			setIsLoading(false);

			// Handle modal and settings cleanup
			setShowModal(null);
			setCarSetting(null);
			setColorSetting(null);
			setFilSetting(null);

			// Trigger a re-render or update
			setOverload((pre) => pre + 1);
		},
		[
			TYPE_LINE.CARS_AVTOMATIC,
			TYPE_LINE.COLORS_AVTOMATIC,
			TYPE_LINE.FIL_SETTING,
			editingModelItem,
			success,
			translateTexts.dataNotSave,
			translateTexts.dataSuccessSave,
			translateTexts.success,
			translateTexts.warn,
			warn,
		],
	);

	const sectorById = useMemo(
		() =>
			sectors.reduce((accumulator, sector) => {
				accumulator[sector.id] = sector;

				return accumulator;
			}, {} as Record<number, Sector.Model>),
		[sectors],
	);

	const taxiServiceFormOnChange = useCallback(
		(taxiServiceId: TaxiServiceForm.Value) => {
			const sectorIds = internalValue.bySector
				? internalValue.sectorIds
				: internalValue.sectorIds?.filter(
						(sectorId) =>
							!sectorById[sectorId] ||
							sectorById[sectorId].taxiServiceId ===
								taxiServiceId,
				  );

			const isNeedsCleaning = (): boolean => {
				const { taxiServiceId: preTaxiServiceId } =
					editingModelItem || {};

				const retval: boolean =
					!taxiServiceId || preTaxiServiceId !== taxiServiceId;
				return retval;
			};

			if (isNeedsCleaning()) setFilSetting(null);

			setEditingModelItem({ ...internalValue, sectorIds, taxiServiceId });
		},
		[editingModelItem, internalValue, sectorById],
	);

	const handleItemClick = (index: string, _jsonData: Record<string, any>) => {
		setShowModal(index);
		setJsonData(_jsonData);
	};

	const elementList = (typeLine, json) => (
		<ElementItem onClick={() => handleItemClick(typeLine, json)}>
			{typeLine}
		</ElementItem>
	);

	return (
		<Root>
			<TaxiServiceForm
				ref={taxiServiceFormRef}
				value={internalValue.taxiServiceId}
				language={language}
				onChange={taxiServiceFormOnChange}
			/>
			<Divider />
			<ElementList>
				{filSetting && elementList(TYPE_LINE.FIL_SETTING, filSetting)}
				{carSetting &&
					elementList(TYPE_LINE.CARS_AVTOMATIC, carSetting)}
				{colorSetting &&
					elementList(TYPE_LINE.COLORS_AVTOMATIC, colorSetting)}
			</ElementList>

			{showModal && jsonData && (
				<Modal
					onCancel={cancelHandler}
					onSave={saveHandler}
					value={jsonData}
					headerTitle={showModal}
					isLoading={isLoading}
				/>
			)}
		</Root>
	);
};

declare namespace Programs {
	interface Value {
		id?: number;
		taxiServiceId?: number;
		sectorIds?: (number | "outside")[];

		bySector: boolean;
	}
}

export default Programs;
