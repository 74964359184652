import React, { useMemo, memo, useLayoutEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import Language from "../../../services/Language";
import { useTypedSelector } from "../../../redux/store";
import { StyledP, StyledRow } from "../styles";
// import { useAppContext } from "../../../context";

const CarClassesByIdsBase: React.FC<CarClassesByIdsBase.Props> = ({
	taxiServiceIds,
	carClassIds,
	lang,
	onSize,
}) => {
	const { t } = useTranslation();
	// const {
	// 	getTaxiServiceIds,
	// 	getCompanyName,
	// 	getTaxiServiceName,
	// 	getCompanyIdByTaxiServiceId,
	// } = useAppContext();
	const { models } = useTypedSelector(
		(state) => state.preferencesReducer.carClasses.data,
	);

	const ref = useRef<HTMLDivElement | null>(null);

	const allCars = useMemo(
		() => t(["all_cars", "All transport classes"]),
		[t],
	);

	useLayoutEffect(() => {
		const height = ref?.current?.offsetHeight;
		if (height !== undefined) onSize?.(height);
	}, [onSize, ref?.current?.offsetHeight]);

	const data = useMemo(() => {
		if (!models.length) return [];
		return models
			?.filter((carClass) => carClassIds.includes(carClass?.id))
			.map(({ name }) => name?.[lang] || "");
	}, [models, carClassIds, lang]);

	if (!data.length) return <StyledRow></StyledRow>;
	if (data.length === models.length) {
		return (
			<StyledRow gap="0 8px">
				<StyledP>{allCars}</StyledP>
			</StyledRow>
		);
	}

	return (
		<StyledRow
			ref={ref}
			gap="0 8px"
			flex={onSize ? { wrap: "wrap" } : undefined}
			w={onSize ? { min: "200px" } : ""}
		>
			{data.length === 1 && <StyledP>{data[0]}</StyledP>}

			{data.length > 1 &&
				data.map((item, i, arr) => (
					<StyledP key={item}>{`${item}${
						arr?.[i + 1] ? "," : ""
					}`}</StyledP>
				))}
		</StyledRow>
	);
};

export const CarClassesByIds = memo(CarClassesByIdsBase);
declare namespace CarClassesByIdsBase {
	interface Props {
		taxiServiceIds?: number[];
		carClassIds: number[];
		lang: Language;
		onSize?: (value: number) => void;
	}
}

export default CarClassesByIdsBase;
