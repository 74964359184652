import React from "react";
import { Icon } from "uikit";
import { Link } from "react-router-dom";

const Logo: React.FC<Logo.Props> = () => (
	<Link tabIndex={-1} to="/">
		<Icon id="logo" size={[113, 28]} />
	</Link>
);

declare namespace Logo {
	interface Props {}
}

export default Logo;
