import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tabs } from "uikit";

import { useTypedSelector } from "../../../../redux/store";
import useActiveTab from "../../../../hooks/useActiveTab";
import { hasAccess, generateAccessName, AccessKey } from "../../../../access";
import { TabOptions } from "../../../../types";
import { CommunicationMethods } from "../../../../components/Settings/tabProps";
import BasicPageLayout from "../../../../components/BasicPageLayout";

import PushNotices from "./PushNotices";
import Templates from "./Templates";
import Provider from "./Provider";

const MessageSettings: React.FC = () => {
	const [ACCESS_SECTION, ACCESS_SECTION_2] = [
		AccessKey.SETTINGS,
		AccessKey.SETTINGS_MESSAGES,
	];
	const { t } = useTranslation();
	const methods = useRef<CommunicationMethods>();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const [, setHasChanges] = useState(false);

	const tabs: TabOptions.Array = useMemo(
		() =>
			[
				{
					key: "providerSms",
					label: t("settings.messages.providerSms.title"),
					value: {
						render() {
							return (
								<Provider
									methods={(communicationMethods) => {
										methods.current = communicationMethods;
									}}
									onHasChanges={setHasChanges}
								/>
							);
						},
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						ACCESS_SECTION_2,
						AccessKey.PROVIDER_SMS,
					),
				},
				{
					key: "templatesSms",
					label: t("settings.messages.templatesSms.title"),
					value: {
						render: () => (
							<Templates
								methods={() => ""}
								onHasChanges={() => ""}
							/>
						),
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						ACCESS_SECTION_2,
						AccessKey.TEMPLATES_SMS,
					),
				},
				{
					key: "pushNotices",
					label: t("settings.messages.pushNotices.title"),
					value: {
						render: () => (
							<PushNotices
								methods={() => ""}
								onHasChanges={() => ""}
							/>
						),
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						ACCESS_SECTION_2,
						AccessKey.PUSH_NOTICES,
					),
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, ACCESS_SECTION, ACCESS_SECTION_2, personalRole],
	);
	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	return (
		<BasicPageLayout
			navigation={
				<Tabs
					value={activeKey}
					onChange={setActiveKey}
					options={tabs}
					variant="vertical"
				/>
			}
			content={activeTab.value.render()}
		/>
	);
};

export default MessageSettings;
