import { isObject } from "lodash";

import { DisplayFields, AccessComponent, AccessKey } from ".";

const mergeAccessData = (
	defaultData: DisplayFields,
	subData: DisplayFields,
): DisplayFields => {
	const merge = (
		defaultObj: AccessComponent,
		subObj: AccessComponent,
	): AccessComponent => {
		const result: AccessComponent = { ...defaultObj };

		const isValidObject = (value: any): boolean =>
			isObject(value) && value !== null;

		if (!isValidObject(subObj)) {
			return result;
		}

		Object.keys(subObj).forEach((key) => {
			if (key === AccessKey.SHOW) {
				result[key] = subObj[key];
			} else if (
				isValidObject(subObj[key]) &&
				isValidObject(result[key])
			) {
				result[key] = merge(
					result[key] as AccessComponent,
					subObj[key] as AccessComponent,
				);
			}
		});

		return result;
	};

	return merge(defaultData, subData);
};

export default mergeAccessData;
