import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { CheckBox, Column, Row, react, useRefWithSetter } from "uikit";

import CarClass from "../../../../../../../../../../../../../../../../../../services/CarClass";
import { AutoRateRulePolygonType } from "../../../../../../../../../../../../../../../../../../services/AutoTariff/enums";
import useObjectEditor from "../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import FieldsContainer from "../../../../../../../../../../../../../../../../../../components/FieldsContainer";
import {
	MultiSelectCarClassWithModal,
	MultiSelectCarClassWithModalBase,
} from "../../../../../../../../../../../../../../../../../../components/common";
import tPath from "../../../../constants/tPath";

import Controller from "./Controller";
import Root from "./components/Root";
import MinOrderCost from "./components/MinOrderCost";
import Sectors from "./components/Sectors";
import Name from "./components/Name";
import ValidityDate from "./components/ValidityDate";
import ValidityTime from "./components/ValidityTime";
import WeekDays from "./components/WeekDays";
import TariffCalculation from "./components/TariffCalculation";
import PriceZoneChangeDistanceThreshold from "./components/PriceZoneChangeDistanceThreshold";
import ApplyToOrderPoints from "./components/ApplyToOrderPoints";
import OrderSource from "./components/OrderSource";
import Orders from "./components/Orders";
import SelectTariffZones from "./components/SelectTariffZones";

const ContentBase = react.withController<Content.PropsBase, Controller>(
	({ value, onChange, controller, taxiServiceIds }) => {
		const [sectorsRef, setSectorsRef] =
			useRefWithSetter<Sectors.Ref | null>(null);
		const [nameRef, setNameRef] = useRefWithSetter<Name.Ref | null>(null);
		controller.setContext({ sectorsRef, nameRef });

		const { t } = useTranslation();

		const valueEditor = useObjectEditor(value, onChange);

		const name = valueEditor.useGetter("name");
		const setName = valueEditor.useSetter("name");

		const priceZoneIds = valueEditor.useGetter("priceZoneIds");
		const setPriceZoneIds = valueEditor.useSetter("priceZoneIds");

		const sectorIds = valueEditor.useGetter("sectorIds");
		const setSectorIds = valueEditor.useSetter("sectorIds");

		const carClassIds = valueEditor.useGetter("carClassIds");
		const setCarClassIds = valueEditor.useSetter("carClassIds");

		const additionalFields = valueEditor.useGetter("additionalFields");
		const setAdditionalFields = valueEditor.useSetter("additionalFields");

		const editor = useObjectEditor(additionalFields, setAdditionalFields);

		const validityDate = editor.useGetter("validityDate");
		const setValidityDate = editor.useSetter("validityDate");

		const validityTime = editor.useGetter("validityTime");
		const setValidityTime = editor.useSetter("validityTime");

		const weekdays = editor.useGetter("weekdays");
		const setWeekdays = editor.useSetter("weekdays");

		const sectors = editor.useGetter("sectors");
		const setSectors = editor.useSetter("sectors");

		const applyToOrderPoints = editor.useGetter("applyToOrderPoints");
		const setApplyToOrderPoints = editor.useSetter("applyToOrderPoints");

		const orderSource = editor.useGetter("orderSource");
		const setOrderSource = editor.useSetter("orderSource");

		const orders = editor.useGetter("orders");
		const setOrders = editor.useSetter("orders");

		const carClasses = editor.useGetter("carClasses");
		const setCarClasses = editor.useSetter("carClasses");
		const handleCarClassesActiveChange = useCallback(
			(newActive) => {
				setCarClasses({
					...carClasses,
					active: newActive,
				});
			},
			[carClasses, setCarClasses],
		);

		const subTaximeter = editor.useGetter("considerSuburbanInTaximeter");
		const setSubTaximeter = editor.useSetter("considerSuburbanInTaximeter");

		const minOrderCost = editor.useGetter("minOrderCost");
		const setMinOrderCost = editor.useSetter("minOrderCost");

		const priceZoneD = editor.useGetter("priceZoneChangeDistanceThreshold");
		const setPriceZoneD = editor.useSetter(
			"priceZoneChangeDistanceThreshold",
		);

		const calculationType = editor.useGetter("calculationType");
		const setCalculationType = editor.useSetter("calculationType");

		const polygonType = editor.useGetter("polygonType");
		const setPolygonType = editor.useSetter("polygonType");

		const outsidePriceZones = editor.useGetter("outsidePriceZones");
		const setOutsidePriceZones = editor.useSetter("outsidePriceZones");

		const disabled = useMemo(() => polygonType === "sector", [polygonType]);

		return (
			<Root hasPaddings>
				<Column gaps="25px*">
					<FieldsContainer
						label={t(`${tPath}.modal.general`) || ""}
						gaps="25px*"
					>
						<Name ref={setNameRef} name={name} setName={setName} />
						<ValidityDate
							value={validityDate}
							onChange={setValidityDate}
						/>
						<ValidityTime
							value={validityTime}
							onChange={setValidityTime}
						/>
						<WeekDays value={weekdays} onChange={setWeekdays} />
						<TariffCalculation
							disabled={disabled}
							type={calculationType}
							setType={setCalculationType}
						/>
						<PriceZoneChangeDistanceThreshold
							disabled={disabled}
							value={priceZoneD}
							onChange={setPriceZoneD}
						/>
						<ApplyToOrderPoints
							disabled={disabled}
							value={applyToOrderPoints}
							onChange={setApplyToOrderPoints}
						/>
						<OrderSource
							value={orderSource}
							onChange={setOrderSource}
						/>
						<Orders value={orders} onChange={setOrders} />
						<Row gaps="10px*" sizes="auto 1fr">
							<CheckBox
								label={
									t(
										"pages.settings.pages.mobileApps.executor.modal.content.tabs.main.str208",
									) || ""
								}
								value={carClasses.active}
								onChange={handleCarClassesActiveChange}
							/>

							<MultiSelectCarClassWithModal
								disabled={
									!carClasses.active || !taxiServiceIds.length
								}
								value={carClassIds}
								onChange={setCarClassIds}
								titleText={
									"pages.settings.pages.mobileApps.executor.modal.content.tabs.main.str1"
								}
								subscribeOptions={{
									taxiServiceIds,
								}}
							/>
						</Row>
						<MinOrderCost
							value={minOrderCost}
							onChange={setMinOrderCost}
						/>
						<CheckBox
							disabled={disabled}
							label={
								t(
									`${tPath}.modal.considerSuburbanInTaximeter`,
								) || ""
							}
							value={subTaximeter}
							onChange={setSubTaximeter}
						/>
						<SelectTariffZones
							outsidePriceZones={outsidePriceZones}
							setOutsidePriceZones={setOutsidePriceZones}
							polygonType={polygonType}
							setPolygonType={setPolygonType}
							value={priceZoneIds}
							onChange={setPriceZoneIds}
						/>
					</FieldsContainer>
					<FieldsContainer
						label={t(`${tPath}.modal.autoIncreaseDecrease`) || ""}
					>
						<Sectors
							ref={setSectorsRef}
							polygonType={polygonType}
							setPolygonType={setPolygonType}
							sectorIds={sectorIds}
							setSectorIds={setSectorIds}
							value={sectors}
							onChange={setSectors}
						/>
					</FieldsContainer>
				</Column>
			</Root>
		);
	},
	Controller,
);

const Content = memo(ContentBase);

declare namespace Content {
	type Ref = Controller | null;
	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;
		autoClasses: CarClass.Model[];
		taxiServiceIds: number[];
	}
	type Props = PropsBase & RefAttributes<Ref>;
	interface Value {
		name: string;
		priceZoneIds: SelectTariffZones.Value;
		sectorIds: Sectors.Props["sectorIds"];
		carClassIds: MultiSelectCarClassWithModalBase.Value;
		position?: Date;
		additionalFields: AdditionalFields;
	}

	interface AdditionalFields {
		validityDate: ValidityDate.Value;
		validityTime: ValidityTime.Value;
		weekdays: WeekDays.Value;
		calculationType: TariffCalculation.Value;
		applyToOrderPoints: ApplyToOrderPoints.Value;
		orderSource: OrderSource.Value;
		orders: Orders.Value;
		considerSuburbanInTaximeter: boolean;
		priceZoneChangeDistanceThreshold: PriceZoneChangeDistanceThreshold.Value;
		sectors: Sectors.Value;
		minOrderCost: MinOrderCost.Value;
		carClasses: {
			active: boolean;
		};
		polygonType: AutoRateRulePolygonType;
		outsidePriceZones: SelectTariffZones.Props["outsidePriceZones"];
	}
}

export default Content;
