import React, { memo, useCallback, useMemo } from "react";
import { CheckBox, Column, MultiSelect, Row, Select } from "uikit";
import { useTranslation } from "react-i18next";
import TabRoot from "../../components/TabRoot";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import CompaniesAndTaxiServices from "./components/CompaniesAndTaxiServices";
import FieldsContainer from "../../../../../../../../../../../../components/FieldsContainer";
import ImprovedStepper from "../../../../../../../../../../../../components/ImprovedStepper";
import { languageOptions } from "../../../../../../../../../../../../assets/languages/langs";
import MapSettings from "../../../../../../../../../../../../services/MapSettings";
import { NonEditablePropertyNames } from "../../../../../../../../../../../../types/NonEditableProperties";
import MapServices from "./components/MapServices";
import RouteCalculation from "./components/RouteCalculation";
import tPath from "../../../../../../constants/tPath";
import { defaultSearchTypeOptions } from "./constants";

const gaps = "8px*";
const sizes = "1fr 1fr";
const path = `${tPath}.modal.tabs.main`;

const Main: React.FC<Main.Props> = memo(({ value, onChange }) => {
	const { t } = useTranslation();

	const editor = useObjectEditor(value, onChange);

	const settings = editor.useGetter("settings");
	const setSettings = editor.useSetter("settings");
	const settingsEditor = useObjectEditor(settings, setSettings);

	const general = settingsEditor.useGetter("general");
	const setGeneral = settingsEditor.useSetter("general");
	const genEdit = useObjectEditor(general, setGeneral);

	const allMapServices = genEdit.usePicker([
		"mapServices",
		"additionalMapServices",
	]);

	const routeCalc = genEdit.useGetter("routeCalculationMode");
	const setRouteCalc = genEdit.useSetter("routeCalculationMode");

	const minSrchQ = genEdit.useGetter("minSearchQueryLength");
	const setMinSrchQ = genEdit.useSetter("minSearchQueryLength");

	const coordExp = genEdit.useGetter("coordinateExpirationMs");
	const setCoordExp = genEdit.useSetter("coordinateExpirationMs");

	const crdReqIntl = genEdit.useGetter("coordinateRequestIntervalMs");
	const setCrdReqInl = genEdit.useSetter("coordinateRequestIntervalMs");

	const revealRadiusMeters = genEdit.useGetter("revealRadiusMeters");
	const setRevealRadiusMeters = genEdit.useSetter("revealRadiusMeters");

	const searchRadiusMeters = genEdit.useGetter("searchRadiusMeters");
	const setSearchRadiusMeters = genEdit.useSetter("searchRadiusMeters");

	const inputRequestDelay = genEdit.useGetter("inputRequestDelayMs");
	const setInputRequestDelay = genEdit.useSetter("inputRequestDelayMs");

	const adrsExption = genEdit.useGetter("addressCacheExpiration");
	const setAdrsExption = genEdit.useSetter("addressCacheExpiration");

	const dispObjInApp = genEdit.useGetter("displayLocalObjectsInClientApp");
	const setDispObjInApp = genEdit.useSetter("displayLocalObjectsInClientApp");

	const calcExtraDist = genEdit.useGetter("calculateExtraDistance");
	const setCalcExtraDist = genEdit.useSetter("calculateExtraDistance");

	const mapLanguage = genEdit.useGetter("mapLanguage");
	const setMapLanguage = genEdit.useSetter("mapLanguage");
	const handleMapLanguageChange = useCallback(
		(newLang) => {
			setMapLanguage(newLang);
		},
		[setMapLanguage],
	);

	const defaultSearchType = genEdit.useGetter("defaultSearchType");
	const setDefaultSearchType = genEdit.useSetter("defaultSearchType");
	const handleSearchTypeChange = useCallback(
		(newSearchType) => {
			setDefaultSearchType(newSearchType);
		},
		[setDefaultSearchType],
	);

	const tSearchTypeOptions = useMemo(
		() =>
			defaultSearchTypeOptions.map((o) => ({
				...o,
				label: t(`${path}.defaultSearchType.options.${o.key}`),
			})),
		[t],
	);

	const generalAssigner = genEdit.useAssigner();

	const taxiServicesData = editor.usePicker([
		"taxiServices",
		"taxiServiceIds",
	]);

	const assigner = editor.useAssigner();

	return (
		<TabRoot hasPaddings visible={true}>
			<Column>
				<Column gaps="25px*" maxedWidth maxedHeight>
					<Row sizes={sizes} gaps="16px*">
						<CompaniesAndTaxiServices
							value={taxiServicesData}
							onChange={assigner}
						/>
					</Row>
					<FieldsContainer gaps={gaps}>
						<MapServices
							value={allMapServices}
							onChange={generalAssigner}
						/>
						<RouteCalculation
							value={routeCalc}
							onChange={setRouteCalc}
						/>
						<Row align="center" gaps={gaps} sizes={sizes}>
							<div>{t(`${path}.minSearchQueryLength`)}</div>
							<div>
								<ImprovedStepper
									width="80px"
									value={minSrchQ}
									onChange={setMinSrchQ}
								/>
							</div>
						</Row>
						<Row align="center" gaps={gaps} sizes={sizes}>
							<div>{t(`${path}.inputRequestDelay`)}</div>
							<Row align="center" gaps={gaps}>
								<ImprovedStepper
									width="80px"
									value={inputRequestDelay}
									onChange={setInputRequestDelay}
								/>
								<div>{t(`${path}.ms`)}.</div>
							</Row>
						</Row>
						<Row align="center" gaps={gaps} sizes={sizes}>
							<div>{t(`${path}.coordinateExpirationMs`)}</div>
							<Row align="center" gaps={gaps}>
								<ImprovedStepper
									width="80px"
									value={coordExp}
									onChange={setCoordExp}
								/>
								<div>{t(`${path}.ms`)}.</div>
							</Row>
						</Row>
						<Row align="center" gaps={gaps} sizes={sizes}>
							<div>
								{t(`${path}.coordinateRequestIntervalMs`)}
							</div>
							<Row align="center" gaps={gaps}>
								<ImprovedStepper
									width="80px"
									value={crdReqIntl}
									onChange={setCrdReqInl}
								/>
								<div>{t(`${path}.ms`)}.</div>
							</Row>
						</Row>
						<Row align="center" gaps={gaps} sizes={sizes}>
							<div>{t(`${path}.revealRadiusMeters`)}</div>
							<Row align="center" gaps={gaps}>
								<ImprovedStepper
									width="80px"
									value={revealRadiusMeters}
									onChange={setRevealRadiusMeters}
								/>
								<div>{t(`${path}.m`)}.</div>
							</Row>
						</Row>
						<Row align="center" gaps={gaps} sizes={sizes}>
							<div>{t(`${path}.searchRadiusMeters`)}</div>
							<Row align="center" gaps={gaps}>
								<ImprovedStepper
									width="80px"
									value={searchRadiusMeters}
									onChange={setSearchRadiusMeters}
								/>
								<div>{t(`${path}.m`)}.</div>
							</Row>
						</Row>
						<Row align="center" gaps={gaps} sizes={sizes}>
							<div>{t(`${path}.addressCacheExpiration`)}</div>
							<Row align="center" gaps={gaps}>
								<ImprovedStepper
									width="80px"
									value={adrsExption}
									onChange={setAdrsExption}
								/>
								<div>{t(`${path}.days`)}</div>
							</Row>
						</Row>
						<Row align="center" gaps={gaps} sizes={sizes}>
							<div>{t(`${path}.mapLanguage.title`)}</div>
							<Select
								placeholder={
									t(`${path}.mapLanguage.placeholder`) || ""
								}
								options={languageOptions}
								value={mapLanguage}
								onChange={handleMapLanguageChange}
							/>
						</Row>
						<Row align="center" gaps={gaps} sizes={sizes}>
							<div>{t(`${path}.defaultSearchType.title`)}</div>
							<MultiSelect
								placeholder={
									t(
										`${path}.defaultSearchType.placeholder`,
									) || ""
								}
								options={tSearchTypeOptions}
								value={defaultSearchType}
								onChange={handleSearchTypeChange}
							/>
						</Row>
						<CheckBox
							label={t(`${path}.displayObjInClientApp`) || ""}
							value={dispObjInApp}
							onChange={setDispObjInApp}
						/>
						<CheckBox
							label={t(`${path}.calculateExtraDistance`) || ""}
							value={calcExtraDist}
							onChange={setCalcExtraDist}
						/>
					</FieldsContainer>
				</Column>
			</Column>
		</TabRoot>
	);
});

declare namespace Main {
	interface Props {
		value: Value;
		onChange: (value: Value) => void;
	}

	type Value = Omit<MapSettings.Model, NonEditablePropertyNames | "name">;
}

export default Main;
