import React, { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Tabs } from "uikit";

import { useTypedSelector } from "../../../../redux/store";
import useActiveTab from "../../../../hooks/useActiveTab";
import { hasAccess, generateAccessName, AccessKey } from "../../../../access";
import { TabOptions } from "../../../../types";
import BasicPageLayout from "../../../../components/BasicPageLayout";
import { CommunicationMethods } from "../../../../components/Settings/tabProps";

import Customer from "./Customer";
import Executor from "./Executor";

const PaymentSystemSettings: React.FC = () => {
	const [ACCESS_SECTION, ACCESS_SECTION_2] = [
		AccessKey.SETTINGS,
		AccessKey.SETTINGS_PAYMENT_SYSTEMS,
	];
	const { t } = useTranslation();
	const methods = useRef<CommunicationMethods>();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const tabs: TabOptions.Array = useMemo(
		() =>
			[
				{
					key: "customer",
					label: t("settings.paymentSystems.customer.title"),
					value: {
						render: () => (
							<Customer
								methods={(communicationMethods) => {
									methods.current = communicationMethods;
								}}
								onHasChanges={() => ""}
							/>
						),
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						ACCESS_SECTION_2,
						AccessKey.CUSTOMER,
					),
				},
				{
					key: "executor",
					label: t("settings.paymentSystems.executor.title"),
					value: {
						render: () => (
							<Executor
								methods={() => ""}
								onHasChanges={() => ""}
							/>
						),
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						ACCESS_SECTION_2,
						AccessKey.EXECUTOR,
					),
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, ACCESS_SECTION, ACCESS_SECTION_2, personalRole],
	);

	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);
	return (
		<BasicPageLayout
			navigation={
				<Tabs
					value={activeKey}
					onChange={setActiveKey}
					options={tabs}
					variant="vertical"
				/>
			}
			content={activeTab.value.render()}
		/>
	);
};

export default PaymentSystemSettings;
