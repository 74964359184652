import React, { useMemo } from "react";
import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const backgroundColorByStatus = {
	offer: "#51C3F7",
	executing: "rgb(76, 175, 80)",
	inactive: "#ebebeb",
};

const ExecutorCallSignCellContent: React.FC<
	ExecutorCallSignCellContent.Props
> = ({ item }) => {
	const executor = useMemo(
		() => item.executorToOrder[0]?.executor ?? item.offer?.executor,
		[item.executorToOrder, item.offer?.executor],
	);

	const content = useMemo(() => executor?.callSign, [executor?.callSign]);

	const backgroundColor = useMemo(() => {
		if (item.executingStages?.accepted) {
			return backgroundColorByStatus.executing;
		}

		if (item.executingStages?.assigned) {
			return backgroundColorByStatus.inactive;
		}

		if (item.offer) {
			return item.offer.executor.online
				? backgroundColorByStatus.offer
				: backgroundColorByStatus.inactive;
		}

		return undefined;
	}, [
		item.executingStages?.accepted,
		item.executingStages?.assigned,
		item.offer,
	]);

	return (
		<CellContentRoot
			align="center"
			backgroundColor={backgroundColor}
			maxedHeight
			maxedWidth
		>
			{content}
		</CellContentRoot>
	);
};

declare namespace ExecutorCallSignCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default ExecutorCallSignCellContent;
