import React, { memo, useCallback, useMemo, CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { DatePicker, RadioButton, TimeInput } from "uikit";

import useDatePickerLocale from "../../../../hooks/useDatePickerLocale";
import { maxDate } from "../../../../utils/clampDate";
import {
	StyledRow,
	StyledColumn,
	StyledP,
	BaseElementIdEnum,
} from "../../../common";

import BaseInputModal from "../BaseInputModal";

interface BaseStyle {
	cursor?: CSSProperties["cursor"];
}

const BaseDatePicker = styled(DatePicker)<BaseStyle>`
	width: 130px;
	${({ cursor }) => cursor && `cursor:${cursor};`}
	input {
		${({ cursor }) => cursor && `cursor:${cursor};`}
	}
	div {
		${({ cursor }) => cursor && `cursor:${cursor};`}
		svg {
			${({ cursor }) => cursor && `cursor:${cursor};`}
		}
	}
`;
const BaseTime = styled(TimeInput)<BaseStyle>`
	${({ cursor }) => cursor && `cursor:${cursor};`}
	input {
		${({ cursor }) => cursor && `cursor:${cursor};`}
	}
`;

const TimeSelect: React.FC<TimeSelect.Props> = ({ value, setValue }) => {
	const { t } = useTranslation();

	const onNow = useCallback(() => {
		setValue({ time: new Date(), on: "now" });
	}, [setValue]);

	const onLater = useCallback(() => {
		setValue((prev) => ({ ...prev, on: "later" }));
	}, [setValue]);

	const disabled = useMemo(() => value.on === "now", [value.on]);

	const onChangeDatePicker = useCallback(
		(date: DatePicker.Value) => {
			if (!date) return;
			if (disabled) return;
			const newDate = value.time ? new Date(value.time) : new Date();
			newDate.setFullYear(date.getFullYear());
			newDate.setMonth(date.getMonth());
			newDate.setDate(date.getDate());

			const time = maxDate(newDate, new Date());
			setValue((prev) => ({ ...prev, time }));
		},
		[setValue, value.time, disabled],
	);

	const onChangeTime = useCallback(
		(time: TimeInput.Value) => {
			if (disabled) return;

			setValue((prev) => ({
				...prev,
				time: time || new Date(),
			}));
		},
		[disabled, setValue],
	);
	const time = useMemo(() => value.time, [value.time]);
	return (
		<StyledColumn
			gap="10px"
			p="15px"
			bgC="#ffffff"
			h="calc(252px - 84px)"
			justify="space-around"
		>
			<StyledRow gap="2rem">
				<RadioButton.RadioButton
					selected={value.on === "now"}
					label={
						t([`orderPage.order_form.order_time.now`, "Not now"]) ??
						""
					}
					onClick={onNow}
				/>

				<RadioButton.RadioButton
					selected={value.on === "later"}
					label={
						t([
							`orderPage.order_form.order_time.later`,
							"Pre order",
						]) ?? ""
					}
					onClick={onLater}
				/>
			</StyledRow>

			<StyledColumn gap="1rem">
				<BaseDatePicker
					cursor={disabled ? "not-allowed" : "pointer"}
					disabled={disabled}
					locale={useDatePickerLocale()}
					minDate={new Date()}
					value={value.time}
					onChange={onChangeDatePicker}
				/>
				<StyledRow alignItems="center" gap="5px 10px">
					<StyledP>
						{t([`orderPage.order_form.time`, "Time"])}:
					</StyledP>

					<BaseTime
						id={BaseElementIdEnum.TIME_SELECT}
						cursor={disabled ? "not-allowed" : "pointer"}
						disabled={disabled}
						autoFocus
						step={60}
						value={time}
						onChange={onChangeTime}
					/>
				</StyledRow>
			</StyledColumn>
		</StyledColumn>
	);
};

declare namespace TimeSelect {
	type OrderTime = "now" | "later";

	interface ScheduledTime {
		on: OrderTime;
		time: Date;
	}

	interface Props extends BaseInputModal.InputModalContent<ScheduledTime> {}
}

export const TimeSelectMemo = memo(TimeSelect);
export default TimeSelect;
