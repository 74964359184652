import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import PushNotice, {
	NoticeDestinationApp,
} from "../../../../../services/PushNotice";
import { useTypedSelector } from "../../../../../redux/store";
import useModelSubscribe from "../../../../../hooks/useModelSubscribe2";
import mapByKey from "../../../../../utils/mapByKey";
import DeleteModal from "../../../../../components/DeleteModal";
import { SettingsTabProps } from "../../../../../components/Settings/tabProps";
import DefaultPageHeader from "../../../../../components/DefaultPageHeader";

import fromStringToJson from "./components/Modal/components/Content/components/TemplateBody/helpers/fromStringToJson";
import Filters from "./components/Filters";
import Root from "./components/Root";
import Modal from "./components/Modal";
import Content from "./components/Content";
import translationPath from "./constants/translationPath";
import defaultTemplate from "./constants/defaultTemplate";

const PushNotices: React.FC<SettingsTabProps> = () => {
	const { t } = useTranslation();
	const globalLang = useTypedSelector((state) => state.session.language);

	const [destinationAppFilter, setDestinationAppFilter] = useState([
		...Object.values(NoticeDestinationApp),
	]);

	const actualPushNotices = useModelSubscribe(
		{
			order: { action: "ASC" },
			destinationApps: destinationAppFilter,
		},
		PushNotice,
	);

	defaultTemplate.lang = globalLang;

	const [showModal, setShowModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showClarificationModal, setShowClarificationModal] = useState(false);

	const [pushNotices, setPushNotices] = useState<PushNotice.Model[] | []>([]);
	useEffect(() => {
		setPushNotices(actualPushNotices.models);
	}, [actualPushNotices.models]);

	const [preparedTemplate, setPreparedTemplate] =
		useState<Modal.PreparedTemplate>(null);

	const [editingItem, setEditingItem] =
		useState<Modal.Value>(defaultTemplate);

	const [selected, setSelected] = useState<number[]>([]);

	const modelItemById = useMemo(
		() => mapByKey(pushNotices, "id"),
		[pushNotices],
	);

	const edit = useCallback(
		(id: number) => {
			const item = modelItemById[id];

			setEditingItem({
				...item,
				templateText: fromStringToJson(item.templateText, t),
			});
			setShowModal(true);
			setSelected([]);
		},
		[modelItemById, t],
	);

	const editHeaderHandler = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const editContentHandler = useCallback(
		(template: PushNotice.Modified) => edit(template.id),
		[edit],
	);

	const copyHandler = useCallback(() => {
		PushNotice.copy(selected[0]);
	}, [selected]);

	const addHandler = useCallback(() => {
		setShowModal(true);
	}, []);

	const preDeleteHandler = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const deleteHandler = useCallback(() => {
		PushNotice.destroy(selected);
		setSelected([]);
		setShowDeleteModal(false);
	}, [selected]);

	const cancelHandler = useCallback(() => {
		setShowModal(false);
		setEditingItem(defaultTemplate);
	}, []);

	const saveHandler = useCallback(
		async (
			template: PushNotice.New | PushNotice.Modified,
			force = false,
		) => {
			let isAllOk = true;

			await template.bigPicture?.upload();
			await template.largeIcon?.upload();

			if ("id" in template) {
				isAllOk = await PushNotice.update(template, force);
			} else {
				isAllOk = await PushNotice.store(template, force);
			}
			if (isAllOk) {
				setShowModal(false);
				setEditingItem(defaultTemplate);
				setPreparedTemplate(null);
			} else {
				setPreparedTemplate(template);
				setShowClarificationModal(true);
			}
		},
		[],
	);

	const createdAt = editingItem.createdAt
		? new Date(editingItem.createdAt).toLocaleDateString()
		: new Date().toLocaleDateString();

	return (
		<Root sizes="auto! 1fr" gaps="16px" maxedWidth maxedHeight>
			<DefaultPageHeader
				canEdit={selected.length === 1}
				canDelete={
					!!selected.length &&
					!selected.some((id) => modelItemById[id].isDefault)
				}
				onAdd={addHandler}
				onDelete={preDeleteHandler}
				onEdit={editHeaderHandler}
				afterAdditionalButtons={[
					{
						icon: { id: "copy", size: 20 },
						onClick: copyHandler,
						disabled: !(selected.length === 1),
					},
				]}
				filters={
					<Filters
						destinationAppFilter={destinationAppFilter}
						setDestinationAppFilter={setDestinationAppFilter}
					/>
				}
			/>
			<Content
				value={pushNotices}
				lang={globalLang}
				selected={selected}
				setSelected={setSelected}
				onEdit={editContentHandler}
				saveHandler={saveHandler}
			/>
			{showModal && (
				<Modal
					lang={globalLang}
					onCancel={cancelHandler}
					onSave={saveHandler}
					value={editingItem}
					headerTitle={
						editingItem?.id
							? `${t(`${translationPath}.modal.title.edit`)} "${
									editingItem.name.length > 20
										? `${editingItem.name.slice(0, 20)}...`
										: editingItem.name
							  }"`
							: t(`${translationPath}.modal.title.add`)
					}
					createdAt={createdAt}
					showClarificationModal={showClarificationModal}
					setShowClarificationModal={setShowClarificationModal}
					preparedTemplate={preparedTemplate}
					setPreparedTemplate={setPreparedTemplate}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					label={
						selected.length === 1
							? t(`${translationPath}.deleteModal.title`) || ""
							: t(`${translationPath}.deleteModal.title2`) || ""
					}
					onCancel={() => {
						setShowDeleteModal(false);
						setSelected([]);
					}}
					onConfirm={deleteHandler}
				/>
			)}
		</Root>
	);
};

export default PushNotices;
