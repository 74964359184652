import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ORDERS_TYPES } from "../../../../../../../../../redux/constants/OrdersPage";
import { useTypedSelector } from "../../../../../../../../../redux/store";
import TabLabel from "../components/TabLabel";

function useTabOptions() {
	const orders = useTypedSelector((state) => state.ordersPageReducer.orders);
	const { t } = useTranslation();

	const tabOptions = useMemo(
		() => [
			{
				key: ORDERS_TYPES.LIVE,
				value: ORDERS_TYPES.LIVE,
				label: (
					<TabLabel
						label={
							t(
								"pages.mainPage.pages.orders.orderHeader.firstRow.hooks.str0",
							) ?? ""
						}
						count={orders[ORDERS_TYPES.LIVE]?.pagination.count ?? 0}
					/>
				),
			},
			{
				key: ORDERS_TYPES.PRELIMINARY,
				value: ORDERS_TYPES.PRELIMINARY,
				label: (
					<TabLabel
						label={
							t(
								"pages.mainPage.pages.orders.orderHeader.firstRow.hooks.str1",
							) ?? ""
						}
						count={
							orders[ORDERS_TYPES.PRELIMINARY]?.pagination
								.count ?? 0
						}
					/>
				),
			},
			{
				key: ORDERS_TYPES.EXECUTING,
				value: ORDERS_TYPES.EXECUTING,
				label: (
					<TabLabel
						label={
							t(
								"pages.mainPage.pages.orders.orderHeader.firstRow.hooks.str2",
							) ?? ""
						}
						count={
							orders[ORDERS_TYPES.EXECUTING]?.pagination.count ??
							0
						}
					/>
				),
			},
			{
				key: ORDERS_TYPES.ALL,
				value: ORDERS_TYPES.ALL,
				label: (
					<TabLabel
						label={
							t(
								"pages.mainPage.pages.orders.orderHeader.firstRow.hooks.str3",
							) ?? ""
						}
						count={orders[ORDERS_TYPES.ALL]?.pagination.count ?? 0}
					/>
				),
			},
			{
				key: ORDERS_TYPES.CLOSED,
				value: ORDERS_TYPES.CLOSED,
				label: (
					<TabLabel
						label={
							t(
								"pages.mainPage.pages.orders.orderHeader.firstRow.hooks.str4",
							) ?? ""
						}
						count={
							orders[ORDERS_TYPES.CLOSED]?.pagination.count ?? 0
						}
					/>
				),
			},
		],
		[orders, t],
	);

	return tabOptions;
}

export default useTabOptions;
