import * as ModelEvent from "@node-elion/syncron";

import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Subscription from "../../types/Subscription";
import Base from "../Base";
import File from "../File";
import {
	NonEditableProperties,
	NonEditablePropertyNames,
} from "../../types/NonEditableProperties";
import Accounts from "../../pages/MainPage/pages/Customers/tabs/Counterparties/components/Modal/components/Content/tabs/Accounts";
import Main from "../../pages/MainPage/pages/Customers/tabs/Counterparties/components/Modal/components/Content/tabs/Main";
import Employee from "../Employee";
import Company from "../Company";
import TaxiService from "../TaxiService";
import { PaymentAccount } from "../../types/PaymentAccount";
import SubscriptionPool from "../../redux/services/SubscriptionPool";
import createRPCQuery from "../../utils/createRPCQuery.util";
import CounterpartyGroup from "../CounterpartyGroup";
import stringToUndefined from "../../utils/stringToUndefined";
import { Language } from "../../assets/languages/langs";

import checkToRequest from "./checkToRequest";

class Counterparty extends Base {
	public static fromResponse(data: any): Counterparty.Model {
		const paymentAccounts: PaymentAccount[] = [...data.paymentAccounts];

		if (data.paymentAccounts) {
			paymentAccounts.push({
				amount: data.additionalFields?.deactivationThreshold?.value,
				description:
					data.additionalFields?.deactivationThreshold?.description,
				type: "threshold",
				configuration: null,
				createdAt: "",
				id: 0,
				deletedAt: null,
				isProvider: false,
				updatedAt: "",
				active: true,
			});
		}

		const res = {
			id: data.id,
			active: data.active,
			personalFiles: data.personalFiles?.map(File.fromResponse),
			otherFiles: data.otherFiles?.map(File.fromResponse),
			additionalFields: data.additionalFields,
			checks: data.checks.map((check) => {
				const checkPaymentAccounts: PaymentAccount[] =
					check.paymentAccounts;
				if (check.paymentAccounts) {
					checkPaymentAccounts.push({
						amount: check.additionalFields?.deactivationThreshold
							?.value,
						description:
							check.additionalFields?.deactivationThreshold
								?.description,
						type: "threshold",
						configuration: null,
						createdAt: "",
						id: 0,
						deletedAt: null,
						isProvider: false,
						updatedAt: "",
						active: true,
					});
				}

				return {
					...check,
					checkId: check.id,
					ownerId: data.id,
					corporatePassword: check.corporatePassword && "********",
					paymentAccounts: checkPaymentAccounts || [],
					rideCounter: {
						total: 0,
						failed: 0,
						success: 0,
						cancelled: 0,
						...(check.rideCounter ?? {}),
					},
					otherFiles: check.otherFiles.map(File.fromResponse),
					personalFiles: check.personalFiles.map(File.fromResponse),
					checkCards: check.checkCards.map((card) => ({
						...card,
						cardId: card.id,
						checkId: check.id,
						ownerId: data.id,
						corporatePassword: card.corporatePassword && "********",
						employees: card.employees.map(Employee.fromResponse),
						rideCounter: {
							total: 0,
							failed: 0,
							success: 0,
							cancelled: 0,
							...(card?.rideCounter ?? {}),
						},
						otherFiles: card.otherFiles.map(File.fromResponse),
						personalFiles: card.personalFiles.map(
							File.fromResponse,
						),
					})),
				};
			}),
			accessAllEmployeeToPersonalApp: data.accessAllEmployeeToPersonalApp,
			isAccessToCorporateAccount: data.isAccessToCorporateAccount,
			allowGeneralBonusBalance: data.allowGeneralBonusBalance,
			ignoreGeneralThresholdBalance: data.ignoreGeneralThresholdBalance,
			addCustomerAsEmployeeAfterOrder:
				data.addCustomerAsEmployeeAfterOrder,
			corporateLogin: data.corporateLogin,
			corporatePassword: data.corporatePassword && "********",
			company: data.company,
			companyId: data.company.id,
			defaultTaxiService: data.defaultTaxiService,
			defaultTaxiServiceId: data.defaultTaxiService.id,
			latestTaxiServiceId: data.latestTaxiServiceId,
			paymentAccounts,
			counterpartyGroup:
				data.counterpartyGroup &&
				CounterpartyGroup.fromResponse(data.counterpartyGroup),
			counterpartyGroupId: data.counterpartyGroup?.id,
			rideCounter: {
				total: 0,
				failed: 0,
				success: 0,
				cancelled: 0,
				...(data?.rideCounter ?? {}),
			},

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
		};

		return res;
	}

	public static toRequest(model: Counterparty.New | Counterparty.Modified) {
		const payload: Record<string, any> = {
			active: model.active,
			isAccessToCorporateAccount: model.isAccessToCorporateAccount,
			allowGeneralBonusBalance: model.allowGeneralBonusBalance,
			ignoreGeneralThresholdBalance: model.ignoreGeneralThresholdBalance,
			accessAllEmployeeToPersonalApp:
				model.accessAllEmployeeToPersonalApp,
			addCustomerAsEmployeeAfterOrder:
				model.addCustomerAsEmployeeAfterOrder,
			personalFileIds: model.personalFiles?.map(({ id }) => id) ?? [],
			otherFileIds: model.otherFiles?.map(({ id }) => id) ?? [],
			additionalFields: {
				address: stringToUndefined(model.additionalFields?.address),
				contactName: stringToUndefined(
					model.additionalFields?.contactName,
				),
				edrpou: stringToUndefined(model.additionalFields?.edrpou),
				email: stringToUndefined(model.additionalFields?.email),
				name: stringToUndefined(model.additionalFields?.name),
				notes: stringToUndefined(model.additionalFields?.notes),
				orderNotes: stringToUndefined(
					model.additionalFields?.orderNotes,
				),
				executorNotes: stringToUndefined(
					model.additionalFields?.executorNotes,
				),
				deactivationThreshold: {
					description: stringToUndefined(
						model.additionalFields?.deactivationThreshold
							.description,
					),
					value: model.additionalFields?.deactivationThreshold.value,
				},
				phones: model.additionalFields?.phones.filter(
					(phone) => !!stringToUndefined(phone),
				),
			},
			counterpartyGroupId: model.counterpartyGroupId,
			checks: model.checks?.map(checkToRequest),
			corporateLogin: model.corporateLogin ?? undefined,
			corporatePassword: model.corporatePassword ?? undefined,
			companyId: model.companyId || undefined,
			defaultTaxiServiceId: model.defaultTaxiServiceId || undefined,
			// latestTaxiServiceId: model.latestTaxiServiceId,
		};

		if (model.paymentTransactions) {
			const threshold = model?.paymentTransactions.threshold?.at(-1);
			const totalAmount = threshold?.amount || 0;
			const description = threshold?.description || "";

			const deactivationThreshold = {
				value: totalAmount,
				description: stringToUndefined(description),
			};

			payload.additionalFields.deactivationThreshold =
				deactivationThreshold;
		}
		if (!("id" in model)) {
			payload.paymentTransactions = model.paymentTransactions;
			delete payload?.paymentTransactions?.threshold;
		}

		if (
			model.paymentAccounts?.some(
				(payment) => payment.type === "threshold",
			)
		) {
			const threshold = model?.paymentAccounts
				?.filter((payment) => payment.type === "threshold")
				?.at(-1);

			payload.additionalFields.deactivationThreshold.description =
				stringToUndefined(threshold?.description || "");

			payload.additionalFields.deactivationThreshold.value =
				threshold?.amount || 0;
		}

		console.log("[Counterparty] toRequest", { payload, model });
		return payload;
	}

	public static async store(object: Counterparty.New) {
		try {
			console.log("[Counterparty] store", object);
			const res = await this.request((prpc) =>
				prpc.theirsModel.counterparty.create(
					Counterparty.toRequest(object),
				),
			);

			return !!res;
		} catch (err: any) {
			console.error("[Counterparty] create  error", err);
			return false;
		}
	}

	public static async generateOneTimePassword() {
		try {
			return await this.request((prpc) =>
				prpc.theirsModel.counterparty.generateOneTimePassword(),
			);
		} catch (err: any) {
			console.error("generateOneTimePassword error", err);
			return false;
		}
	}

	public static async update(object: Counterparty.Modified) {
		try {
			console.log("[Counterparty] update", object);
			await this.request((prpc) =>
				prpc.theirsModel.counterparty.update(
					object.id,
					Counterparty.toRequest(object),
				),
			);

			return true;
		} catch (err: any) {
			console.error("[Counterparty] update error", err);
			return false;
		}
	}

	public static async getThresholdStatus(
		params: Counterparty.GetThresholdStatus,
	) {
		try {
			return this.request((prpc) =>
				prpc.theirsModel.counterparty.getThresholdStatus(params),
			);
		} catch (error) {
			console.error("[Counterparty] getThresholdStatus error", error);
			return null;
		}
	}

	public static async getCodeInfo(str: string) {
		try {
			return this.request((prpc) =>
				prpc.theirsModel.code.getCodeInfo(str),
			);
		} catch (error) {
			console.error("[Counterparty] getCodeInfo error", error);
			return null;
		}
	}

	public static async delete(id: number[] | number) {
		this.request((prpc) => prpc.theirsModel.counterparty.delete(id));
	}

	public static async subscribe(
		options: Counterparty.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<Counterparty.Model>,
	): Promise<Subscription<Counterparty.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				console.log("[Counterparty] subscribe", { state });

				onUpdate({
					...state,
					models: state.models.map(this.fromResponse),
				});
			},
		});

		const subscription = await SubscriptionPool.add(
			(prpc) =>
				createRPCQuery(() =>
					prpc.theirsModel.counterparty.subscribe({
						params: this.optionsToRequest(options),
						ping: () => true,
						onEvent: (events) => {
							modelEventConstructor.onEvent(events);
						},
						onError: (error) => {
							console.error(error);
						},
					}),
				),
			{ name: "Counterparty.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: Counterparty.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as Subscription<Counterparty.SubscribeOptions>;
	}

	private static optionsToRequest(options: Counterparty.SubscribeOptions) {
		const payload: Counterparty.SubscribeOptions = {
			lang: options.lang,
			active: options.active,
			query: options.query,
			limit: options.limit,
			offset: options.offset,
			order: options.order,
		};
		if (typeof options.withDeleted === "boolean") {
			payload.withDeleted = options.withDeleted;
		}
		console.log("[Counterparty] subscribe option", { payload, options });
		return payload;
	}
}

declare namespace Counterparty {
	interface Model extends NonEditableProperties {
		active: boolean;
		personalFiles: File.Model[];
		otherFiles: File.Model[];
		additionalFields: Main.AdditionalFields;
		isAccessToCorporateAccount: boolean;
		addCustomerAsEmployeeAfterOrder: boolean;
		accessAllEmployeeToPersonalApp: boolean;
		allowGeneralBonusBalance: boolean;
		ignoreGeneralThresholdBalance: boolean;
		paymentAccounts: PaymentAccount[];
		checks: Accounts.Check[];
		corporateLogin?: string;
		corporatePassword?: string;
		company?: Company.Model;
		companyId: number;
		defaultTaxiService?: TaxiService.Model;
		defaultTaxiServiceId: number;
		latestTaxiServiceId?: number;
		counterpartyGroup: CounterpartyGroup.Model;
		counterpartyGroupId: number | undefined;
		rideCounter?: {
			total: number;
		};
	}

	type New = Omit<Model, NonEditablePropertyNames> & {
		paymentTransactions: {
			main: { amount: number; description: string }[];
			bonus: { amount: number; description: string }[];
			threshold: { amount: number; description: string }[];
		};
	};
	type Modified = Partial<New> & NonEditableProperties;

	interface SubscribeOptions
		extends ServiceSubscribeOptionsBase<Counterparty.Model> {
		lang?: Language;
		active?: boolean;
		query?: string;
		withDeleted?: boolean;
	}
	interface GetThresholdStatus {
		orderAmount: number;
		credentials:
			| {
					customerId: number;
					counterpartyId: number;
			  }
			| {
					employeeId: number;
			  };
	}
}
export enum CounterpartyStatus {
	ACTIVE = "active",
	BLACKLIST_BAN = "blacklist_ban",
	BLACKLIST_WARNING = "blacklist_warning",
	BLOCKED = "blocked",
}

export default Counterparty;
