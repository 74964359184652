import makeTable from "./makeTable";

const role = makeTable({
	allIds: ["active", "name", "assigns", "taxiServices", "createdAt"],
	defaultIds: ["active", "name", "assigns", "taxiServices", "createdAt"],
	initialWidths: {
		active: 90,
		name: 781,
		assigns: 120,
		taxiServices: 390,
		createdAt: 150,
	},
	i18tPath: (id) => `tableColumns.dispatcher.${id}`,
});

export default role;
