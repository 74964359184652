import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { clone, defaults } from "lodash";
import {
	ColorEntry,
	DataKind,
	EntryGroup,
	EntryList,
} from "../../../types/settingEntries";
import Order from "../../../services/Order";

type OrderSourceColorEntries<Data extends DataKind> = {
	[P in Order.Source]: ColorEntry<Data>;
};

type OrderPaymentTypeColorEntries<Data extends DataKind> = {
	[P in Order.PaymentType]: ColorEntry<Data>;
};

export interface BaseColorEntries<Data extends DataKind>
	extends OrderSourceColorEntries<Data>,
		OrderPaymentTypeColorEntries<Data> {
	activeOrder: ColorEntry<Data>;

	color1: ColorEntry<Data>;
	color2: ColorEntry<Data>;
	color3: ColorEntry<Data>;
	color4: ColorEntry<Data>;
	color5: ColorEntry<Data>;
	color6: ColorEntry<Data>;
	color7: ColorEntry<Data>;
	color8: ColorEntry<Data>;
}
export const colorGroups: EntryGroup<
	BaseColorEntries<any>,
	"settings.interface.colors.groups."
>[] = [
	{
		title: [],
		entryKeys: ["activeOrder"],
	},
	{
		title: ["settings.interface.colors.groups.orderSourceColors"],
		entryKeys: Object.values(Order.Source),
	},
	{
		title: ["settings.interface.colors.groups.orderPaymentTypeColors"],
		entryKeys: Object.values(Order.PaymentType),
	},
	{
		title: ["settings.interface.colors.groups.orderColors"],
		entryKeys: [
			"color1",
			"color2",
			"color3",
			"color4",
			"color5",
			"color6",
			"color7",
			"color8",
		],
	},
];

export type ColorEntries<Data extends DataKind> = BaseColorEntries<Data> &
	EntryList<DataKind, never, never>;
export type ColorMeta = ColorEntries<"meta">;
export type ColorState = ColorEntries<"value">;
export type ColorValue = ColorEntries<any>[keyof ColorEntries<any>];

const colorPicker: ColorEntry<"meta"> = {
	type: "color",
};

export const colorMeta: ColorMeta = {
	activeOrder: colorPicker,

	app: colorPicker,
	phone: colorPicker,
	website: colorPicker,
	executor: colorPicker,
	dispatcher: colorPicker,

	card: colorPicker,
	cash: colorPicker,
	invoice: colorPicker,
	company_bill: colorPicker,

	color1: colorPicker,
	color2: colorPicker,
	color3: colorPicker,
	color4: colorPicker,
	color5: colorPicker,
	color6: colorPicker,
	color7: colorPicker,
	color8: colorPicker,
};

const initialState: ColorState = {
	activeOrder: "#0080ff7f",

	app: "#D2D2D2",
	phone: "#FFFFFF",
	website: "#D2D2D2",
	executor: "#FFFFFF",
	dispatcher: "#FFFFFF",

	card: "#FFFFFF",
	cash: "#FFFFFF",
	invoice: "#FFFFFF",
	company_bill: "#FEFF00",

	color1: "#F83528",
	color2: "#4CAF50",
	color3: "#F2BD00",
	color4: "#609BEB",
	color5: "#FF9500",
	color6: "#F69993",
	color7: "#8000FF",
	color8: "#00BAD4",
};

type Reducer<P> = CaseReducer<ColorState, PayloadAction<P>>;

const setAll: Reducer<Partial<ColorState>> = (state, { payload }) =>
	defaults(clone(payload), state, initialState);
const resetDefaults: Reducer<void> = () => initialState;

const colors = createSlice({
	name: "colors",
	initialState,
	reducers: {
		setAll,
		resetDefaults,
	},
});

export default colors;
