import React, { Dispatch, memo, useCallback, useMemo } from "react";
import {
	Button,
	CheckBox,
	Column,
	Icon,
	InputGroup,
	Row,
	TextBox,
	theme,
} from "uikit";
import { clamp, clone } from "lodash";
import { useTranslation } from "react-i18next";

import useObjectEditor from "../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import { NonEditableProperties } from "../../../../../../../../../../../../../../../../../types/NonEditableProperties";
import tPath from "../../../../../../../../constants/tPath";

const Phones = memo<Phones.Props>(
	({
		value,
		disabled,
		min = 1,
		max = Number.MAX_SAFE_INTEGER,
		onChange,
		errors,
		indexPhones,
	}) => {
		const { t } = useTranslation();

		const internalValue = useMemo(
			() =>
				value?.length < min
					? [
							{
								value: "",
								group: 0,
								isCounterparty: false,
							},
					  ]
					: clone(value)?.slice(0, max),
			[max, min, value],
		);

		const valueEditor = useObjectEditor(internalValue, onChange);

		const addPhone = useCallback(() => {
			valueEditor.push({ value: "", group: 1, isCounterparty: false });
		}, [valueEditor]);

		const renderField = useCallback(
			(index: number) => {
				const isFirstItem = index === 0;
				const isLastItem = index === valueEditor.value.length - 1;
				const isMaxedItem = index === (max ?? 0) - 1;
				const hasMoreThanOneItem = valueEditor.value.length > 1;

				const label = isFirstItem
					? t(`${tPath}.modal.tabs.main.phones.main`)
					: t(`${tPath}.modal.tabs.main.phones.additional`);

				let sizes = "";

				if (isFirstItem && !hasMoreThanOneItem) sizes = "1fr 32px";
				else if (!isLastItem || (isLastItem && isMaxedItem))
					sizes = "1fr";
				else if (isLastItem) sizes = "1ft 32px";

				return (
					<Column gaps="7px*" key={index}>
						<Row gaps="13px*" align="center">
							<>
								<span style={{ whiteSpace: "nowrap" }}>
									{label}
								</span>
								<CheckBox
									label={
										t(
											`${tPath}.modal.tabs.main.phones.auto`,
										) || ""
									}
									value={
										valueEditor.get(index)
											?.isCounterparty ?? false
									}
									onChange={(newIsCounterparty) => {
										valueEditor.set(index, {
											...valueEditor.get(index),
											isCounterparty: newIsCounterparty,
										});
									}}
								/>
							</>
						</Row>
						<Row gaps="8px*" sizes={sizes}>
							{!isFirstItem ? (
								<InputGroup.InputGroup
									sizes="1fr 32px!"
									error={
										errors[index] ||
										indexPhones?.includes(index)
									}
								>
									<TextBox.TextBox
										value={
											valueEditor.get(index)?.value ?? ""
										}
										disabled={disabled}
										autoComplete="one-time-code"
										type="phone"
										style={{
											minHeight: "32px",
											flex: "1 0 0",
										}}
										placeholder={label}
										onChange={(newPhone) =>
											valueEditor.set(index, {
												...valueEditor.get(index),
												value: newPhone,
											})
										}
									/>
									<Button.Button
										disabled={disabled}
										icon={
											<Icon
												id="minus"
												size={16}
												colors={[theme.colors.white]}
											/>
										}
										style={{
											borderTopLeftRadius: 0,
											borderBottomLeftRadius: 0,
										}}
										onClick={() =>
											valueEditor.remove(index)
										}
									/>
								</InputGroup.InputGroup>
							) : (
								<TextBox.TextBox
									value={valueEditor.get(index)?.value ?? ""}
									disabled={disabled}
									autoComplete="one-time-code"
									error={
										errors[index] ||
										indexPhones?.includes(index)
									}
									type="phone"
									style={{
										minHeight: "32px",
										flex: "1 0 0",
									}}
									placeholder={label}
									onChange={(newPhone) =>
										valueEditor.set(index, {
											...valueEditor.get(index),
											value: newPhone,
										})
									}
								/>
							)}
							{isLastItem && !isMaxedItem && (
								<Button.Button
									disabled={disabled}
									icon={
										<Icon
											id="plus"
											size={16}
											colors={[theme.colors.white]}
										/>
									}
									onClick={addPhone}
								/>
							)}
						</Row>
					</Column>
				);
			},
			[valueEditor, max, t, errors, indexPhones, disabled, addPhone],
		);

		return (
			<>
				{Array(clamp(valueEditor.value.length, min, max))
					.fill(null)
					.map((_, index) => renderField(index))}
			</>
		);
	},
);

declare namespace Phones {
	type Value = ({
		value: string;
		group: number;
		isCounterparty: boolean;
		id?: string | number;
	} & Partial<Omit<NonEditableProperties, "id">>)[];

	interface Props {
		errors: boolean[];
		value: Value;
		min?: number;
		max?: number;
		onChange: Dispatch<Value>;
		disabled: boolean;
		indexPhones?: number[];
	}
}

export default Phones;
