import React, { useMemo } from "react";
import { Tabs } from "uikit";
import { useTranslation } from "react-i18next";

import useActiveTab from "../../../../hooks/useActiveTab";
import { useTypedSelector } from "../../../../redux/store";
import { hasAccess, generateAccessName, AccessKey } from "../../../../access";
import { TabOptions } from "../../../../types";
import BasicPageLayout from "../../../../components/BasicPageLayout";

import {
	CustomerPenalties,
	CustomerTariffPlans,
	ExecutorPenalties,
	ExecutorTariffPlans2,
	UserPenalties,
	UserTariffPlans,
} from "./tabs";

const Finances: React.FC = () => {
	const [ACCESS_SECTION, ACCESS_SECTION_2] = [
		AccessKey.SETTINGS,
		AccessKey.SETTINGS_FINANCES,
	];
	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const tabs: TabOptions.Array = useMemo(
		() =>
			[
				// {
				// 	key: "executorTariffPlans",
				// 	label: t("settings.finances.executorTariffPlans.title"),
				// 	value: {
				// 		render: () => <ExecutorTariffPlans />,
				// 	},
				// accessName: generateAccessName(
				// 	ACCESS_SECTION,
				// 	ACCESS_SECTION_2,
				// 	AccessKey.EXECUTOR_TARIFF_PLANS,
				// ),
				// },
				{
					key: "executorTariffPlans2",
					label: t("settings.finances.executorTariffPlans.title"),
					value: {
						render: () => <ExecutorTariffPlans2 />,
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						ACCESS_SECTION_2,
						AccessKey.EXECUTOR_TARIFF_PLANS_2,
					),
				},
				{
					key: "customerTariffPlans",
					label: t("settings.finances.customerTariffPlans.title"),
					value: {
						render: () => <CustomerTariffPlans />,
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						ACCESS_SECTION_2,
						AccessKey.CUSTOMER_TARIFF_PLANS,
					),
				},
				{
					key: "userTariffPlans",
					label: t("settings.finances.userTariffPlans.title"),
					value: {
						render: () => <UserTariffPlans />,
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						ACCESS_SECTION_2,
						AccessKey.USER_TARIFF_PLANS,
					),
				},
				{
					key: "executorPenalties",
					label: t("settings.finances.executorPenalties.title"),
					value: {
						render: () => <ExecutorPenalties />,
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						ACCESS_SECTION_2,
						AccessKey.EXECUTOR_PENALTIES,
					),
				},
				{
					key: "customerPenalties",
					label: t("settings.finances.customerPenalties.title"),
					value: {
						render: () => <CustomerPenalties />,
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						ACCESS_SECTION_2,
						AccessKey.CUSTOMER_PENALTIES,
					),
				},
				{
					key: "userPenalties",
					label: t("settings.finances.userPenalties.title"),
					value: {
						render: () => <UserPenalties />,
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						ACCESS_SECTION_2,
						AccessKey.USER_PENALTIES,
					),
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, ACCESS_SECTION, ACCESS_SECTION_2, personalRole],
	);

	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	return (
		<BasicPageLayout
			navigation={
				<Tabs
					value={activeKey}
					onChange={setActiveKey}
					options={tabs}
					variant="vertical"
				/>
			}
			content={activeTab.value.render()}
		/>
	);
};

export default Finances;
