import React, { useCallback, useMemo } from "react";
import { isUndefined } from "lodash";

import Order from "../../../../../../../services/Order";
import { RoundingMethod } from "../../../../../../../redux/constants/OrdersPage/order";
import CellContentRoot from "../CellContentRoot";

const OrderPriceCellContent: React.FC<OrderPriceCellContent.Props> = ({
	item,
}) => {
	const roundingMultipleValue = useMemo(
		() => item.cost?.settings?.rounding?.multiple?.value ?? 0,
		[item.cost?.settings?.rounding?.multiple?.value],
	);
	const roundingMultipleActive = useMemo(
		() => item.cost?.settings?.rounding?.multiple?.active ?? false,
		[item.cost?.settings?.rounding?.multiple?.active],
	);
	const roundingMethod = useMemo(
		() => item.cost?.settings?.rounding?.method ?? RoundingMethod.GENERAL,
		[item.cost?.settings?.rounding?.method],
	);
	const precision = useMemo(
		() => item.cost?.settings?.rounding?.precision ?? 2,
		[item.cost?.settings?.rounding?.precision],
	);

	const price = useMemo(() => item?.cost?.value ?? 0, [item]);

	const stringifyPrice = useCallback(
		(price: number | undefined) => {
			if (isUndefined(price)) return "-";

			let processedPrice = price;

			if (roundingMultipleActive) {
				processedPrice /= roundingMultipleValue;
			} else {
				processedPrice *= 10 ** precision;
			}

			switch (roundingMethod) {
				case RoundingMethod.GENERAL:
					processedPrice = Math.round(processedPrice);
					break;
				case RoundingMethod.CEIL:
					processedPrice = Math.ceil(processedPrice);
					break;
				case RoundingMethod.FLOOR:
					processedPrice = Math.floor(processedPrice);
					break;
				default:
					break;
			}

			if (roundingMultipleActive) {
				processedPrice *= roundingMultipleValue;
			} else {
				processedPrice /= 10 ** precision;
			}

			return `${processedPrice.toFixed(precision)}`.trim();
		},
		[
			precision,
			roundingMethod,
			roundingMultipleActive,
			roundingMultipleValue,
		],
	);

	const currency = useMemo(
		() => item?.cost?.currency?.settings?.name ?? "",
		[item],
	);

	const watchedPrice = useMemo(
		() => `${stringifyPrice(price)} ${currency}`,
		[currency, price, stringifyPrice],
	);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{watchedPrice}
		</CellContentRoot>
	);
};

declare namespace OrderPriceCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default OrderPriceCellContent;
