import React, { useMemo } from "react";
import { Tabs } from "uikit";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../../../../redux/store";
import { hasAccess, generateAccessName, AccessKey } from "../../../../access";
import { TabOptions } from "../../../../types";
import useActiveTab from "../../../../hooks/useActiveTab";
import BasicPageLayout from "../../../../components/BasicPageLayout";

import {
	CounterpartyGroups,
	AgentGroups,
	ClientGroups,
	Agents,
	Counterparties,
	Clients as ClientsTab,
} from "./tabs";

const Clients: React.FC = () => {
	const ACCESS_SECTION = AccessKey.CUSTOMERS;
	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const tabs: TabOptions.Array = useMemo(
		() =>
			[
				{
					key: "clients",
					label: t("mainPage.customers.clients.title"),
					value: {
						render: () => <ClientsTab />,
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.CLIENTS,
					),
				},
				{
					key: "counterparties",
					label: t("mainPage.customers.counterparties.title"),
					value: {
						render: () => <Counterparties />,
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.COUNTERPARTIES,
					),
				},
				{
					key: "agents",
					label: t("mainPage.customers.agents.title"),
					value: {
						render: () => <Agents />,
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.AGENTS,
					),
				},
				{
					key: "clientGroups",
					label: t("mainPage.customers.clientGroups.title"),
					value: {
						render: () => <ClientGroups />,
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.CLIENT_GROUPS,
					),
				},
				{
					key: "counterpartyGroups",
					label: t("mainPage.customers.counterpartyGroups.title"),
					value: {
						render: () => <CounterpartyGroups />,
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.COUNTERPARTY_GROUPS,
					),
				},
				{
					key: "agentGroups",
					label: t("mainPage.customers.agentGroups.title"),
					value: { render: () => <AgentGroups /> },
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.AGENT_GROUPS,
					),
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, ACCESS_SECTION, personalRole],
	);

	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	return (
		<BasicPageLayout
			navigation={
				<Tabs
					value={activeKey}
					onChange={(newActive) => {
						setActiveKey(newActive);
					}}
					options={tabs}
					variant="vertical"
				/>
			}
			content={activeTab.value.render()}
		/>
	);
};

export default Clients;
