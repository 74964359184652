import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import CounterpartyGroup from "../../../../../../services/CounterpartyGroup";
import Counterparty from "../../../../../../services/Counterparty";
import useModelSubscribe2 from "../../../../../../hooks/useModelSubscribe2";
import mapByKey from "../../../../../../utils/mapByKey";
import DefaultPageHeader from "../../../../../../components/DefaultPageHeader";
import DeleteModal from "../../../../../../components/DeleteModal";

import Root from "./components/Root";
import Content from "./components/Content";
import Modal from "./components/Modal";
import defaultValue from "./constants/defaultValue";
import tPath from "./constants/tPath";
import { useTableOptions } from "../../../../../../components/LightTable";

const CounterpartyGroups: React.FC = () => {
	const { t } = useTranslation();

	const { editor, onChange, limit, sort, query, setDataLength, dataLength } =
		useTableOptions();

	const {
		lang,
		limit: limitCounterparty,
		sort: sortCounterparty,
		editor: editorCounterparty,
		onChange: onChangeCounterparty,
		query: queryCounterparty,
	} = useTableOptions();

	const optionsCounterpartyGroup = useMemo(() => {
		const order = {};
		if (sort.dataKey) order[sort.dataKey] = sort.sortType;
		const payload: CounterpartyGroup.SubscribeOptions = {
			limit,
			order,
			query,
		};
		return payload;
	}, [limit, sort.dataKey, sort.sortType, query]);

	const { models } = useModelSubscribe2(
		optionsCounterpartyGroup,
		CounterpartyGroup,
	);

	const optionsCounterparty = useMemo(() => {
		const order = {};
		if (sortCounterparty.dataKey) {
			order[sortCounterparty.dataKey] = sortCounterparty.sortType;
		}

		const payload: Counterparty.SubscribeOptions = {
			limit: limitCounterparty,
			lang,
			order,
			query: queryCounterparty,
			active: true,
		};

		return payload;
	}, [
		lang,
		limitCounterparty,
		sortCounterparty.dataKey,
		sortCounterparty.sortType,
		queryCounterparty,
	]);

	const { models: counterparties } = useModelSubscribe2(
		optionsCounterparty,
		Counterparty,
	);

	useLayoutEffect(() => {
		if (dataLength !== models.length) {
			setDataLength(models.length);
		}
	}, [models.length, dataLength, setDataLength]);

	const [showModal, setShowModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const [selected, setSelected] = useState<number[]>([]);

	const [editingItem, setEditingItem] = useState<Modal.Value>(defaultValue);

	const modelItemById = useMemo(() => mapByKey(models, "id"), [models]);
	const counterpartyById = useMemo(
		() => mapByKey(counterparties, "id"),
		[counterparties],
	);

	const edit = useCallback(
		(id: number) => {
			const item = modelItemById[id];
			setEditingItem(item);

			setShowModal(true);
			setSelected([]);
		},
		[modelItemById],
	);

	const editHeaderHandler = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const editContentHandler = useCallback(
		(model: CounterpartyGroup.Modified) => edit(model.id),
		[edit],
	);

	const addHandler = useCallback(() => {
		setEditingItem(defaultValue);
		setShowModal(true);
	}, []);

	const preDeleteHandler = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const deleteHandler = useCallback(async () => {
		await CounterpartyGroup.delete(selected);
		setSelected([]);
		setShowDeleteModal(false);
	}, [selected]);

	const cancelHandler = useCallback(() => {
		setShowModal(false);
		setEditingItem(defaultValue);
	}, []);

	const cancelDeleteHandler = useCallback(() => {
		setShowDeleteModal(false);
		setSelected([]);
	}, []);

	const saveHandler = useCallback(
		async (model: CounterpartyGroup.New | CounterpartyGroup.Modified) => {
			let isAllOk = true;

			if ("id" in model && model.id) {
				isAllOk = await CounterpartyGroup.update(model);
			} else {
				isAllOk = await CounterpartyGroup.store(
					model as CounterpartyGroup.New,
				);
			}

			if (isAllOk) {
				setShowModal(false);
				setEditingItem(defaultValue);
			}
		},
		[],
	);

	return (
		<Root sizes="auto! 1fr" gaps="16px" maxedWidth maxedHeight>
			<DefaultPageHeader
				canEdit={selected.length === 1}
				canDelete={!!selected.length}
				onAdd={addHandler}
				onDelete={preDeleteHandler}
				onEdit={editHeaderHandler}
			/>
			<Content
				value={models}
				selected={selected}
				setSelected={setSelected}
				onEdit={editContentHandler}
				editorTable={editor}
				onChangeTable={onChange}
			/>
			{showModal && (
				<Modal
					counterparties={counterparties}
					counterpartyById={counterpartyById}
					value={editingItem}
					onCancel={cancelHandler}
					onSave={saveHandler}
					editorTable={editorCounterparty}
					onChangeTable={onChangeCounterparty}
					headerTitle={
						editingItem.id
							? editingItem.name
							: t(`${tPath}.modal.title`)
					}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					label={t(`${tPath}.deleteModal.title`) || ""}
					onCancel={cancelDeleteHandler}
					onConfirm={deleteHandler}
				/>
			)}
		</Root>
	);
};

export default CounterpartyGroups;
