import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tabs } from "uikit";

import { useTypedSelector } from "../../../../redux/store";
import useActiveTab from "../../../../hooks/useActiveTab";
import { hasAccess, generateAccessName, AccessKey } from "../../../../access";
import { TabOptions } from "../../../../types";
import BasicPageLayout from "../../../../components/BasicPageLayout";
import { CommunicationMethods } from "../../../../components/Settings/tabProps";

import { Auto, Main, General, Additional } from "./tabs";

const Tariffs: React.FC = () => {
	const [ACCESS_SECTION, ACCESS_SECTION_2] = [
		AccessKey.SETTINGS,
		AccessKey.SETTINGS_TARIFFS,
	];
	const { t } = useTranslation();
	const methods = useRef<CommunicationMethods>();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const [, setHasChanges] = useState(false);

	const tabs: TabOptions.Array = useMemo(
		() =>
			[
				{
					key: "mainTariffs",
					label: t("settings.tariffs.main.title"),
					value: {
						render: () => (
							<Main
								methods={(communicationMethods) => {
									methods.current = communicationMethods;
								}}
								onHasChanges={setHasChanges}
							/>
						),
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						ACCESS_SECTION_2,
						AccessKey.MAIN_TARIFFS,
					),
				},
				{
					key: "automatic",
					label: t("settings.tariffs.auto.title"),
					value: {
						render: () => (
							<Auto
								methods={(communicationMethods) => {
									methods.current = communicationMethods;
								}}
								onHasChanges={setHasChanges}
							/>
						),
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						ACCESS_SECTION_2,
						AccessKey.AUTOMATIC_TARIFFS,
					),
				},
				{
					key: "general",
					label: t("settings.tariffs.general.title"),
					value: {
						render: () => (
							<General
								methods={(communicationMethods) => {
									methods.current = communicationMethods;
								}}
								onHasChanges={setHasChanges}
							/>
						),
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						ACCESS_SECTION_2,
						AccessKey.GENERAL_TARIFFS,
					),
				},
				{
					key: "additional",
					label: t("settings.tariffs.additional.title"),
					value: {
						render: () => (
							<Additional
								methods={(communicationMethods) => {
									methods.current = communicationMethods;
								}}
								onHasChanges={setHasChanges}
							/>
						),
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						ACCESS_SECTION_2,
						AccessKey.ADDITIONAL,
					),
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, ACCESS_SECTION, ACCESS_SECTION_2, personalRole],
	);

	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	return (
		<BasicPageLayout
			navigation={
				<Tabs
					value={activeKey}
					onChange={setActiveKey}
					options={tabs}
					variant="vertical"
				/>
			}
			content={activeTab.value.render()}
		/>
	);
};

export default Tariffs;
