export type Key = string | number;

export interface Option<T> {
	key: Key;
	label: string;
	value: T;
}

export enum BaseElementIdEnum {
	MODAL = "modal_card_id",
	CROSS = "modal_header_button_cross_id",
	SAVE = "modal_footer_button_save_id",
	CLOSE = "modal_footer_button_close_id",
	STEPPER = "modal_stepper_input_id",
	TIME_SELECT = "modal_time_input_id",
}
export const BaseElementId = {
	MODAL: "modal_card_id",
	CROSS: "modal_header_button_cross_id",
	SAVE: "modal_footer_button_save_id",
	CLOSE: "modal_footer_button_close_id",
	STEPPER: "modal_stepper_input_id",
	TIME_SELECT: "modal_time_input_id",
} as const;
export type TypeBaseElementId = typeof BaseElementId;
export type ValueBaseElementId =
	(typeof BaseElementId)[keyof typeof BaseElementId];

export const Colors = {
	TRANSPARENT: "transparent",
	DISABLED: "#cccccc",
	DISABLED_1: "#DEE0E2",
	BACKGROUND_RED: "#E60000",
	BACKGROUND_RED_1: "#E8807F",
	BACKGROUND_GREEN: "rgb(76, 175, 80)",
	BACKGROUND_DARK: "#21333F",
	BACKGROUND_GRAY: "rgb(190, 190, 190)",
	BACKGROUND_GRAY_1: "rgba(190, 190, 190, 0.3)",
	BACKGROUND_BLUE: "#03A9F4",
	BACKGROUND_DISABLE: "#E9E9E9",
	BACKGROUND_WHITE: "#ffffff",
	BORDER_GRAY: "#cbcfd2",
	TEXT_DARK: "#21333f",
	PLACEHOLDER: "#9ba3a8",
} as const;
export type TypeColors = typeof Colors;
export type ValueColors = (typeof Colors)[keyof typeof Colors];
