import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useEffect,
	useState,
} from "react";
import { Column, RadioButton, Row, react, useRefWithSetter } from "uikit";
import { useTranslation } from "react-i18next";

import { AutoRateRulePolygonType as PolygonType } from "../../../../../../../../../../../../../../../../../../../../services/AutoTariff/enums";
import useObjectEditor from "../../../../../../../../../../../../../../../../../../../../hooks/useObjectEditor";
import FieldsContainer from "../../../../../../../../../../../../../../../../../../../../components/FieldsContainer";
import {
	MultiSelectSectorWithModal,
	MultiSelectSectorWithModalBase,
} from "../../../../../../../../../../../../../../../../../../../../components/common";

import tPath from "../../../../../../constants/tPath";

import Controller from "./Controller";
import ExecutorsCounter from "./components/ExecutorsCounter";
import OrdersCounter from "./components/OrdersCounter";
import {
	AutoRateRuleSectorItemCounterType,
	AutoRateRuleSectorsDistributionType as DistributionType,
} from "./constants";

const marginLeft = { marginLeft: 30 };

const SectorsBase = react.withController<Sectors.PropsBase, Controller>(
	({
		polygonType,
		setPolygonType,
		sectorIds,
		setSectorIds,
		value,
		onChange,
		controller,
	}) => {
		const [ordersCounterRef, setOrdersCounterRef] =
			useRefWithSetter<OrdersCounter.Ref | null>(null);
		const [executorsCounterRef, setExecutorsCounterRef] =
			useRefWithSetter<ExecutorsCounter.Ref | null>(null);
		controller.setContext({ ordersCounterRef, executorsCounterRef });

		const { t } = useTranslation();
		const valueEditor = useObjectEditor(value, onChange);

		const ordersCounter = valueEditor.useGetter("ordersCounter");
		const setOrdersCounter = valueEditor.useSetter("ordersCounter");

		const executorsCounter = valueEditor.useGetter("executorsCounter");
		const setExecutorsCounter = valueEditor.useSetter("executorsCounter");

		const distributionType = valueEditor.useGetter("distributionType");
		const setDistributionType = valueEditor.useSetter("distributionType");

		const [disabledLastDistributionType, setDisabledLastDistributionType] =
			useState(false);

		useEffect(() => {
			const isOverall =
				ordersCounter.type ===
				AutoRateRuleSectorItemCounterType.OVERALL;

			if (isOverall && !disabledLastDistributionType) {
				setDisabledLastDistributionType(true);
			}
			if (!isOverall && disabledLastDistributionType) {
				setDisabledLastDistributionType(false);
			}
			if (
				isOverall &&
				distributionType ===
					DistributionType.PICKUP_POINT_WITH_REACHED_COUNT
			) {
				setDistributionType(DistributionType.PICKUP_POINT);
			}
		}, [
			disabledLastDistributionType,
			distributionType,
			ordersCounter.type,
			setDistributionType,
		]);

		const setPolygonTypeToSectors = useCallback(() => {
			setPolygonType(PolygonType.SECTOR);
		}, [setPolygonType]);

		return (
			<Column gaps="15px*">
				<Row gaps="10px" align="center" sizes="auto 1fr">
					<RadioButton.RadioButton
						label={t(`${tPath}.modal.sectors.title`) || ""}
						selected={polygonType === PolygonType.SECTOR}
						onClick={setPolygonTypeToSectors}
					/>
					<MultiSelectSectorWithModal
						disabled={polygonType === PolygonType.PRICE_ZONE}
						value={sectorIds}
						onChange={setSectorIds}
						titleText={
							t(
								"pages.settings.pages.tariffs.tabs.auto.modal.content.tabs.main.rules.modal.content.sectors.str200",
							) ?? ""
						}
					/>
				</Row>
				<Column gaps="25px*" style={marginLeft}>
					<OrdersCounter
						ref={setOrdersCounterRef}
						disabled={polygonType === PolygonType.PRICE_ZONE}
						value={ordersCounter}
						onChange={setOrdersCounter}
					/>
					<ExecutorsCounter
						ref={setExecutorsCounterRef}
						disabled={polygonType === PolygonType.PRICE_ZONE}
						value={executorsCounter}
						onChange={setExecutorsCounter}
					/>
					<FieldsContainer
						label={
							t(
								`${tPath}.modal.sectors.distributionType.title`,
							) || ""
						}
					>
						<Column gaps="10px*">
							<RadioButton.RadioButton
								disabled={
									polygonType === PolygonType.PRICE_ZONE
								}
								label={
									t(
										`${tPath}.modal.sectors.distributionType.options.all`,
									) || ""
								}
								selected={
									distributionType === DistributionType.ALL
								}
								onClick={() => {
									setDistributionType(DistributionType.ALL);
								}}
							/>
							<RadioButton.RadioButton
								disabled={
									polygonType === PolygonType.PRICE_ZONE
								}
								label={
									t(
										`${tPath}.modal.sectors.distributionType.options.pickup_point`,
									) || ""
								}
								selected={
									distributionType ===
									DistributionType.PICKUP_POINT
								}
								onClick={() => {
									setDistributionType(
										DistributionType.PICKUP_POINT,
									);
								}}
							/>
							<RadioButton.RadioButton
								disabled={
									polygonType === PolygonType.PRICE_ZONE ||
									disabledLastDistributionType
								}
								label={
									t(
										`${tPath}.modal.sectors.distributionType.options.pickup_point_with_reached_count`,
									) || ""
								}
								selected={
									distributionType ===
									DistributionType.PICKUP_POINT_WITH_REACHED_COUNT
								}
								onClick={() => {
									setDistributionType(
										DistributionType.PICKUP_POINT_WITH_REACHED_COUNT,
									);
								}}
							/>
						</Column>
					</FieldsContainer>
				</Column>
			</Column>
		);
	},
	Controller,
);

const Sectors = memo(SectorsBase);

declare namespace Sectors {
	type Ref = Controller | null;
	interface PropsBase {
		polygonType: PolygonType;
		setPolygonType: Dispatch<PolygonType>;
		sectorIds: MultiSelectSectorWithModalBase.Value;
		setSectorIds: (sectorIds: MultiSelectSectorWithModalBase.Value) => void;
		value: Value;
		onChange: (value: Value) => void;
	}
	type Props = PropsBase & RefAttributes<Ref>;
	interface Value {
		distributionType: DistributionType;
		ordersCounter: OrdersCounter.Value;
		executorsCounter: ExecutorsCounter.Value;
	}
}

export default Sectors;
