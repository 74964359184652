import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Order from "../../../../../../../services/Order";

import { useTypedSelector } from "../../../../../../../redux/store";
import CellContentRoot from "../CellContentRoot";

const SourceCellContent: React.FC<SourceCellContent.Props> = ({ item }) => {
	const colors = useTypedSelector((state) => state.settings.colors);

	const { t } = useTranslation();

	const content = useMemo(
		() => t(`order_source.${item.source}`),
		[item.source, t],
	);

	const backgroundColor = useMemo(
		() => colors[item.source],
		[colors, item.source],
	);

	return (
		<CellContentRoot
			align="center"
			backgroundColor={backgroundColor}
			maxedHeight
			maxedWidth
		>
			{content}
		</CellContentRoot>
	);
};

declare namespace SourceCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default SourceCellContent;
