/* eslint-disable no-shadow */
import React, {
	Dispatch,
	ReactElement,
	useCallback,
	useMemo,
	useRef,
} from "react";
import { UseTranslationResponse, useTranslation } from "react-i18next";
import { CheckBox, Column, Table, ToggleButton } from "uikit";
import { SortType } from "rsuite-table";
import { clone, isUndefined } from "lodash";
import moment from "moment";

import KeyBinder from "../../../../../../../../../../services/KeyBinder";
import TaxiService from "../../../../../../../../../../services/TaxiService";
import Language from "../../../../../../../../../../services/Language";
import Role from "../../../../../../../../../../services/Role";
import { useTypedSelector } from "../../../../../../../../../../redux/store";
import {
	useVisibleColumns,
	useWidths,
} from "../../../../../../../../../../hooks/useTableSettings";
import mapByKey from "../../../../../../../../../../utils/mapByKey";
import LightTable from "../../../../../../../../../../components/LightTable";
import TableSettings from "../../../../../../../../../../components/TableSettings";

import { getColumns, defaultColumnIds } from "./constants";

interface ColumnContext {
	language: Language;
	t: UseTranslationResponse<"translation", undefined>[0];
	taxiServiceById: Record<number, TaxiService.Model>;
	width: number;
	onResize: (width?: number, columnId?: string) => void;
}

const Columns: Record<string, (context: ColumnContext) => ReactElement> = {
	active: ({ width, onResize, t }) => (
		<LightTable.Column width={width} resizable onResize={onResize}>
			<LightTable.HeaderCell verticalAlign="middle">
				{t(
					"pages.mainPage.pages.accounts.tabs.roles.content.modelTable.str150",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell verticalAlign="middle">
				{(item) => (
					<div onClick={(event) => event.stopPropagation()}>
						<ToggleButton.ToggleButton
							value={item.active}
							onChange={(value) => {
								Role.update({
									...(item as Role.Model),

									active: value,
								});
							}}
						/>
					</div>
				)}
			</LightTable.Cell>
		</LightTable.Column>
	),

	name: ({ width, onResize, t }) => (
		<LightTable.Column width={width} sortable resizable onResize={onResize}>
			<LightTable.HeaderCell verticalAlign="middle">
				{t(
					"pages.mainPage.pages.accounts.tabs.roles.content.modelTable.str151",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell verticalAlign="middle" dataKey="name">
				{(item) => item.name}
			</LightTable.Cell>
		</LightTable.Column>
	),

	assigns: ({ width, onResize, t }) => (
		<LightTable.Column width={width} sortable resizable onResize={onResize}>
			<LightTable.HeaderCell verticalAlign="middle">
				{t(
					"pages.mainPage.pages.accounts.tabs.roles.content.modelTable.str152",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell verticalAlign="middle" dataKey="fullName">
				{(item) =>
					item.assignableTo === "dispatcher"
						? t(
								"pages.mainPage.pages.accounts.tabs.roles.content.modelTable.str200",
						  ) ?? ""
						: t(
								"pages.mainPage.pages.accounts.tabs.roles.content.modelTable.str201",
						  ) ?? ""
				}
			</LightTable.Cell>
		</LightTable.Column>
	),

	taxiServices: ({ language, taxiServiceById, width, onResize, t }) => (
		<LightTable.Column width={width} sortable resizable onResize={onResize}>
			<LightTable.HeaderCell verticalAlign="middle">
				{t(
					"pages.mainPage.pages.accounts.tabs.roles.content.modelTable.str153",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell verticalAlign="middle" dataKey="fullName">
				{(item) => (
					<div
						style={{
							textOverflow: "ellipsis",
							overflow: "hidden",
							whiteSpace: "nowrap",
						}}
					>
						{(item.taxiServiceIds as number[])
							.map(
								(taxiServiceId) =>
									taxiServiceById[taxiServiceId]?.settlement[
										language
									] ?? "",
							)
							.filter((taxiServiceName) => taxiServiceName)
							.join(", ")}
					</div>
				)}
			</LightTable.Cell>
		</LightTable.Column>
	),

	createdAt: ({ width, onResize, t }) => (
		<LightTable.Column width={width} sortable resizable onResize={onResize}>
			<LightTable.HeaderCell verticalAlign="middle">
				{t(
					"pages.mainPage.pages.accounts.tabs.roles.content.modelTable.str154",
				) ?? ""}
			</LightTable.HeaderCell>
			<LightTable.Cell verticalAlign="middle" dataKey="registeredAt">
				{(rowData) => moment(rowData.createdAt).format("DD.MM.YYYY")}
			</LightTable.Cell>
		</LightTable.Column>
	),
};

const ModelTable: React.FC<ModelTable.Props> = ({
	selected,
	sort,

	loading,
	data,
	language,

	onChangeSelected,
	onChangeSort,

	onEdit,
	onLoadMore,
}) => {
	const { t } = useTranslation();

	const columns = useMemo(() => getColumns(t), [t]);

	const { columnIds, setColumnIds } = useVisibleColumns(
		"accounts.dispatchers",
		"role",
	);
	const { widths, setWidths } = useWidths("accounts.dispatchers", "role");

	const { models: taxiServices } = useTypedSelector(
		(state) => state.taxiServices,
	);

	const taxiServiceById = useMemo(
		() => mapByKey(taxiServices, "id"),
		[taxiServices],
	);

	const canLoadMoreRef = useRef(true);
	const tableRef = useRef<Table.Ref | null>(null);

	const tableRowClassName = useCallback(
		(rowData) => {
			if (selected.includes(rowData?.id ?? "")) return "selected";

			if (!rowData?.active) return "not-active";

			return "";
		},
		[selected],
	);

	const tableOnScroll = useMemo(() => {
		canLoadMoreRef.current = true;

		return (x: number, y: number) => {
			if (!canLoadMoreRef.current) return;

			const contextHeight = data.length * 44;
			const top = Math.abs(y);
			const tableContainerHeight =
				tableRef.current?.root.getBoundingClientRect().height ?? 0;

			if (contextHeight - top - tableContainerHeight < 300) {
				onLoadMore();

				canLoadMoreRef.current = false;
			}
		};
	}, [data.length, onLoadMore]);

	const setItemSelection = useCallback(
		(id: number, value: boolean) => {
			if (value) {
				const newSelected = clone(selected);

				newSelected.push(id);

				onChangeSelected(newSelected);
			} else
				onChangeSelected(
					selected.filter((selectedId) => selectedId !== id),
				);
		},
		[onChangeSelected, selected],
	);

	const tableOnRowClick = useCallback(
		(rowData) =>
			KeyBinder.isControlPressed
				? setItemSelection(rowData.id, !selected.includes(rowData.id))
				: onChangeSelected(
						selected.includes(rowData.id) ? [] : [rowData.id],
				  ),
		[onChangeSelected, selected, setItemSelection],
	);

	return (
		<Column sizes="1fr auto!" maxedWidth maxedHeight>
			<LightTable
				ref={tableRef}
				fillHeight
				virtualized
				shouldUpdateScroll={false}
				headerHeight={44}
				rowHeight={44}
				sortColumn={sort.column}
				sortType={sort.type}
				loading={loading}
				data={data}
				rowClassName={tableRowClassName}
				onScroll={tableOnScroll}
				onRowClick={tableOnRowClick}
				onRowDoubleClick={onEdit}
				onSortColumn={(column, type) => onChangeSort({ column, type })}
			>
				<LightTable.Column width={36}>
					<LightTable.HeaderCell verticalAlign="middle">
						<CheckBox
							value={
								selected.length === data.length &&
								data.length !== 0
							}
							onChange={(value) =>
								onChangeSelected(
									value ? data.map((item) => item.id) : [],
								)
							}
						/>
					</LightTable.HeaderCell>
					<LightTable.Cell verticalAlign="middle">
						{(rowData) => (
							<CheckBox
								value={selected.includes(rowData.id)}
								onChange={(selected) =>
									KeyBinder.isControlPressed
										? setItemSelection(rowData.id, selected)
										: onChangeSelected(
												selected ? [rowData.id] : [],
										  )
								}
							/>
						)}
					</LightTable.Cell>
				</LightTable.Column>

				{columnIds.map((columnId) =>
					Columns[columnId]({
						language,
						t,
						taxiServiceById,
						width: widths[columnId],
						onResize: (width, columnId) => {
							if (isUndefined(width) || isUndefined(columnId))
								return;

							setWidths({
								...widths,
								[columnId]: width,
							});
						},
					}),
				)}
			</LightTable>
			<TableSettings
				value={columnIds}
				defaultValue={defaultColumnIds}
				columns={columns}
				onChange={setColumnIds}
			/>
		</Column>
	);
};

declare namespace ModelTable {
	interface Sort {
		column?: string;
		type?: SortType;
	}

	interface Props {
		selected: number[];
		sort: Sort;

		loading: boolean;
		data: (unknown & { id: number })[];
		language: Language;

		onChangeSelected: Dispatch<number[]>;
		onChangeSort: Dispatch<Sort>;

		onEdit: Dispatch<number>;
		onLoadMore: () => void;
	}
}

export default ModelTable;
