import React, { memo, useCallback, useState } from "react";
import { Button, Icon } from "uikit";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../../../../../../../../../../redux/store";
import SIP from "../../../../../../../../../../services/SIP";
import Client from "../../../../../../../../../../services/Client";
import {
	Popup,
	StyledP,
} from "../../../../../../../../../../components/common";

import Wrapper from "./container";
import CallArrayModal from "./modal";

interface Props {
	clients: Client.Model[];
}

const Call: React.FC<Props> = memo(({ clients }) => {
	const { t } = useTranslation();
	const isOneSelected = clients.length === 1;

	const phones = clients[0]?.person?.phones;

	const sipToDispatcherId = useTypedSelector(
		(state) => state.sipToDispatcher.id,
	);

	const [showModal, setShowModal] = useState(false);

	const handleCall = useCallback(
		async (phone?: string) => {
			if (sipToDispatcherId) {
				await SIP.callToPhone({
					sipToDispatcherId,
					phone: phone || (phones?.[0]?.number as string),
				});
			}
		},
		[phones, sipToDispatcherId],
	);

	const handleShowModal = useCallback((e) => {
		setShowModal((prev) => !prev);
	}, []);

	const handleCloseModal = useCallback(() => {
		setShowModal(false);
	}, []);

	return (
		<Popup
			useHoverControl
			trackerId={"client_call_id"}
			offset={{ x: 32, y: 33 }}
			tracker={
				<Wrapper>
					<div style={{ maxWidth: 30 }}>
						<div
							style={{
								pointerEvents: showModal ? "none" : undefined,
							}}
						>
							<Button.Button
								variant="secondary"
								disabled={
									showModal ||
									!sipToDispatcherId ||
									!isOneSelected ||
									!phones
								}
								onClick={handleShowModal as any}
								icon={<Icon id="call-to-person" size={20} />}
							/>
						</div>
					</div>
					{showModal && (
						<CallArrayModal
							phones={phones}
							onClose={handleCloseModal}
							onSubmit={handleCall}
						/>
					)}
				</Wrapper>
			}
		>
			{!showModal && (
				<StyledP br="4px" bgC="#000000" colors="#ffffff" p="5px 10px">
					{t(["Позвонить"])}
				</StyledP>
			)}
		</Popup>
	);
});

export default Call;
