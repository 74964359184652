import React, {
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { cloneDeep } from "lodash";

import Counterparty from "../../../../../../services/Counterparty";
import CounterpartyGroup from "../../../../../../services/CounterpartyGroup";
import useModelSubscribe from "../../../../../../hooks/useModelSubscribe2";
import useCurrentTaxiServices from "../../../../../../hooks/useCurrentTaxiService";
import mapByKey from "../../../../../../utils/mapByKey";
import DefaultPageHeader from "../../../../../../components/DefaultPageHeader";
import DeleteModal from "../../../../../../components/DeleteModal";
import { useTableOptions } from "../../../../../../components/LightTable";

import tPath from "./constants/tPath";
import Root from "./components/Root";
import defaultValue from "./constants/defaultValue";
import Modal from "./components/Modal";
import Content from "./components/Content";
import Filters from "./components/Filters";

const Counterparties: React.FC = () => {
	const { t } = useTranslation();
	const {
		setDataLength,
		dataLength,
		filter,
		onActive,
		isActive,
		isNotActive,
		lang,
		limit,
		sort,
		editor,
		onChange,
		setQuery,
		query,
		setLimit,
	} = useTableOptions();

	const optionsCounterparty = useMemo(() => {
		const order = {};
		const payload: Counterparty.SubscribeOptions = {
			limit,
			lang,
			order,
			query,
		};
		if (typeof filter.active === "boolean") {
			payload.active = filter.active;
			if (!filter.active) payload.withDeleted = true;
		}
		if (filter.active === undefined) payload.withDeleted = true;
		if (sort.dataKey) order[sort.dataKey] = sort.sortType;
		return payload;
	}, [filter, lang, limit, sort.dataKey, sort.sortType, query]);

	const { models: counterparties } = useModelSubscribe(
		optionsCounterparty,
		Counterparty,
	);

	useLayoutEffect(() => {
		if (dataLength !== counterparties.length) {
			setDataLength(counterparties.length);
		}
	}, [counterparties.length, dataLength, setDataLength]);

	const { models: groups } = useModelSubscribe({}, CounterpartyGroup);

	const [actualDefaultValue, setActualDefaultValue] =
		useState<Modal.Value>(defaultValue);

	const settings = useCurrentTaxiServices()?.settings;
	const counterparty = settings?.counterparty;

	useEffect(() => {
		if (counterparty) {
			setActualDefaultValue({
				...defaultValue,
				active: counterparty?.active,
				accessAllEmployeeToPersonalApp:
					counterparty?.accessAllEmployeeToPersonalApp,
				addCustomerAsEmployeeAfterOrder:
					counterparty?.addCustomerAsEmployeeAfterOrder,
				isAccessToCorporateAccount:
					counterparty?.isAccessToCorporateAccount,
			});
		}
	}, [counterparty]);

	const [showModal, setShowModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const [editingItem, setEditingItem] =
		useState<Modal.Value>(actualDefaultValue);

	const [selected, setSelected] = useState<number[]>([]);

	const modelItemById = useMemo(
		() => mapByKey([...counterparties], "id"),
		[counterparties],
	);

	const edit = useCallback(
		(id: number) => {
			const item = { ...modelItemById[id] };

			setEditingItem(cloneDeep({ ...item }));
			setShowModal(true);
			setSelected([]);
		},
		[modelItemById],
	);

	const editHeaderHandler = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const editContentHandler = useCallback(
		(item: Counterparty.Model) => {
			edit(item.id);
		},
		[edit],
	);

	const addHandler = useCallback(() => {
		setEditingItem(actualDefaultValue);
		setShowModal(true);
	}, [actualDefaultValue]);

	const preDeleteHandler = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const deleteHandler = useCallback(() => {
		counterparties.forEach((item) => {
			if (selected.includes(item.id)) {
				Counterparty.update({ ...item, active: false });
			}
		});
		// Counterparty.delete(selected);
		setSelected([]);
		setShowDeleteModal(false);
	}, [counterparties, selected]);

	const cancelHandler = useCallback(() => {
		setShowModal(false);
		setEditingItem(actualDefaultValue);
	}, [actualDefaultValue]);

	const saveHandler = useCallback(
		async (counterparty: Counterparty.New | Counterparty.Modified) => {
			let isAllOk = true;

			if ("id" in counterparty) {
				isAllOk = await Counterparty.update(counterparty);
				// THIS IS CASTILE because is not work subscribe!
				setLimit(limit + 1);
			} else {
				isAllOk = await Counterparty.store(counterparty);
				// THIS IS CASTILE because is not work subscribe!
				setLimit(limit + 1);
			}

			if (isAllOk) {
				setShowModal(false);
				setEditingItem(actualDefaultValue);
			}
		},
		[actualDefaultValue, limit, setLimit],
	);

	const cancelDelete = useCallback(() => {
		setShowDeleteModal(false);
		setSelected([]);
	}, []);

	useEffect(() => {
		if (editingItem && editingItem?.id) {
			const newData = modelItemById?.[editingItem.id];
			const newPaymentAccounts = newData?.paymentAccounts ?? [];
			const newChecks = newData?.checks || [];

			const oldPaymentAccounts = editingItem?.paymentAccounts ?? [];
			const oldChecks = editingItem?.checks || [];

			const paymentAccounts = newPaymentAccounts.map((item) => {
				if (item.type === "threshold") {
					const exist = oldPaymentAccounts.find(
						(old) => old.type === "threshold",
					);
					if (exist) return exist;
				}

				return item;
			});

			const modifyChecks = [...oldChecks]?.map((item) => {
				const exist = newChecks?.find((check) => item.id === check.id);
				if (exist) {
					const newPaymentAccounts =
						exist.paymentAccounts?.filter(
							(item) => item.type !== "threshold",
						) || [];

					const oldPaymentAccounts =
						item.paymentAccounts?.filter(
							(item) => item.type === "threshold",
						) || [];

					return {
						...item,
						paymentAccounts: [
							...oldPaymentAccounts,
							...newPaymentAccounts,
						],
					};
				}
				return item;
			});

			setEditingItem({
				...editingItem,
				checks: modifyChecks,
				paymentAccounts,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [counterparties, modelItemById]);

	const deleteModalLabel = useMemo(() => {
		if (selected.length === 1) {
			const name =
				modelItemById?.[selected[0]]?.additionalFields?.name || "";

			const text = t(`${tPath}.deleteModal.title`);
			return `${text} ${name}?`;
		}

		if (selected.length > 1) {
			const text = t(`${tPath}.deleteModal.title`);
			return `${text} ?`;
		}

		return "";
	}, [modelItemById, selected, t]);

	return (
		<Root sizes="auto! 1fr" gaps="16px">
			<DefaultPageHeader
				canEdit={selected.length === 1}
				canDelete={!!selected.length}
				onAdd={addHandler}
				onDelete={preDeleteHandler}
				onEdit={editHeaderHandler}
				titleAdd={t([`${tPath}.table.add`]) || ""}
				titleEdit={t([`${tPath}.table.edit`]) || ""}
				titleDelete={t([`${tPath}.table.delete`]) || ""}
				afterAdditionalButtons={[
					{
						disabled: false,
						icon: { id: "counterpart", size: 20 },
						onClick: () => {
							onActive("yes");
						},
						variation: isActive ? "primary" : "secondary",
						title: t([`${tPath}.table.showActive`]) || "",
					},
					{
						disabled: false,
						icon: { id: "counterpart", size: 20 },
						onClick: () => {
							onActive("no");
						},
						variation: isNotActive ? "primary" : "secondary",
						title: t([`${tPath}.table.showNoActive`]) || "",
					},
				]}
				filters={<Filters value={query} onChange={setQuery} />}
			/>
			<Content
				value={filter.active === null ? [] : counterparties}
				selected={selected}
				setSelected={setSelected}
				onEdit={editContentHandler}
				editorTable={editor}
				onChangeTable={onChange}
			/>
			{showModal && (
				<Modal
					groups={groups}
					onCancel={cancelHandler}
					onSave={saveHandler}
					value={editingItem}
					onChange={setEditingItem}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					label={deleteModalLabel}
					onCancel={cancelDelete}
					onConfirm={deleteHandler}
				/>
			)}
		</Root>
	);
};

export default Counterparties;
