import React, { useMemo } from "react";
import { isFunction, isNil, isUndefined } from "lodash";
import PropsOf from "uikit/src/types/PropsOf";
import Root from "./components/Root";
import { useKeyBindSettings } from "../../../../../../../../../../hooks/useKeyBindSettings";
import { BaseKeybindEntries } from "../../../../../../../../../../redux/reducers/settings/keybinds";
import { useTypedSelector } from "../../../../../../../../../../redux/store";
import keyNames from "../../../../../../../../../../utils/keyNames";

const Button: React.FC<Button.Props> = ({ settingsKeyId, ...props }) => {
	useKeyBindSettings(settingsKeyId, (event) => {
		if (!props.disabled) props.onClick?.();

		event.preventDefault();
		event.stopPropagation();
	});

	const keys = useTypedSelector((state) =>
		isNil(settingsKeyId)
			? []
			: state.settings.keybinds[settingsKeyId].map(
					(key) => keyNames[key],
			  ),
	);

	const keysString = useMemo(
		() => (keys.length ? `(${keys.join("+")})` : ""),
		[keys],
	);

	const title = useMemo(
		() => {
			if (isFunction(props.title)) return props.title(keys);

			if (isUndefined(props.title)) return undefined;

			return `${props.title}${
				!isUndefined(props.title) ? " " : ""
			}${keysString}`;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[keys, keysString, props.title],
	);

	return <Root {...props} title={title} />;
};

declare namespace Button {
	type Variant = Root.Variant;

	interface Props extends Omit<PropsOf<typeof Root>, "title" | "onClick"> {
		settingsKeyId?: keyof BaseKeybindEntries<"meta">;

		title?: ((keys: string[]) => string) | string;

		onClick?: VoidFunction;
	}

	namespace Variant {
		type Props = Root.Variant.Props;
	}
}

export default Button;
