/* eslint-disable no-param-reassign */

import * as ModelEvent from "@node-elion/syncron";
import { LatLngLiteral } from "leaflet";
import { cloneDeep, isBoolean } from "lodash";

import Map from "../../redux/services/Map";
import { createObjectLanguageNamesFromObject } from "../../assets/languages/langs";
import Subscription from "../../types/Subscription";
import SubscriptionPool from "../../redux/services/SubscriptionPool";
import Company from "../Company";
import Language from "../Language";
import Base from "../Base";
import createLogger from "../../utils/logger.util";

const logger = createLogger({ name: "TaxiService2" });

class TaxiService extends Base {
	static fromResponse(data: any): TaxiService.Model {
		return {
			id: data.id,

			company: data.company
				? Company.fromResponse(data.company)
				: undefined,
			settlement: createObjectLanguageNamesFromObject(data.settlement),

			coordinates: data.settlementPoint,
			settings: cloneDeep(data.settings),

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
		};
	}

	static toRequest(model: TaxiService.Model.Modified): any {
		const payload: TaxiService.Model.Modified = {
			settings: model.settings,
		};

		if (
			isBoolean(model.settings?.sectors?.isBasicAverageSpeedEnabled) &&
			payload.settings
		) {
			payload.settings.sectors.additionalFields.isBasicAverageSpeedEnabled =
				model?.settings?.sectors?.additionalFields
					?.isBasicAverageSpeedEnabled || false;
		}

		return payload;
	}

	static async update(object: TaxiService.Model.Modified) {
		this.request((prpc) =>
			prpc.theirsModel.taxiService.update(
				object.id,
				TaxiService.toRequest(object),
			),
		);
	}

	public static async update2(
		object: TaxiService.Model.Modified,
	): Promise<boolean> {
		try {
			await this.request((prpc) =>
				prpc.theirsModel.taxiService.update(
					object.id,
					TaxiService.toRequest(object),
				),
			);
		} catch (err: any) {
			console.error("[TaxiService] Error update2:", err);
			return false;
		}
		return true;
	}

	public static async subscribe(
		options: TaxiService.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<TaxiService.Model>,
	): Promise<Subscription<TaxiService.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				console.log("[TaxiService] subscribe", { state, options });
				onUpdate({
					...state,

					models: state.models.map(this.fromResponse),
				});
			},
		});

		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.taxiService.subscribe({
					params: this.optionsToRequest(options),
					ping: () => true,
					onEvent: (events) => {
						modelEventConstructor.onEvent(events);
					},
					onError: (error) => {
						console.log("[TaxiService] subscribe error", options);
						// eslint-disable-next-line no-console
						console.log(error);
					},
				}),
			{ name: "TaxiService.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: TaxiService.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as Subscription<TaxiService.SubscribeOptions>;
	}

	public static async getById(id: number): Promise<TaxiService.Model | null> {
		const titleError = "[TaxiService] Error getById:";
		try {
			const result = await this.request(
				(prpc) => prpc.theirsModel.taxiService.getById(id),
				{ silent: false, error: true },
			);

			if (result && result.error) {
				logger.error(titleError, result.data);
				return null;
			}

			return result;
		} catch (err: any) {
			logger.error(titleError, err);
			return null;
		}
	}

	private static optionsToRequest(options: TaxiService.SubscribeOptions) {
		return {
			ids: options.ids,
			companyIds: options.companyIds,

			query: options.query,
			limit: options.limit,
			offset: options.offset,
			lang: options.language,
			terse: true,
			near: options.near,
		};
	}
}

declare namespace TaxiService {
	interface Model {
		id: number;
		additionalFields?: Record<string, any> & {
			isBasicAverageSpeedEnabled?: boolean;
		};
		company?: Company.Model;

		settlement: Record<Map.Language, string>;
		coordinates: LatLngLiteral;
		settings: Record<string, any>;

		createdAt: string | null;
		updatedAt: string | null;
		deletedAt: string | null;
	}

	interface SubscribeOptions {
		ids?: number[];
		companyIds?: number[];

		query?: string;
		limit?: number;
		offset?: number;
		language?: Language;
		terse?: boolean;
		near?: LatLngLiteral;
	}

	namespace Model {
		type Modified = Partial<
			Omit<Model, "createdAt" | "updatedAt" | "deletedAt">
		>;
	}
}

export default TaxiService;
