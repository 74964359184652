/* eslint-disable no-nested-ternary */
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { isNumber } from "lodash";
import styled from "styled-components";
import {
	Button,
	Select,
	Icon,
	InputGroup,
	Row,
	theme,
	useReactiveRef,
} from "uikit";

import TaxiService from "../../../../../../../../services/TaxiService";
import useModelSubscribe from "../../../../../../../../hooks/useModelSubscribe";
import SearchIconBorders from "../../../../../../../../components/SearchIconBorders";
import SearchTextBox from "../../../../../../../../components/SearchTextBox";
import CompaniesAndTaxiServicesFilter from "../../../../../../../../components/CompaniesAndTaxiServicesFilter";

const StyledInputGroup = styled(InputGroup.InputGroup)`
	width: 280px !important;
`;
const StyledRoot = styled.div`
	padding: 20px 24px 0;
	width: 100%;
	display: flex;
	gap: 10px;
	justify-content: space-between;
	align-items: center;
`;

export default function Header({
	handleOpenModal,
	setTaxiServiceFilter,
	onDelete,
	onEdit,
	notSelected,
	setLanguageFilter,
	languageFilter,
	query,
	onChangeQuery,
}) {
	const taxiServices = useModelSubscribe({}, TaxiService)?.cache;

	const [selectedCompaniesRef, setSelectedCompaniesRef] = useReactiveRef<
		number[] | ["all"]
	>(["all"]);
	const [selectedTaxiServicesRef, setSelectedTaxiServicesRef] =
		useReactiveRef<number[] | ["all"]>(["all"]);

	const calculateAndSendTaxiServiceIdsOnChange = useCallback(() => {
		const data =
			selectedTaxiServicesRef.current[0] !== "all"
				? (selectedTaxiServicesRef.current as number[])
				: selectedCompaniesRef.current[0] !== "all"
				? taxiServices
						?.filter(
							(taxiService) =>
								isNumber(taxiService.company?.id) &&
								(selectedCompaniesRef.current as Array<number>)
									// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
									.includes(taxiService.company!.id),
						)
						.map((taxiService) => taxiService.id)
				: undefined;
		setTaxiServiceFilter(data);
	}, [
		setTaxiServiceFilter,
		selectedCompaniesRef,
		selectedTaxiServicesRef,
		taxiServices,
	]);

	const CTSFOnChangeCompanies = useCallback(
		(ids: number[] | ["all"]) => {
			setSelectedCompaniesRef(ids);

			calculateAndSendTaxiServiceIdsOnChange();
		},
		[calculateAndSendTaxiServiceIdsOnChange, setSelectedCompaniesRef],
	);

	const CTSFOnChangeTaxiServices = useCallback(
		(ids: number[] | ["all"]) => {
			setSelectedTaxiServicesRef(ids);

			calculateAndSendTaxiServiceIdsOnChange();
		},
		[calculateAndSendTaxiServiceIdsOnChange, setSelectedTaxiServicesRef],
	);

	const { t } = useTranslation();
	return (
		<StyledRoot>
			<Row gaps="10px*">
				<Button.Button
					icon={
						<Icon
							id="plus"
							size={16}
							colors={[theme.colors.white]}
						/>
					}
					onClick={() => {
						handleOpenModal();
					}}
				/>
				<Button.Button
					variant="secondary"
					icon={<Icon id="pencil" size={20} />}
					disabled={!notSelected}
					onClick={() => {
						onEdit();
					}}
				/>
				<Button.Button
					variant="secondary"
					icon={<Icon id="trash" size={20} />}
					disabled={!notSelected}
					onClick={() => onDelete()}
				/>
			</Row>
			<Row gaps="10px*">
				<Row
					sizes="200px!*2 66px! 270px!"
					gaps="12px 10px*"
					align="center"
				>
					<CompaniesAndTaxiServicesFilter
						companies={selectedCompaniesRef.current}
						taxiServices={selectedTaxiServicesRef.current}
						language={languageFilter}
						onChangeCompanies={CTSFOnChangeCompanies}
						onChangeTaxiServices={CTSFOnChangeTaxiServices}
					/>
					<Select
						style={{ width: "65px" }}
						onSelect={(value) => setLanguageFilter(value.key)}
						value={languageFilter || "ru"}
						options={[
							{
								key: "uk",
								label:
									t(
										"pages.preferencesPages.screenDirectory.colors.header.str200",
									) ?? "",
								value: "uk",
							},
							{
								key: "ru",
								label:
									t(
										"pages.preferencesPages.screenDirectory.colors.header.str201",
									) ?? "",
								value: "ru",
							},
							{
								key: "en",
								label: "EN",
								value: "en",
							},
							{
								key: "az",
								label: "AZ",
								value: "az",
							},
							{
								key: "tr",
								label: "TR",
								value: "tr",
							},
						]}
					/>
					<StyledInputGroup sizes="auto 280px">
						<SearchIconBorders>
							<Icon
								id="search"
								size={16}
								colors={[theme.colors.disabled_text]}
							/>
						</SearchIconBorders>
						<SearchTextBox
							value={query}
							onChange={onChangeQuery}
							placeholder={`${
								t(
									"pages.preferencesPages.screenDirectory.colors.header.str202",
								) ?? ""
							}...`}
						/>
					</StyledInputGroup>
				</Row>
				<Button.Button
					variant="secondary"
					icon={<Icon id="filters" size={20} />}
					onClick={() => {}}
				/>
			</Row>
		</StyledRoot>
	);
}
