import React, { useMemo } from "react";
import moment from "moment";
import { isNumber } from "lodash";
import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const ExecutorArrivalDateCellContent: React.FC<
	ExecutorArrivalDateCellContent.Props
> = ({ item }) => {
	const executorArrivalTimestamp = useMemo(
		() => item.executorToOrder[0]?.additionalFields?.arrivalTime,
		[item.executorToOrder],
	);

	const content = useMemo(
		() =>
			isNumber(executorArrivalTimestamp)
				? moment(executorArrivalTimestamp).format("DD-MM-YYYY")
				: "",
		[executorArrivalTimestamp],
	);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace ExecutorArrivalDateCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default ExecutorArrivalDateCellContent;
