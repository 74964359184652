import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import LabeledContent from "../LabeledContent";
import { useTypedSelector } from "../../../../../../redux/store";
import TaxiServiceService from "../../../../../../services/TaxiService2";

const TaxiService = memo(() => {
	const { t } = useTranslation();

	const settingsLanguage = useTypedSelector(
		(state) => state.settings.map.mapLanguage,
	);

	const user = useTypedSelector((state) => state.account.user);

	const defaultTaxiService = useMemo(
		() =>
			user?.defaultTaxiService
				? TaxiServiceService.fromResponse(user?.defaultTaxiService)
				: null,
		[user?.defaultTaxiService],
	);

	const company = useMemo(
		() => defaultTaxiService?.company?.name[settingsLanguage],
		[defaultTaxiService?.company?.name, settingsLanguage],
	);

	const taxiService = useMemo(
		() => defaultTaxiService?.settlement[settingsLanguage],
		[defaultTaxiService?.settlement, settingsLanguage],
	);

	const content = useMemo(
		() => (company && taxiService ? `${company}, ${taxiService}` : "-"),
		[company, taxiService],
	);

	return (
		<LabeledContent width={180} label={t("main_header.taxiService")}>
			{content}
		</LabeledContent>
	);
});

export default TaxiService;
