import React, { memo, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox } from "uikit";

import { StyledRow, StyledSpan, Style } from "../styles";

const CheckBoxWithTextBase: React.FC<CheckBoxWithTextBase.Props> = ({
	value,
	onChange,
	disabled = false,
	title,
	labelPosition,
	...props
}) => {
	const { t } = useTranslation();
	const [data, setData] = useState<CheckBoxWithTextBase.Value>(false);

	const text = useMemo(() => t(title), [t, title]);

	const colorText = useMemo(() => {
		if (disabled) return "#647079";
		if (value || data) return "#21333f";
		return "#647079";
	}, [value, disabled, data]);

	return (
		<StyledRow alignItems="center" gap="10px" {...props}>
			<CheckBox
				value={onChange ? value : data}
				onChange={onChange || setData}
				disabled={disabled}
				labelPosition={labelPosition}
			/>
			<StyledSpan colors={colorText}>{text}</StyledSpan>
		</StyledRow>
	);
};

export const CheckBoxWithText = memo(CheckBoxWithTextBase);

declare namespace CheckBoxWithTextBase {
	type Value = boolean;

	interface Props extends Style.Row {
		value?: Value;
		onChange?: (value: Value) => void;
		title: string | string[];
		disabled?: boolean;
		labelPosition?: CheckBox.Props["labelPosition"];
	}
}

export default CheckBoxWithTextBase;
