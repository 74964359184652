import { useMemo } from "react";
import { TFunction } from "i18next";

import { useTypedSelector } from "../redux/store";
import { ROUTES, OPEN_ROUTES, RoutePaths } from "../constants/routes";

import { hasAccess, DisplayFields } from ".";

const useAuthRouterControl = (
	t: TFunction,
	warn: (title: string, message: string) => void,
	pathname: RoutePaths,
	accessName: string,
	personalRole?: DisplayFields,
) => {
	const { connected, authorized } = useTypedSelector((state) => ({
		connected: state.prpc.connected,
		authorized: state.prpc.authorized,
	}));

	const translateTexts = useMemo(
		() => ({
			warn: t("notification.title.warn") ?? "Warn",
			accessNotToPage:
				t("notification.description.accessNotToPage") ??
				"Access to the page is limited",
			authorizedNotToSystem:
				t("notification.description.authorizedNotToSystem") ??
				"You are not authorized. Please sign in",
		}),
		[t],
	);

	const isOpenRoute = OPEN_ROUTES.includes(pathname);
	const isMainPage = pathname === ROUTES.MAIN;
	const isLoginPage = pathname === ROUTES.LOGIN;

	const hasAccessMemo = useMemo(
		() => (accessName ? hasAccess(accessName, personalRole) : false),
		[accessName, personalRole],
	);

	if (isOpenRoute) {
		return { redirect: null };
	}

	if (connected && !authorized && !isOpenRoute && !isLoginPage) {
		warn(translateTexts.warn, translateTexts.authorizedNotToSystem);
		return { redirect: ROUTES.LOGIN };
	}

	if (connected && authorized && !isMainPage && !hasAccessMemo) {
		warn(translateTexts.warn, translateTexts.accessNotToPage);
		return { redirect: ROUTES.MAIN };
	}

	return { redirect: null };
};

export default useAuthRouterControl;
