import { isString } from "lodash";

import { AccessKey } from ".";

const cache = new Map<string, string>();

const generateAccessName = (...keys: AccessKey[]): string => {
	const validKeys = keys.filter(isString);

	if (validKeys.length === 0) {
		return "";
	}

	const cacheKey = validKeys.join(",");

	const cachedResult = cache.get(cacheKey);
	if (cachedResult !== undefined) {
		return cachedResult;
	}

	let result: string = validKeys[0];
	for (let i = 1; i < validKeys.length; i++) {
		result = `${result}.${validKeys[i]}`;
	}

	cache.set(cacheKey, result);

	return result;
};

export default generateAccessName;
