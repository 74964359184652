import React, {
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";

import Tariff from "../../../../../../services/Tariff";
import CarClass from "../../../../../../services/CarClass";
import mapByKey from "../../../../../../utils/mapByKey";
import useCurrentTaxiServices from "../../../../../../hooks/useCurrentTaxiService";
import useModelSubscribe from "../../../../../../hooks/useModelSubscribe2";
import { SettingsTabProps } from "../../../../../../components/Settings/tabProps";
import DeleteModal from "../../../../../../components/DeleteModal";
import DefaultPageHeader from "../../../../../../components/DefaultPageHeader";
import ControlButtons from "../../../../../../components/DefaultPageHeader/components/ControlButtons";
import { useTableOptions } from "../../../../../../components/LightTable";
import Root from "../../components/Root";

import getDeleteModalTitle from "./constants/getDeleteModalTitle";
import { defaultValue } from "./constants/defaultValue";
import { ErrorResponse, getErrorByMessage } from "./constants/errors";
import Content from "./components/Content";
import tPath from "./constants/tPath";
import Modal from "./components/Modal";

const Main: React.FC<Main.Props> = () => {
	const { setDataLength, dataLength, lang, limit, editor, onChange } =
		useTableOptions();

	const optionsTariff = useMemo(() => {
		// const order = {};
		const payload: Tariff.SubscribeOptions = {
			limit,
			// order,
		};

		// if (sort.dataKey) order[sort.dataKey] = sort.sortType;
		return payload;
	}, [limit]);

	const { models: tariffs } = useModelSubscribe(optionsTariff, Tariff);
	const { models: carClasses } = useModelSubscribe({}, CarClass);

	useLayoutEffect(() => {
		if (dataLength !== tariffs.length) {
			setDataLength(tariffs.length);
		}
	}, [tariffs.length, dataLength, setDataLength]);

	const { t } = useTranslation();

	const [showMainModal, setShowMainModal] = useState<boolean>(false);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const [showClarificationModal, setShowClarificationModal] = useState(false);
	const [showButtonUpdate, setShowButtonUpdate] = useState<boolean>(false);
	const [requestError, setRequestError] = useState<ErrorResponse | null>(
		null,
	);

	const [preparedTariff, setPreparedTariff] = useState<
		Tariff.New | Tariff.Modified | null
	>(null);

	const [actualDefaultValue, setActualDefaultValue] = useState(defaultValue);

	const settings = useCurrentTaxiServices()?.settings;

	const { general, rateGrid, fixedRate } =
		settings?.defaults.rateSettings || {};

	useEffect(() => {
		setActualDefaultValue({
			...defaultValue,
			additionalFields: {
				...defaultValue.additionalFields,
				general,
				rateGrid,
				fixedRate,
			},
		});
	}, [general, rateGrid, fixedRate]);

	const [editingItem, setEditingItem] =
		useState<Modal.Value>(actualDefaultValue);
	const [selectedTariffIds, setSelectedTariffIds] = useState<number[]>([]);

	const modelItemById = useMemo(() => mapByKey(tariffs, "id"), [tariffs]);

	const editTariff = useCallback(
		(id: number) => {
			const item = modelItemById[id];

			setEditingItem(item);
			setShowMainModal(true);
			setSelectedTariffIds([]);
		},
		[modelItemById],
	);

	const editTariffHeaderHandler = useCallback(() => {
		editTariff(selectedTariffIds[0]);
	}, [editTariff, selectedTariffIds]);

	const editTariffContentHandler = useCallback(
		(tariff: Tariff.Modified) => editTariff(tariff.id),
		[editTariff],
	);

	const addTariffHandler = useCallback(() => {
		setEditingItem(actualDefaultValue);
		setShowMainModal(true);
	}, [actualDefaultValue]);

	const preDeleteTariffHandler = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const deleteTariffHandler = useCallback(async () => {
		await Tariff.destroy(selectedTariffIds);
		setSelectedTariffIds([]);
		setShowDeleteModal(false);
	}, [selectedTariffIds]);

	const cancelHandler = useCallback(() => {
		setShowMainModal(false);
		setEditingItem(actualDefaultValue);
	}, [actualDefaultValue]);

	const copyHandler = useCallback(() => {
		Tariff.copy(selectedTariffIds[0]);
		setSelectedTariffIds([]);
	}, [selectedTariffIds]);

	const saveTariffHandler = useCallback(
		async (tariff: Tariff.New | Tariff.Modified, isForce = false) => {
			let isAllOk = true;

			if ("id" in tariff && tariff.id) {
				const res = await Tariff.update(tariff, isForce);
				isAllOk = !(res?.error || res === null);

				if (res.error) {
					const { message } = res.data;
					setRequestError(getErrorByMessage(message, true));
				}
			} else {
				const res = await Tariff.store(tariff as Tariff.New, isForce);
				isAllOk = !(res?.error || res === null);

				if (res.error) {
					const { message } = res.data;
					setRequestError(getErrorByMessage(message, false));
				}
			}

			if (isAllOk) {
				setShowMainModal(false);
				setEditingItem(actualDefaultValue);
				setPreparedTariff(null);
				setRequestError(null);
			} else {
				setPreparedTariff(tariff);
				setShowClarificationModal(true);
			}
		},
		[actualDefaultValue, setRequestError],
	);

	const additionalButtons = useMemo<ControlButtons.Button[]>(
		() => [
			{
				icon: { id: "copy", size: 20 },
				onClick: copyHandler,
				disabled: !(selectedTariffIds.length === 1),
			},
		],
		[copyHandler, selectedTariffIds.length],
	);

	const clarificationModalLabel = useMemo(() => {
		if (!requestError) return "";
		return t([requestError.translation, requestError.baseText]);
	}, [t, requestError]);

	useLayoutEffect(() => {
		if (editingItem?.id) {
			const item = modelItemById?.[editingItem.id];

			const carClassIds = item.carClassIds || [];

			if (item) {
				setEditingItem({
					...editingItem,
					carClassIds,
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tariffs]);

	return (
		<Root sizes="auto! 1fr" maxedWidth maxedHeight>
			<DefaultPageHeader
				onAdd={addTariffHandler}
				onDelete={preDeleteTariffHandler}
				onEdit={editTariffHeaderHandler}
				canEdit={selectedTariffIds.length === 1}
				canDelete={
					!selectedTariffIds.some((id) => modelItemById[id].root) &&
					!!selectedTariffIds.length
				}
				afterAdditionalButtons={additionalButtons}
			/>
			<Content
				tariffData={tariffs}
				selected={selectedTariffIds}
				onEdit={editTariffContentHandler}
				onChangeSelected={setSelectedTariffIds}
				carClasses={carClasses}
				editorTable={editor}
				onChangeTable={onChange}
			/>
			{showMainModal && (
				<Modal
					tariffs={tariffs}
					carClasses={carClasses}
					value={editingItem}
					headerTitle={
						editingItem.id
							? `${t(`${tPath}.mainModal.title2`)} "${
									editingItem.name[lang]
							  }"`
							: t(`${tPath}.mainModal.title`)
					}
					createdAt={
						editingItem?.createdAt
							? new Date(
									editingItem.createdAt,
							  ).toLocaleDateString()
							: new Date().toLocaleDateString()
					}
					onCancel={cancelHandler}
					onSave={saveTariffHandler}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					label={getDeleteModalTitle(selectedTariffIds.length, t)}
					onCancel={() => {
						setShowDeleteModal(false);
					}}
					onConfirm={deleteTariffHandler}
				/>
			)}
			{showClarificationModal && (
				<DeleteModal // in fact, this modal performs clarification functionality
					label={clarificationModalLabel}
					onCancel={() => {
						setShowClarificationModal(false);
						setShowButtonUpdate(false);
						setPreparedTariff(null);
						setRequestError(null);
					}}
					isConfirm={!showButtonUpdate}
					confirmButtonLabel={
						requestError?.canRetry
							? t(
									"pages.settings.pages.tariffs.tabs.main.str200",
							  ) ?? ""
							: t(
									"pages.settings.pages.tariffs.tabs.main.str201",
							  ) ?? ""
					}
					onConfirm={() => {
						if (requestError?.canRetry && preparedTariff) {
							saveTariffHandler(preparedTariff, true);
						}
						setShowClarificationModal(false);
						setRequestError(null);
					}}
				/>
			)}
		</Root>
	);
};

declare namespace Main {
	type Props = SettingsTabProps;
}

export default Main;
