import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";
import {
	getOrderPassengerPoints,
	stringifyPoint,
} from "../../../../../../../redux/constants/OrdersPage/order";

const PickupAddressCellContent: React.FC<PickupAddressCellContent.Props> = ({
	item,
}) => {
	const { t } = useTranslation();
	const points = useMemo(() => getOrderPassengerPoints(item), [item]);
	const pointOnMap = useMemo(() => t(["point_on_map"]), [t]);

	const content = useMemo(
		() =>
			points[0] ? stringifyPoint(points[0], "address", pointOnMap) : "",
		[pointOnMap, points],
	);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace PickupAddressCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default PickupAddressCellContent;
