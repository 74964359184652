import Modal from "../components/Modal";
import { GenderOptions } from "../components/Modal/components/Content/tabs/Main";

const defaultValue: Modal.Value = {
	active: true,
	otherFiles: [],
	personalFiles: [],
	phones: [{ value: "", group: 0, isCounterparty: false }],
	isCode: false,
	isOneTimePassword: false,
	oneTimePassword: "",
	code: {
		value: "",
		keyword: "",
	},
	additionalFields: {
		addresses: [],
		tripLimit: {
			active: false,
			amountByPeriod: {
				active: false,
				amount: 0,
				customPeriod: 0,
			},
			amountByTrip: {
				active: false,
				maxAmountOfTrip: 0,
			},
			countByPeriod: {
				active: false,
				customPeriod: 0,
				numberOfTrips: 0,
			},
			distanceByPeriod: {
				active: false,
				customPeriod: 0,
				distance: 0,
			},
		},
	},
	isAccessToCorporateApp: true,
	isAccessToCorporateAccount: false,
	customer: {
		emails: [
			{
				group: 0,
				value: "",
			},
		],
		gender: GenderOptions.UNSPECIFIED,
		name: "",
		surname: "",
		fatherName: "",
	},
};

export default defaultValue;
