import React, { Suspense, useMemo, lazy } from "react";
import { Tabs } from "uikit";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../../../../redux/store";
import useActiveTab from "../../../../hooks/useActiveTab";
import { hasAccess, generateAccessName, AccessKey } from "../../../../access";
import { TabOptions } from "../../../../types";
import BasicPageLayout from "../../../../components/BasicPageLayout";
import { StyledRow, SuspenseLoader } from "../../../../components/common";

import {
	ExecutorBonuses,
	CustomerBonuses,
	BankAccounts,
	Agents,
	LoyaltyProgram,
} from "./tabs";

const LazyExecutors = lazy<React.FC<any>>(async () => {
	const elem = await import(
		"./tabs/Executors" /* webpackChunkName: "accounting-executors-page" */
	);
	return elem;
});

const Accounting: React.FC = () => {
	const ACCESS_SECTION = AccessKey.ACCOUNTING;
	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const tabs: TabOptions.Array = useMemo(
		() =>
			[
				{
					key: "executors",
					label: t("mainPage.accounting.executors.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyExecutors />
							</Suspense>
						),
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.EXECUTORS_GENERAL,
					),
				},
				{
					key: "executorBonuses",
					label: t("mainPage.accounting.executorBonuses.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<ExecutorBonuses />
							</Suspense>
						),
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.EXECUTOR_BONUSES,
					),
				},
				{
					key: "customerBonuses",
					label: t("mainPage.accounting.customerBonuses.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<CustomerBonuses />
							</Suspense>
						),
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.CUSTOMER_BONUSES,
					),
				},
				{
					key: "bankAccounts",
					label: t("mainPage.accounting.bankAccounts.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<BankAccounts />
							</Suspense>
						),
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.BANK_ACCOUNTS,
					),
				},
				{
					key: "agents",
					label: t("mainPage.accounting.agents.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<Agents />
							</Suspense>
						),
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.AGENTS,
					),
				},
				{
					key: "loyaltyProgram",
					label: t("mainPage.accounting.loyaltyProgram.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LoyaltyProgram />
							</Suspense>
						),
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.LOYALTY_PROGRAM,
					),
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, ACCESS_SECTION, personalRole],
	);

	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	return (
		<Suspense
			fallback={
				<StyledRow position="absolute" top="50%" left="50%">
					<SuspenseLoader />
				</StyledRow>
			}
		>
			<BasicPageLayout
				navigation={
					<Tabs
						value={activeKey}
						onChange={setActiveKey}
						options={tabs}
						variant="vertical"
					/>
				}
				content={activeTab.value.render()}
			/>
		</Suspense>
	);
};

export default Accounting;
