import React, { Dispatch } from "react";

import ControlButtons from "../../../components/ControlButtons";
import InternalFilters from "./components/Filters";
import Root from "./components/Root";
import { Language } from "../../../../../../../../assets/languages/langs";

const Header: React.FC<Header.Props> = ({
	companies,
	taxiServices,
	language,
	dataType,
	search,
	additionalFilters,
	canEdit,
	canDelete,

	onChangeCompanies,
	onChangeTaxiServices,
	onChangeLanguage,
	onChangeDataType,
	onChangeSearch,
	onChangeAdditionalFilters,

	onAdd,
	onEdit,
	onDelete,
	onExport,
	onImport,
}) => (
	<Root gaps="10px*" align="center" justify="space-between" maxedWidth>
		<ControlButtons
			canEdit={canEdit}
			canDelete={canDelete}
			onAdd={onAdd}
			onEdit={onEdit}
			onDelete={onDelete}
		/>
		<InternalFilters
			companies={companies}
			taxiServices={taxiServices}
			language={language}
			dataType={dataType}
			search={search}
			additionalFilters={additionalFilters}
			onChangeCompanies={onChangeCompanies}
			onChangeTaxiServices={onChangeTaxiServices}
			onChangeLanguage={onChangeLanguage}
			onChangeDataType={onChangeDataType}
			onChangeSearch={onChangeSearch}
			onChangeAdditionalFilters={onChangeAdditionalFilters}
			onExport={onExport}
			onImport={onImport}
		/>
	</Root>
);

declare namespace Header {
	interface Props {
		companies: InternalFilters.Props["companies"];
		taxiServices: InternalFilters.Props["taxiServices"];
		language: Language;
		dataType: "objects" | "object-groups";
		search: string;
		additionalFilters: InternalFilters.Popover.Value;
		canEdit: boolean;
		canDelete: boolean;

		onChangeCompanies: InternalFilters.Props["onChangeCompanies"];
		onChangeTaxiServices: InternalFilters.Props["onChangeTaxiServices"];
		onChangeLanguage: Dispatch<Language>;
		onChangeDataType: Dispatch<"objects" | "object-groups">;
		onChangeSearch: Dispatch<string>;
		onChangeAdditionalFilters: Dispatch<InternalFilters.Popover.Value>;

		onAdd: () => void;
		onEdit: () => void;
		onDelete: () => void;
		onExport: () => void;
		onImport: () => void;
	}

	namespace Filters {
		namespace Popover {
			type Value = InternalFilters.Popover.Value;
		}
	}
}

export default Header;
