import React, { memo, useCallback, useMemo } from "react";
import { Icon } from "uikit";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../../../../../../../../redux/store";
import { useKeyBindSettings } from "../../../../../../../../hooks/useKeyBindSettings";
import {
	ListSelect,
	ListSelectMemo,
} from "../../../../../../../../components/Orders";
import {
	StyledWrapIcon,
	StyledColumn,
	Text,
	Style,
} from "../../../../../../../../components/common";
import { OrderFormProps } from "../../types/FormProps";

const TaxiClass: React.FC<TaxiClass.Props> = ({ tab, form, disabled }) => {
	const { t } = useTranslation();
	const language = useTypedSelector((state) => state.session.language);
	const { rates } = useTypedSelector(
		(state) => state.preferencesReducer.rates,
	);
	const { classes } = useTypedSelector(
		(state) => state.preferencesReducer.classesReducer,
	);

	const styles = useMemo<TaxiClass.Styles>(
		() => ({
			wrap: {
				justify: "center",
				alignItems: "space-between",
				w: "max-content",
				bgC: "transparent",
				cursor: disabled ? "not-allowed" : "pointer",
			},
			icon: {
				w: "16px",
				h: "16px",
				colors: "#5e6b73",
			},
			arrow: {
				w: "16px",
				h: "16px",
				colors: "#5e6b73",
				cursor: disabled ? "not-allowed" : "pointer",
			},
			text: {
				colors: disabled ? "#9BA3A8" : "#21333F",
			},
		}),
		[disabled],
	);

	const rateId = useMemo(() => tab?.form?.rateId, [tab?.form?.rateId]);

	const rateCarClassIds = useMemo<number[]>(() => {
		const rate = rates?.find((item) => item.id === rateId) || rates?.at(0);
		return rate?.carClassIds || [];
	}, [rateId, rates]);

	const companyID = useMemo(
		() => tab?.form?.companyID,
		[tab?.form?.companyID],
	);

	const taxiServiceId = useMemo(
		() => tab?.form?.taxiServiceId,
		[tab?.form?.taxiServiceId],
	);

	const taxiClass = useMemo(
		() => tab?.form?.taxiClass,
		[tab?.form?.taxiClass],
	);

	const carClassModalOptions = useMemo<ListSelect.Options>(() => {
		const tabClasses = classes.map((item) => {
			const { active, taxiServiceToCarClasses, id } = item;

			const isClass = taxiServiceToCarClasses?.some(
				(item) =>
					item.taxiService?.id === taxiServiceId &&
					item.taxiService?.company?.id === companyID,
			);

			const isTariff = isClass && active && rateCarClassIds.includes(id);

			return { ...item, isTariff };
		});

		return tabClasses.map((item) => ({
			value: item.id,
			name: item.name?.[language],
			active: item?.isTariff,
		}));
	}, [classes, companyID, taxiServiceId, language, rateCarClassIds]);

	const title = useMemo(() => t(["class_car", "Car class"]), [t]);
	const value = useMemo(
		() =>
			taxiClass
				? taxiClass?.map((item) => item?.name).join(", ")
				: t("none"),
		[t, taxiClass],
	);

	const onIsTaxiClass = useCallback(
		(classes: boolean) => {
			if (!disabled) {
				tab.setState((prev) => ({
					...prev,
					modals: { ...prev.modals, classes },
				}));
			}
		},
		[disabled, tab],
	);

	const changedRateId = useCallback(
		(carClasses: { value: number; name: string }[]) => {
			const carClassIds = carClasses.map((item) => item.value);

			const foundRates = rates.filter((rate) => {
				const cars = carClassIds
					.map((item) => rate.carClassIds.includes(item))
					.every((item) => item);

				const taxi = rate.taxiServiceIds.includes(taxiServiceId);
				const company = rate.taxiServices.find((service) => {
					if (service.company?.id === companyID) return true;
					return false;
				});

				if (taxi && company && cars) return true;
				return false;
			});

			const rateId = foundRates?.at(0)?.id;

			form.setValue("rateId", rateId || 1);
		},
		[companyID, rates, form, taxiServiceId],
	);

	const onClassOptionSelect = useCallback(
		(options) => {
			onIsTaxiClass(false);

			if (!options.length) return;

			const classes = options.map((item) => ({
				value: item.value,
				name: item.name,
			}));
			changedRateId(classes);
			form.setValue("taxiClass", classes);
			form.setValue("orderSave", true);
			form.setValue("orderPointSave", false);
		},
		[changedRateId, form, onIsTaxiClass],
	);

	const onClickTaxiClass = useCallback(() => {
		const isOpen = tab.state.modals.classes;
		if (!disabled && !isOpen) onIsTaxiClass(true);
		if (isOpen) onIsTaxiClass(false);
	}, [disabled, onIsTaxiClass, tab.state?.modals?.classes]);

	useKeyBindSettings("orderModalTaxiClassModal", (event) => {
		if (!disabled) {
			onClickTaxiClass();
		}
		event.preventDefault();
		event.stopPropagation();
	});

	return (
		<>
			<StyledColumn {...styles.wrap} onClick={onClickTaxiClass}>
				<Text
					textStyle={styles.text}
					valueStyle={styles.text}
					text={
						<StyledWrapIcon {...styles.icon}>
							<Icon id="order-modal-tariff" size={16} />
						</StyledWrapIcon>
					}
					value={
						<>
							{value}
							<StyledWrapIcon {...styles.arrow}>
								<Icon id="arrow-select-down" size={16} />
							</StyledWrapIcon>
						</>
					}
				/>
			</StyledColumn>
			{tab.state.modals.classes && (
				<ListSelectMemo
					hiddenButton={false}
					pullUpItemInsideArray
					required
					headerDivider={false}
					title={title}
					value={taxiClass}
					options={carClassModalOptions}
					onSubmit={onClassOptionSelect}
					onClose={onClickTaxiClass}
					selectOnlyOne
				/>
			)}
		</>
	);
};

declare namespace TaxiClass {
	interface Props extends OrderFormProps {}

	interface Styles {
		wrap: Style.FlexType;
		icon: Style.FlexType;
		arrow: Style.FlexType;
		text: Style.TextType;
	}
}
export const TaxiClassMemo = memo(TaxiClass);
export default TaxiClass;
