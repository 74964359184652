import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Controller, ControllerRenderProps } from "react-hook-form";
import { Option, Icon } from "uikit";

import { useTypedSelector } from "../../../../../../../../redux/store";
import { Schema } from "../../../../../../../../redux/constants/OrdersPage/order";
import {
	StyledRow,
	StyledSpan,
	Text,
	StyledWrapIcon,
	Divider,
	Select,
} from "../../../../../../../../components/common";
import { OrderFormProps } from "../../types/FormProps";
import {
	TransportationType,
	transportationTypes,
} from "../../types/transportationTypes";

type Fn = (type: TransportationType) => Option<TransportationType>;

interface RenderTransportationType {
	field: ControllerRenderProps<Schema, "transportationType">;
}
interface RenderCompanyID {
	field: ControllerRenderProps<Schema, "companyID">;
}

interface RenderTaxiServiceId {
	field: ControllerRenderProps<Schema, "taxiServiceId">;
}
const Left: React.FC<OrderFormProps> = ({ tab, form, disabled }) => {
	const { t } = useTranslation();

	const { rates } = useTypedSelector(
		(state) => state.preferencesReducer.rates,
	);
	const { user } = useTypedSelector((state) => state.account);
	const companiesRedux = useTypedSelector(
		(state) => state.ordersPageReducer.companies,
	);
	const [city, setCity] = useState("");
	const companies = useMemo(() => companiesRedux, [companiesRedux]);
	const userLanguage = useMemo(
		() => user?.settings?.language || "en",
		[user],
	);

	const currentCompanyId = useMemo(
		() => tab?.form?.companyID,
		[tab?.form?.companyID],
	);

	const taxiClass = useMemo(() => tab.form.taxiClass, [tab.form.taxiClass]);

	const handleSave = useCallback(() => {
		form.setValue("orderSave", true);
		form.setValue("orderPointSave", false);
	}, [form]);

	const companyToOption = useCallback(
		(company) => ({
			key: company.id,
			value: company.id,
			label: company.name?.[userLanguage],
		}),
		[userLanguage],
	);

	const companiesOptions = useMemo(
		() => companies.items.map(companyToOption),
		[companyToOption, companies],
	);

	const taxiServiceToOption = useCallback(
		(service) => ({
			key: service.id,
			value: service.id,
			label: service.settlement?.[userLanguage],
		}),
		[userLanguage],
	);

	const taxiServiceOptions = useMemo(() => {
		const selectedCompanyId = currentCompanyId;
		const selectedCompany = companies.items?.find(
			(item) => item.id === selectedCompanyId,
		);

		return selectedCompany.taxiServices.map(taxiServiceToOption);
	}, [currentCompanyId, companies, taxiServiceToOption]);

	const allTaxiServices = useMemo(
		() =>
			companies.items?.flatMap((item) =>
				item.taxiServices?.map((service) => ({
					...service,
					companyID: item.id,
				})),
			),
		[companies],
	);

	const wrap = useCallback<Fn>(
		(type) => ({
			key: type,
			value: type,
			label: t([
				`orderPage.order_form.transportation_types.${type}`,
				type,
			]),
		}),
		[t],
	);

	const options = useMemo(
		() => transportationTypes.map((type) => wrap(type)),
		[wrap],
	);

	const onRenderCreatedAt = useCallback(
		({ field }: { field: ControllerRenderProps<Schema, "createdAt"> }) => {
			const text = `${t([
				`orderPage.order_form.order_time.title`,
				"Added",
			])}: `;

			return (
				<Text
					textStyle={{
						w: "auto",
					}}
					text={text}
					value={moment(field.value).format("HH:mm:ss")}
				/>
			);
		},
		[t],
	);

	const onRenderStatus = useCallback(
		({ field }: { field: ControllerRenderProps<Schema, "status"> }) => {
			const text = `${t([
				`order_status.${field.value}`,
				"Order status",
			])}`;

			return (
				<StyledSpan
					m="0 10px 0 10px"
					font={{ size: "10px", line: "12px", fw: "600" }}
					colors="#8000FF"
					bgC="#F0E0FF"
					br="4px"
					p="2px 4px"
					h="16px"
				>
					{text}
				</StyledSpan>
			);
		},
		[t],
	);

	const onRenderSource = useCallback(
		({ field }: { field: ControllerRenderProps<Schema, "source"> }) => {
			const text = `${t([`order_source.${field.value}`, "Source"])}`;
			return (
				<StyledRow m="0 0 0 10px" alignItems="center" gap="0 6px">
					<StyledWrapIcon
						w="16px"
						h="16px"
						offset={{ x: "0", y: "2px" }}
						colors="#647079"
					>
						<Icon id="order-modal-source" size={16} />
					</StyledWrapIcon>
					<StyledSpan colors="#647079">{text}</StyledSpan>
				</StyledRow>
			);
		},
		[t],
	);

	const onRenderTransportationType = useCallback(
		({ field }: RenderTransportationType) => {
			const value = wrap(field.value || "passenger").key;
			const selected = options
				.map((item, i) => {
					if (value === item.value) return i + 1;
					return null;
				})
				.find((item) => item !== null);

			return (
				<Select
					selected={selected || 0}
					disabled={disabled}
					options={options}
					value={value}
					onSelect={(option) => {
						field.onChange({
							target: { value: option?.value },
						} as unknown as React.ChangeEvent<Element>);
						handleSave();
					}}
				/>
			);
		},
		[disabled, options, wrap, handleSave],
	);

	const onSelect = useCallback(
		(option) => {
			const foundedOption = allTaxiServices.find(
				(item) => item.id === (option?.value || 1),
			);

			const defaultCity = {
				id: foundedOption.id,
				value: foundedOption.settlement?.[userLanguage],
				settlement: foundedOption.settlement?.[userLanguage],
				coordinates: foundedOption.settlementPoint,
			};

			setCity(defaultCity.value);
			tab.setState({ ...tab.state, defaultCity });
			handleSave();
		},
		[allTaxiServices, handleSave, tab, userLanguage],
	);

	const setDefaultRateId = useCallback(() => {
		const rate = rates.find((item) => item.default);
		form.setValue("rateId", rate?.id || 1);
	}, [rates, form]);

	const onRenderCompanyIDe = useCallback(
		({ field }: RenderCompanyID) => {
			const selected = companiesOptions
				.map((item, i) => {
					if (field?.value === item.value) return i + 1;
					return null;
				})
				.find((item) => item !== null);

			return (
				<Select
					selected={selected || 0}
					disabled={disabled}
					options={companiesOptions}
					value={field.value}
					onSelect={(option) => {
						field.onChange({
							target: { value: option?.value },
						} as unknown as React.ChangeEvent<Element>);
						if (option) setDefaultRateId();
					}}
				/>
			);
		},
		[companiesOptions, disabled, setDefaultRateId],
	);

	const changedRateId = useCallback(
		(companyId: number) => {
			const carClassIds = taxiClass?.map((item) => item.value) || [];
			const rate = rates.filter((rate) => {
				const cars = carClassIds
					.map((item) => rate.carClassIds.includes(item))
					.every((item) => item);

				const company = rate.taxiServiceIds?.includes(companyId);

				if (company && cars) return true;
				return false;
			});

			const rateId = rate?.at(0)?.id;
			form.setValue("rateId", rateId || 1);
		},
		[rates, taxiClass, form],
	);

	const placeholderTaxiService = t(["taxiService", "Branch"]);
	const onRenderTaxiServiceId = useCallback(
		({ field }: RenderTaxiServiceId) => {
			const exist = taxiServiceOptions.find((taxi) => {
				if (field.value === taxi.value) return taxi;
				return false;
			});

			if (!exist) setCity("");
			else {
				const city = tab?.form?.city;
				if (!city) {
					if (form.getValues().city !== exist?.label)
						form.setValue("city", exist?.label);
					setCity(exist?.label || "");
				}
			}

			const selected = taxiServiceOptions
				.map((item, i) => {
					if (field?.value === item?.value) return i + 1;
					return null;
				})
				.find((item) => item !== null);

			return (
				<Select
					selected={selected}
					disabled={disabled}
					options={taxiServiceOptions}
					value={field.value}
					placeholder={placeholderTaxiService}
					onSelect={(option) => {
						onSelect(option);

						if (typeof option.value === "number") {
							changedRateId(option.value);
						}

						field.onChange({
							target: { value: option?.value },
						} as unknown as React.ChangeEvent<Element>);
					}}
				/>
			);
		},

		[
			taxiServiceOptions,
			disabled,
			placeholderTaxiService,
			tab.form,
			form,
			onSelect,
			changedRateId,
		],
	);

	return (
		<StyledRow
			alignItems="center"
			h="clamp(26px, 3.6vh, 32px)"
			over="visible"
			flex={{ wrap: "nowrap", flex: "0 1 100%" }}
		>
			<Controller
				name="createdAt"
				control={form.control}
				render={onRenderCreatedAt}
			/>

			<Controller
				name="status"
				control={form.control}
				render={onRenderStatus}
			/>
			<Divider h="18px" />
			<Controller
				name="transportationType"
				control={form.control}
				render={onRenderTransportationType}
			/>
			<Divider h="18px" />
			<Controller
				name="companyID"
				control={form.control}
				render={onRenderCompanyIDe}
			/>
			<Divider h="18px" />
			<Controller
				name="taxiServiceId"
				control={form.control}
				render={onRenderTaxiServiceId}
			/>
			<Divider h="18px" />

			<StyledRow alignItems="center" gap="0 6px" p="0 0 0 10px">
				<StyledWrapIcon w="15px" h="15px" colors="#647079">
					<Icon id="order-modal-city" size={15} />
				</StyledWrapIcon>
				<StyledSpan colors="#647079">{city}</StyledSpan>
			</StyledRow>

			<Controller
				name="source"
				control={form.control}
				render={onRenderSource}
			/>
		</StyledRow>
	);
};

export default Left;
