import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Order from "../../../../../../../services/Order";
import { OrderPaymentStatus } from "../../../../../../../redux/constants/OrdersPage/order";

import CellContentRoot from "../CellContentRoot";

interface PaymentColor {
	backgroundColor: string;
	textColor: string;
}

export const paymentColors: Record<
	OrderPaymentStatus,
	PaymentColor | undefined
> = {
	[OrderPaymentStatus.PAYMENT_PENDING]: {
		backgroundColor: "#03A9F4",
		textColor: "#FFFFFF",
	},
	[OrderPaymentStatus.PAYMENT_FAILED]: {
		backgroundColor: "#f83528",
		textColor: "#FFFFFF",
	},
	[OrderPaymentStatus.PAYMENT_SUCCESS]: {
		backgroundColor: "#4caf50",
		textColor: "#FFFFFF",
	},
	[OrderPaymentStatus.CARD_VALIDATION]: {
		backgroundColor: "#03A9F4",
		textColor: "#FFFFFF",
	},
	[OrderPaymentStatus.CARD_VALIDATION_FAILED]: {
		backgroundColor: "#f83528",
		textColor: "#FFFFFF",
	},
	[OrderPaymentStatus.CARD_VALIDATION_SUCCESS]: {
		backgroundColor: "#4caf50",
		textColor: "#FFFFFF",
	},
	[OrderPaymentStatus.CLOSED]: undefined,
	[OrderPaymentStatus.PAYMENT_PART]: {
		backgroundColor: "#FFD8D9",
		textColor: "#FFFFFF",
	},
	[OrderPaymentStatus.REFUND_SUCCESS]: undefined,
	[OrderPaymentStatus.REFUND_PENDING]: undefined,
	[OrderPaymentStatus.REFUND_FAILED]: undefined,
};

const PaymentTypeCellContent: React.FC<PaymentTypeCellContent.Props> = ({
	item,
}) => {
	const { t } = useTranslation();
	OrderPaymentStatus;
	const content = useMemo(
		() => t(`order_payment_type.${item.paymentType}`),
		[item.paymentType, t],
	);
	const paymentStatus = useMemo(
		() => item.paymentStatus?.status,
		[item.paymentStatus?.status],
	);

	const colors = useMemo(
		() => (paymentStatus ? paymentColors[paymentStatus] ?? {} : {}),
		[paymentStatus],
	);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth {...colors}>
			{content}
		</CellContentRoot>
	);
};

declare namespace PaymentTypeCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default PaymentTypeCellContent;
