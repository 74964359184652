import React, { memo } from "react";
import { Row } from "uikit";
import DefaultPageHeader from "../../../../../../../../components/DefaultPageHeader";
import Agent from "../../../../../../../../services/Agent";
import Call from "./components/Call";
import Balance from "./components/Balance";
import SendingPush from "./components/SendingPush";
import SendingSMS from "./components/SendingSMS";

const HeaderAdditional: React.FC<HeaderAdditional.Props> = memo((props) => {
	const { agents } = props;

	const mainAccount = agents[0]?.paymentAccounts.find(
		(a) => a.type === "main",
	);
	const bonusAccount = agents[0]?.paymentAccounts.find(
		(a) => a.type === "bonus",
	);

	const agent = agents.length === 1 && agents[0];

	return (
		<Row gaps="50px*" style={{ marginLeft: 50 }}>
			<Row align="center" gaps="10px*">
				<Call agents={agents} />
				<SendingSMS agents={agents} />
				<SendingPush agents={agents} />
			</Row>
			<Row align="center" gaps="10px*">
				<Balance
					agent={agent}
					paymentAccount={mainAccount}
					iconId="balance"
				/>
				<Balance
					agent={agent}
					paymentAccount={bonusAccount}
					iconId="bonus-balance"
				/>
			</Row>
		</Row>
	);
});

declare namespace HeaderAdditional {
	interface Props extends DefaultPageHeader.Props {
		agents: Agent.Model[];
	}

	interface Value {}
}

export default HeaderAdditional;
