/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-shadow */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import SMSTemplate from "../../../../../services/SMSTemplate";
import { useTypedSelector } from "../../../../../redux/store";
import useModelSubscribe from "../../../../../hooks/useModelSubscribe2";
import mapByKey from "../../../../../utils/mapByKey";
import { SettingsTabProps } from "../../../../../components/Settings/tabProps";
import DefaultPageHeader from "../../../../../components/DefaultPageHeader";
import DeleteModal from "../../../../../components/DeleteModal";

import Content from "./components/Content";
import Modal from "./components/Modal";
import Root from "./components/Root";
import fromStringToJson from "./components/Modal/components/Content/components/TemplateBody/helpers/fromStringToJson";
import translationPath from "./constants/translationPath";
import defaultTemplate from "./constants/defaultTemplate";

const Templates: React.FC<Templates.Props> = () => {
	const { t } = useTranslation();
	const globalLang = useTypedSelector((state) => state.session.language);
	const actualTemplates = useModelSubscribe(
		{ order: { action: "ASC" } },
		SMSTemplate,
	);
	defaultTemplate.lang = globalLang;

	const [showModal, setShowModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showClarificationModal, setShowClarificationModal] = useState(false);

	const [templates, setTemplates] = useState<SMSTemplate.Model[] | []>([]);
	useEffect(() => {
		setTemplates(actualTemplates.models);
	}, [actualTemplates.models]);

	const [preparedTemplate, setPreparedTemplate] =
		useState<Modal.PreparedTemplate>(null);

	const [editingItem, setEditingItem] =
		useState<Modal.Value>(defaultTemplate);

	const [selected, setSelected] = useState<number[]>([]);

	const modelItemById = useMemo(() => mapByKey(templates, "id"), [templates]);

	const edit = useCallback(
		(id: number) => {
			const item = modelItemById[id];

			setEditingItem({
				...item,
				templateText: fromStringToJson(item.templateText, t),
			});
			setShowModal(true);
			setSelected([]);
		},
		[modelItemById, t],
	);

	const editHeaderHandler = useCallback(() => {
		edit(selected[0]);
	}, [edit, selected]);

	const editContentHandler = useCallback(
		(template: SMSTemplate.Modified) => edit(template.id),
		[edit],
	);

	const copyHandler = useCallback(() => {
		SMSTemplate.copy(selected[0]);
		setSelected([]);
	}, [selected]);

	const addHandler = useCallback(() => {
		setShowModal(true);
	}, []);

	const deleteHandler = useCallback(() => {
		SMSTemplate.destroy(selected);
		setSelected([]);
		setShowDeleteModal(false);
	}, [selected]);

	const cancelHandler = useCallback(() => {
		setShowModal(false);
		setEditingItem(defaultTemplate);
	}, []);

	const saveHandler = useCallback(
		async (
			template: SMSTemplate.New | SMSTemplate.Modified,
			force = false,
		) => {
			let isAllOk = true;

			if ("id" in template) {
				isAllOk = await SMSTemplate.update(template, force);
			} else {
				isAllOk = await SMSTemplate.store(template, force);
			}

			if (isAllOk) {
				setShowModal(false);
				setEditingItem(defaultTemplate);
				setPreparedTemplate(null);
			} else {
				setPreparedTemplate(template);
				setShowClarificationModal(true);
			}
		},
		[],
	);

	return (
		<Root sizes="auto! 1fr" gaps="16px" maxedWidth maxedHeight>
			<DefaultPageHeader
				canEdit={selected.length === 1}
				canDelete={
					!!selected.length &&
					!selected.some((id) => modelItemById[id].isDefault)
				}
				onAdd={addHandler}
				onDelete={() => {
					setShowDeleteModal(true);
				}}
				onEdit={editHeaderHandler}
				afterAdditionalButtons={[
					{
						icon: { id: "copy", size: 20 },
						onClick: copyHandler,
						disabled: !(selected.length === 1),
					},
				]}
			/>
			<Content
				value={templates}
				lang={globalLang}
				selected={selected}
				setSelected={setSelected}
				onEdit={editContentHandler}
				saveHandler={saveHandler}
			/>
			{showModal && (
				<Modal
					onCancel={cancelHandler}
					onSave={saveHandler}
					value={editingItem}
					headerTitle={
						editingItem.id
							? `${t(`${translationPath}.modal.title.edit`)} "${
									editingItem.name.length > 20
										? `${editingItem.name.slice(0, 20)}...`
										: editingItem.name
							  }"`
							: t(`${translationPath}.modal.title.add`)
					}
					createdAt={new Date().toLocaleDateString()}
					showClarificationModal={showClarificationModal}
					setShowClarificationModal={setShowClarificationModal}
					preparedTemplate={preparedTemplate}
					setPreparedTemplate={setPreparedTemplate}
					saveHandler={saveHandler}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					label={
						selected.length === 1
							? t(`${translationPath}.deleteModal.title`) || ""
							: t(`${translationPath}.deleteModal.title2`) || ""
					}
					onCancel={() => {
						setShowDeleteModal(false);
						setSelected([]);
					}}
					onConfirm={deleteHandler}
				/>
			)}
		</Root>
	);
};

declare namespace Templates {
	type Props = SettingsTabProps;
}

export default Templates;
