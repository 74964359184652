import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { cloneDeep, isNumber, isUndefined } from "lodash";
import moment from "moment";

import { useTypedSelector } from "../../../../../../redux/store";
import Order from "../../../../../../services/Order";
import { CurrencyDisplayStyle } from "../../../../../../pages/Settings/pages/Tariffs/tabs/Additional/components/Modal/components/Content/tabs/Main/components/Currency/constants";
import { RoundingMethod } from "../../../../../../pages/Settings/pages/Tariffs/tabs/Additional/components/Modal/components/Content/tabs/Main/components/Rounding/constants";

interface Value {
	action: "add" | "set";
	value: number;
}

function formatDuration(elapsedMinutes: number): string {
	// eslint-disable-next-line no-param-reassign
	elapsedMinutes = Math.floor(Math.abs(elapsedMinutes));

	const minutes = Math.floor(elapsedMinutes % 60);
	const hours = Math.floor(elapsedMinutes / 60);

	const formattedHours = String(hours).padStart(2, "0");
	const formattedMinutes = String(minutes).padStart(2, "0");

	return `${formattedHours}:${formattedMinutes}`;
}

function useBottomData(activeOrder: any) {
	const { t } = useTranslation();

	const additionalTariffSettings = useTypedSelector((state) =>
		state.additionalTariffs.models.at(0),
	);

	const outOfTownDistanceValue = useMemo<Value | undefined>(() => {
		const suburbanDistance = activeOrder?.route?.suburbanPriceZones as
			| number
			| undefined;
		const suburbanDistanceOverride = activeOrder?.additionalFields
			?.suburbanDistanceOverride?.value as number | undefined;

		if (
			isUndefined(suburbanDistance) &&
			isUndefined(suburbanDistanceOverride)
		) {
			return undefined;
		}

		const totalSuburbanDistance =
			suburbanDistanceOverride || suburbanDistance || 0;

		return {
			action: activeOrder.additionalFields?.suburbanDistanceOverride
				?.shrink
				? "set"
				: "add",
			value: totalSuburbanDistance,
		};
	}, [
		activeOrder?.additionalFields?.suburbanDistanceOverride?.shrink,
		activeOrder?.additionalFields?.suburbanDistanceOverride?.value,
		activeOrder?.route?.suburbanPriceZones,
	]);

	const outOfTownDistanceLabel = useMemo(() => {
		const outOfTownDistance = outOfTownDistanceValue?.value;

		return isNumber(outOfTownDistance)
			? `${Number(Math.max(outOfTownDistance, 0)).toFixed(3)} ${
					t(`units.km`) ?? "km"
			  }`
			: "-";
	}, [outOfTownDistanceValue, t]);

	const updateOutOfTownDistance = useCallback(
		(newOutOfTownDistance: Value) => {
			if (!("id" in activeOrder)) return;
			const newAdditionalFields = cloneDeep(activeOrder.additionalFields);
			const suburbanDistance = (activeOrder?.route?.suburbanPriceZones ??
				0) as number;
			const suburban = ((activeOrder?.cost?.distances?.suburban ?? 0) /
				1000) as number;

			const payload = suburban ?? suburbanDistance ?? 0;

			// const endOutOfTownDistance =
			// 	newOutOfTownDistance.action === "set"
			// 		? newOutOfTownDistance.value
			// 		: (outOfTownDistanceValue?.value ?? 0) +
			// 		  newOutOfTownDistance.value;

			newAdditionalFields.suburbanDistanceOverride.shrink =
				newOutOfTownDistance.action === "set";

			if (newOutOfTownDistance.action === "set") {
				newAdditionalFields.suburbanDistanceOverride.value = Math.max(
					newOutOfTownDistance.value,
					0,
				);
			} else {
				const value = Number(Number(payload).toFixed(3));
				newAdditionalFields.suburbanDistanceOverride.value =
					value + newOutOfTownDistance.value;
			}

			Order.update({
				id: activeOrder.id,
				additionalFields: newAdditionalFields,
			});
		},
		[activeOrder],
	);

	const distance = useMemo(() => {
		const value =
			activeOrder?.cost?.distances?.total ||
			activeOrder?.route?.distance ||
			0;
		return isNumber(value)
			? `${((value ?? 0) / 1000).toFixed(3)} ${t(`units.km`) ?? "km"}`
			: "-";
	}, [activeOrder?.cost?.distances?.total, activeOrder?.route?.distance, t]);

	const idleTimeValue = useMemo<Value | undefined>(() => {
		let idleTimeMilliseconds = 0;
		let withClients = 0;
		if (!activeOrder?.id) return undefined;
		if (isNumber(activeOrder?.additionalFields?.idleTimeMilliseconds)) {
			idleTimeMilliseconds =
				activeOrder.additionalFields.idleTimeMilliseconds;
		}
		if (isNumber(activeOrder?.downtimes?.withClients)) {
			withClients = activeOrder?.downtimes?.withClients;
		}
		const value = Math.max(withClients + idleTimeMilliseconds, 0);

		return {
			action: "add",
			// value: Math.max(Math.round(value / 60e3), 0),
			value: value / 60e3,
		};
	}, [
		activeOrder?.additionalFields?.idleTimeMilliseconds,
		activeOrder?.downtimes?.withClients,
		activeOrder?.id,
	]);

	const idleTimeLabel = useMemo(() => {
		const value = idleTimeValue?.value ? idleTimeValue.value * 60e3 : 0;

		return isNumber(idleTimeValue?.value)
			? `${
					// formatDuration(value)
					moment.utc(value).format("HH:mm:ss")
			  } ${t(`units.minute`) ?? "min"}`
			: "-";
	}, [idleTimeValue, t]);

	const updateIdleTime = useCallback(
		(newIdleTime: Value) => {
			if (!("id" in activeOrder)) return;
			let withClients = 0;

			const newAdditionalFields = cloneDeep(activeOrder.additionalFields);

			if (isNumber(activeOrder?.downtimes?.withClients)) {
				withClients = activeOrder?.downtimes?.withClients;
			}

			const newIdleTimeValue =
				newIdleTime.action === "add"
					? (idleTimeValue?.value ?? 0) + newIdleTime.value
					: newIdleTime.value;

			newAdditionalFields.idleTimeMilliseconds =
				newIdleTimeValue * 60e3 - withClients;

			Order.update({
				id: activeOrder.id,
				additionalFields: newAdditionalFields,
			});
		},
		[activeOrder, idleTimeValue?.value],
	);

	const currencyLabel = useMemo(() => {
		const displayStyle =
			additionalTariffSettings?.additionalFields.general.currency
				.displayStyle ?? CurrencyDisplayStyle.NAME;

		const currency =
			activeOrder?.cost?.currency?.settings?.[displayStyle] ?? "";

		return currency;
	}, [
		activeOrder?.cost?.currency?.settings,
		additionalTariffSettings?.additionalFields.general.currency
			.displayStyle,
	]);

	const stringifyPrice = useCallback(
		(price: number | undefined) => {
			if (isUndefined(price)) {
				return "-";
			}

			let processedPrice = price;
			let precision = 2;

			if (additionalTariffSettings) {
				const { rounding } =
					additionalTariffSettings.additionalFields.general;

				precision = rounding.precision;

				if (rounding.multiple.active) {
					processedPrice /= rounding.multiple.value;
				} else {
					processedPrice *= 10 ** precision;
				}

				switch (rounding.method) {
					case RoundingMethod.GENERAL:
						processedPrice = Math.round(processedPrice);

						break;
					case RoundingMethod.CEIL:
						processedPrice = Math.ceil(processedPrice);

						break;
					case RoundingMethod.FLOOR:
						processedPrice = Math.floor(processedPrice);

						break;
					default:
						break;
				}

				if (rounding.multiple.active) {
					processedPrice *= rounding.multiple.value;
				} else {
					processedPrice /= 10 ** precision;
				}
			}

			return `${processedPrice.toFixed(
				precision,
			)} ${currencyLabel}`.trim();
		},
		[additionalTariffSettings, currencyLabel],
	);

	const additionalPriceValue = useMemo<Value | undefined>(
		() =>
			isNumber(activeOrder?.additionalFields?.additionalCost)
				? {
						action: "add",
						value: activeOrder.additionalFields
							.additionalCost as number,
				  }
				: undefined,
		[activeOrder?.additionalFields?.additionalCost],
	);

	const additionalPriceLabel = useMemo(
		() => stringifyPrice(additionalPriceValue?.value),
		[additionalPriceValue, stringifyPrice],
	);

	const discountLabel = useMemo(() => {
		if (!activeOrder?.cost?.discount) return "";
		const bonusAmount = activeOrder?.cost?.discount?.bonusAmount || 0;
		if (!bonusAmount) return "";
		return bonusAmount;
	}, [activeOrder?.cost?.discount]);

	const updateAdditionalCost = useCallback(
		(newAdditionalCost: Value) => {
			if (!("id" in activeOrder)) return;

			const newAdditionalFields = cloneDeep(activeOrder.additionalFields);
			const activeOrderCost = cloneDeep(activeOrder.cost);

			const additionalCost = newAdditionalFields?.additionalCost ?? 0;
			const watchedPrice = activeOrderCost.value ?? 0;
			const dataValue = newAdditionalCost?.value ?? 0;
			const cost = dataValue + Number(additionalCost);
			const prevPrice = Number(watchedPrice) - Number(additionalCost);
			const payload = Number(additionalCost) + dataValue;

			const valueAdd =
				cost + prevPrice <= 0 ? -Number(prevPrice) : payload;
			const valueSet =
				cost + prevPrice < 0 ? -Number(prevPrice) : dataValue;

			const newAdditionalPriceValue =
				newAdditionalCost.action === "add" ? valueAdd : valueSet;

			// const newAdditionalPriceValue =
			// 	newAdditionalPrice.action === "add"
			// 		? costPrevPrice <= 0
			// 			? dataValue + newAdditionalPrice.value
			// 			: 0
			// 		: newAdditionalPrice.value;

			Order.update({
				id: activeOrder.id,
				additionalFields: {
					...newAdditionalFields,
					additionalCost: newAdditionalPriceValue,
				},
			});
		},
		[activeOrder],
	);

	const updateAdditionalPrice = useCallback(
		(newAdditionalPrice: Value) => {
			if (!("id" in activeOrder)) return;

			const newAdditionalFields = cloneDeep(activeOrder.additionalFields);
			const activeOrderCost = cloneDeep(activeOrder.cost);

			const additionalCost = newAdditionalFields?.additionalCost ?? 0;
			const watchedPrice = activeOrderCost.value ?? 0;
			const dataValue = newAdditionalPrice?.value ?? 0;
			const cost = dataValue + Number(additionalCost);
			const prevPrice = Number(watchedPrice) - Number(additionalCost);
			const dataValuePrice =
				dataValue - Number(watchedPrice) + Number(additionalCost);
			const payload = Number(additionalCost) + dataValue;

			const valueAdd2 =
				prevPrice + payload >= 0 ? Number(payload) : payload;

			const valueAdd =
				cost + prevPrice <= 0 ? -Number(prevPrice) : valueAdd2;

			const valueSet2 =
				cost + prevPrice <= 0
					? -Number(prevPrice)
					: -(prevPrice - dataValue);

			const valueSet = dataValue >= 0 ? dataValuePrice : valueSet2;

			const newAdditionalPriceValue =
				newAdditionalPrice.action === "add" ? valueAdd : valueSet;

			// const newAdditionalPriceValue =
			// 	newAdditionalPrice.action === "add"
			// 		? costPrevPrice <= 0
			// 			? dataValue + newAdditionalPrice.value
			// 			: 0
			// 		: newAdditionalPrice.value;

			Order.update({
				id: activeOrder.id,
				additionalFields: {
					...newAdditionalFields,
					additionalCost: newAdditionalPriceValue,
				},
			});
		},
		[activeOrder],
	);

	const priceValue = useMemo<Value | undefined>(() => {
		if (
			!isNumber(activeOrder?.cost?.value) &&
			!isNumber(activeOrder?.additionalFields?.additionalCost)
		) {
			return undefined;
		}

		// const total = ((activeOrder?.cost?.value ?? 0) +
		// 	(activeOrder?.additionalFields?.additionalCost ?? 0)) as number;

		const total = activeOrder?.cost?.value ?? 0;

		return {
			action: "add",
			value: total,
		};
	}, [
		activeOrder?.additionalFields?.additionalCost,
		activeOrder?.cost?.value,
	]);

	const priceLabel = useMemo(
		() => stringifyPrice(priceValue?.value),
		[priceValue, stringifyPrice],
	);

	const updatePrice = useCallback(
		(newPrice: Value) => {
			if (!("id" in activeOrder)) return;

			updateAdditionalPrice(newPrice);
		},
		[activeOrder, updateAdditionalPrice],
	);

	const awaitingTimeValue = useMemo<Value | undefined>(() => {
		if (!activeOrder?.id) return undefined;
		let waitingTimeMilliseconds = 0;
		let waitingClient = 0;
		if (isNumber(activeOrder?.additionalFields?.waitingTimeMilliseconds)) {
			waitingTimeMilliseconds =
				activeOrder.additionalFields.waitingTimeMilliseconds;
		}
		if (isNumber(activeOrder?.downtimes?.waitingClient)) {
			waitingClient = activeOrder?.downtimes?.waitingClient;
		}
		const value = Math.max(waitingClient + waitingTimeMilliseconds, 0);

		return {
			action: "add",
			// value: Math.max(Math.round(value / 60e3), 0),
			value: value / 60e3,
		};
	}, [
		activeOrder?.additionalFields?.waitingTimeMilliseconds,
		activeOrder?.downtimes?.waitingClient,
		activeOrder?.id,
	]);

	const awaitingTimeLabel = useMemo(() => {
		const value = awaitingTimeValue?.value
			? awaitingTimeValue.value * 60e3
			: 0;

		return isNumber(awaitingTimeValue?.value)
			? `${
					// formatDuration(value)
					moment.utc(value).format("HH:mm:ss")
			  } ${t(`units.minute`) ?? "min"}`
			: "-";
	}, [awaitingTimeValue, t]);

	const updateAwaitingTime = useCallback(
		(newAwaitingTime: Value) => {
			if (!("id" in activeOrder)) return;

			let waitingClient = 0;

			if (isNumber(activeOrder?.downtimes?.waitingClient)) {
				waitingClient = activeOrder?.downtimes?.waitingClient;
			}

			const newAdditionalFields = cloneDeep(activeOrder.additionalFields);

			const newAwaitingTimeValue =
				newAwaitingTime.action === "add"
					? (awaitingTimeValue?.value ?? 0) + newAwaitingTime.value
					: newAwaitingTime.value;

			newAdditionalFields.waitingTimeMilliseconds =
				newAwaitingTimeValue * 60e3 - waitingClient;

			Order.update({
				id: activeOrder.id,
				additionalFields: newAdditionalFields,
			});
		},
		[activeOrder, awaitingTimeValue?.value],
	);

	const hourlyTimeValue = useMemo<Value | undefined>(
		() =>
			isNumber(activeOrder?.additionalFields?.hourlyMilliseconds)
				? {
						action: "add",
						value: (activeOrder.additionalFields
							.hourlyMilliseconds / 60e3) as number,
				  }
				: undefined,
		[activeOrder?.additionalFields?.hourlyMilliseconds],
	);

	const hourlyTimeLabel = useMemo(() => {
		const hourlyTime = hourlyTimeValue?.value;

		return isNumber(hourlyTime)
			? `${formatDuration(hourlyTime)} ${t(`units.minute`) ?? "min"}`
			: "-";
	}, [hourlyTimeValue, t]);

	const updateHourlyTime = useCallback(
		(newHourlyTime: Value) => {
			if (!("id" in activeOrder)) return;

			const newAdditionalFields = cloneDeep(activeOrder.additionalFields);

			const newHourlyTimeValue =
				newHourlyTime.action === "add"
					? (hourlyTimeValue?.value ?? 0) + newHourlyTime.value
					: newHourlyTime.value;

			newAdditionalFields.hourlyMilliseconds = newHourlyTimeValue * 60e3;

			Order.update({
				id: activeOrder.id,
				additionalFields: newAdditionalFields,
			});
		},
		[activeOrder, hourlyTimeValue?.value],
	);

	return {
		distance,

		outOfTownDistanceValue,
		outOfTownDistanceLabel,
		updateOutOfTownDistance,

		idleTimeValue,
		idleTimeLabel,
		updateIdleTime,

		priceValue,
		priceLabel,
		updatePrice,

		discountLabel,

		additionalPriceValue,
		additionalPriceLabel,
		updateAdditionalPrice,
		updateAdditionalCost,

		awaitingTimeValue,
		awaitingTimeLabel,
		updateAwaitingTime,

		hourlyTimeValue,
		hourlyTimeLabel,
		updateHourlyTime,
	};
}

export default useBottomData;
