import React, { Dispatch, useLayoutEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";
import { useDebouncedCallback } from "use-debounce";

import Service from "../../../../../../../../../../../../../../services/Service";
import Language from "../../../../../../../../../../../../../../services/Language";
import useModelSubscribe from "../../../../../../../../../../../../../../hooks/useModelSubscribe2";
import LabeledField from "../../../../../../../../../../../../../../components/LabeledField";
import { MultiSelectServiceWithModal } from "../../../../../../../../../../../../../../components/common/Modal";

const Services: React.FC<Services.Props> = ({
	value = [],
	onChange,

	disabled,
	// language,
	taxiServiceIds,
}) => {
	const { t } = useTranslation();
	const data = useModelSubscribe(
		{
			availableForExecutor: true,
		},
		Service,
	);

	const items = useMemo(() => data?.models ?? [], [data?.models]);

	const servicesIds = useMemo(() => items.map((item) => item.id), [items]);

	const debounceFn = useDebouncedCallback(() => {
		if (value.length) {
			const valueIds: number[] = [];
			servicesIds.forEach((serviceId) => {
				const existId = value.find((id) => id === serviceId);
				if (existId) valueIds.push(existId);
			});

			if (!isEqual(valueIds, value)) {
				onChange?.(valueIds);
			}
		}
	}, 1000);

	useLayoutEffect(() => {
		debounceFn();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taxiServiceIds, servicesIds]);

	// const options = useMemo<Option<number>[]>(
	// 	() =>
	// 		items.map((item) => ({
	// 			key: item?.id,
	// 			value: item?.id,
	// 			label: item?.name?.[language] || "",
	// 		})),
	// 	[items, language],
	// );

	return (
		<LabeledField
			label={
				t(
					"pages.mainPage.pages.accounts.tabs.roles.editModal.content.mainTab.services.str0",
				) ?? ""
			}
		>
			<MultiSelectServiceWithModal
				disabled={disabled}
				value={value}
				onChange={onChange}
				titleText={
					"pages.mainPage.pages.accounts.tabs.roles.editModal.content.mainTab.services.str0"
				}
				subscribeOptions={{
					availableForExecutor: true,
				}}
			/>

			{/* <MultiSelectWithModal
				value={value}
				disabled={disabled}
				title={
					t(
						"pages.mainPage.pages.accounts.tabs.roles.editModal.content.mainTab.services.str0",
					) ?? ""
				}
				options={options}
				onChange={
					onChange as unknown as Dispatch<
						MultiSelectWithModal.Value<number>
					>
				}
			/> */}
		</LabeledField>
	);
};

declare namespace Services {
	type Value = number[];

	interface Props {
		value: Value;

		disabled: boolean;

		language: Language;
		taxiServiceIds: number[];

		onChange: Dispatch<Value>;
	}
}

export default Services;
