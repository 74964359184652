import { extend } from "lodash";
import React, { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import {
	CheckBoxWithContent,
	Column,
	RadioButton,
	Row,
	Stepper,
	theme,
} from "uikit";

import useObjectEditor from "../../../../../../../../../../../../../../hooks/useObjectEditor";

enum Type {
	Always = "always",
	Sometimes = "sometimes",
}

const borderStyles = {
	border: `1px solid ${theme.colors.color_border_basic}`,
	borderRadius: theme.borderRadius.m,
	padding: 10,
};

export const marginLeft = { marginLeft: 30 };

const ShowCustomerPhoneNumbers = extend(
	(({ value, onChange }) => {
		const { t } = useTranslation();
		const valueEditor = useObjectEditor(value, onChange);

		const activeValue = valueEditor.useGetter("active");
		const activeOnChange = valueEditor.useSetter("active");

		const typeValue = valueEditor.useGetter("type");
		const typeOnChange = valueEditor.useSetter("type");

		const conditionsEditor = valueEditor.usePropertyEditor("conditions");

		const showManuallyValue = conditionsEditor.useGetter("showManually");
		const showManuallyOnChange = conditionsEditor.useSetter("showManually");

		const afterArrivedMessageValue = conditionsEditor.useGetter(
			"afterArrivedMessage",
		);
		const afterArrivedMessageOnChange = conditionsEditor.useSetter(
			"afterArrivedMessage",
		);

		const afterWaitingCustomerMessageValue = conditionsEditor.useGetter(
			"afterWaitingCustomerMessage",
		);
		const afterWaitingCustomerMessageOnChange = conditionsEditor.useSetter(
			"afterWaitingCustomerMessage",
		);

		const beforeArrivalTimeEditor =
			valueEditor.usePropertyEditor("beforeArrivalTime");

		const beforeArrivalTimeActiveValue =
			beforeArrivalTimeEditor.useGetter("active");
		const beforeArrivalTimeActiveOnChange =
			beforeArrivalTimeEditor.useSetter("active");

		const beforeArrivalTimeThresholdValue =
			beforeArrivalTimeEditor.useGetter("threshold");
		const beforeArrivalTimeThresholdOnChange =
			beforeArrivalTimeEditor.useSetter("threshold");

		const afterAcceptingOrderEditor = valueEditor.usePropertyEditor(
			"afterAcceptingOrder",
		);

		const afterAcceptingOrderActiveValue =
			afterAcceptingOrderEditor.useGetter("active");
		const afterAcceptingOrderActiveOnChange =
			afterAcceptingOrderEditor.useSetter("active");

		const afterAcceptingOrderThresholdValue =
			afterAcceptingOrderEditor.useGetter("threshold");
		const afterAcceptingOrderThresholdOnChange =
			afterAcceptingOrderEditor.useSetter("threshold");

		return (
			<Column gaps="10px*" style={borderStyles}>
				<CheckBoxWithContent
					value={activeValue}
					gap="10px"
					onChange={activeOnChange}
				>
					{t(
						"pages.settings.pages.orders.tabs.orders.modal.content.tabs.main.showCustomerPhoneNumbers.str200",
					) ?? ""}
					:
				</CheckBoxWithContent>
				<Column style={marginLeft} gaps="5px*">
					<RadioButton.RadioButton
						selected={typeValue === Type.Always}
						disabled={!activeValue}
						label={
							t(
								"pages.settings.pages.orders.tabs.orders.modal.content.tabs.main.showCustomerPhoneNumbers.str0",
							) ?? ""
						}
						onClick={() => typeOnChange(Type.Always)}
					/>
					<RadioButton.RadioButton
						selected={typeValue === Type.Sometimes}
						disabled={!activeValue}
						label={
							t(
								"pages.settings.pages.orders.tabs.orders.modal.content.tabs.main.showCustomerPhoneNumbers.str1",
							) ?? ""
						}
						onClick={() => typeOnChange(Type.Sometimes)}
					/>
					<Row
						style={{ ...marginLeft, ...borderStyles }}
						gaps="30px*"
					>
						<CheckBoxWithContent
							value={showManuallyValue}
							disabled={
								!activeValue || typeValue !== Type.Sometimes
							}
							gap="10px"
							onChange={showManuallyOnChange}
						>
							{t(
								"pages.settings.pages.orders.tabs.orders.modal.content.tabs.main.showCustomerPhoneNumbers.str150",
							) ?? ""}
						</CheckBoxWithContent>
						<CheckBoxWithContent
							value={afterArrivedMessageValue}
							disabled={
								!activeValue || typeValue !== Type.Sometimes
							}
							gap="10px"
							onChange={afterArrivedMessageOnChange}
						>
							{t(
								"pages.settings.pages.orders.tabs.orders.modal.content.tabs.main.showCustomerPhoneNumbers.str201",
							) ?? ""}
						</CheckBoxWithContent>
						<CheckBoxWithContent
							value={afterWaitingCustomerMessageValue}
							disabled={
								!activeValue || typeValue !== Type.Sometimes
							}
							gap="10px"
							onChange={afterWaitingCustomerMessageOnChange}
						>
							{t(
								"pages.settings.pages.orders.tabs.orders.modal.content.tabs.main.showCustomerPhoneNumbers.str203",
							) ?? ""}
						</CheckBoxWithContent>
					</Row>
				</Column>
				<Row style={marginLeft} gaps="30px*">
					<Row gaps="8px*" align="center">
						<CheckBoxWithContent
							value={beforeArrivalTimeActiveValue}
							disabled={
								!activeValue || typeValue !== Type.Sometimes
							}
							gap="10px"
							onChange={beforeArrivalTimeActiveOnChange}
						>
							{t(
								"pages.settings.pages.orders.tabs.orders.modal.content.tabs.main.showCustomerPhoneNumbers.str151",
							) ?? ""}
						</CheckBoxWithContent>
						<Stepper
							value={beforeArrivalTimeThresholdValue / 1e3}
							disabled={
								!activeValue || typeValue !== Type.Sometimes
							}
							onChange={(value) =>
								beforeArrivalTimeThresholdOnChange(
									(value ? value * 1e3 : value) as number,
								)
							}
						/>
						<span>
							{t(
								"pages.settings.pages.orders.tabs.orders.modal.content.tabs.main.showCustomerPhoneNumbers.str100",
							) ?? ""}
						</span>
					</Row>
					<Row gaps="8px*" align="center">
						<CheckBoxWithContent
							value={afterAcceptingOrderActiveValue}
							disabled={
								!activeValue || typeValue !== Type.Sometimes
							}
							gap="10px"
							onChange={afterAcceptingOrderActiveOnChange}
						>
							{t(
								"pages.settings.pages.orders.tabs.orders.modal.content.tabs.main.showCustomerPhoneNumbers.str152",
							) ?? ""}
						</CheckBoxWithContent>
						<Stepper
							value={afterAcceptingOrderThresholdValue / 1e3}
							disabled={
								!activeValue || typeValue !== Type.Sometimes
							}
							onChange={(value) =>
								afterAcceptingOrderThresholdOnChange(
									(value ? value * 1e3 : value) as number,
								)
							}
						/>
						<span>
							{t(
								"pages.settings.pages.orders.tabs.orders.modal.content.tabs.main.showCustomerPhoneNumbers.str101",
							) ?? ""}
						</span>
					</Row>
				</Row>
			</Column>
		);
	}) as React.FC<ShowCustomerPhoneNumbers.Props>,
	{
		Type,
	},
);

declare namespace ShowCustomerPhoneNumbers {
	interface Value {
		active: boolean;

		type: Type;

		conditions: {
			showManually: boolean;
			afterArrivedMessage: boolean;
			afterWaitingCustomerMessage: boolean;
		};

		beforeArrivalTime: {
			active: boolean;
			threshold: number;
		};

		afterAcceptingOrder: {
			active: boolean;
			threshold: number;
		};
	}

	interface Props {
		value: Value;

		onChange: Dispatch<Value>;
	}
}

export default ShowCustomerPhoneNumbers;
