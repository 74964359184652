import React, { useMemo } from "react";
import { Tabs } from "uikit";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../../../../redux/store";
import useActiveTab from "../../../../hooks/useActiveTab";
import BasicPageLayout from "../../../../components/BasicPageLayout";
import { hasAccess, generateAccessName, AccessKey } from "../../../../access";
import { TabOptions } from "../../../../types";

import { Customers, Executors, Transport } from "./tabs";

const Blacklist: React.FC = () => {
	const ACCESS_SECTION = AccessKey.BLACKLIST;
	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const tabs: TabOptions.Array = useMemo(
		() =>
			[
				{
					key: "customers",
					label: t("mainPage.blacklist.customers.title"),
					value: {
						render: () => <Customers />,
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.BLACKLIST_CUSTOMERS,
					),
				},
				{
					key: "executors",
					label: t("mainPage.blacklist.executors.title"),
					value: {
						render: () => <Executors />,
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.BLACKLIST_EXECUTORS,
					),
				},
				{
					key: "transport",
					label: t("mainPage.blacklist.transport.title"),
					value: {
						render: () => <Transport />,
					},
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.BLACKLIST_TRANSPORT,
					),
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, ACCESS_SECTION, personalRole],
	);

	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	return (
		<BasicPageLayout
			navigation={
				<Tabs
					value={activeKey}
					onChange={setActiveKey}
					options={tabs}
					variant="vertical"
				/>
			}
			content={activeTab.value.render()}
		/>
	);
};

export default Blacklist;
